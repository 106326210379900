@use '@angular/material' as mat;
//styling for apex components

@use './variables.scss' as *;
@use './media.scss' as *;
@use './classes.scss' as *;
@use './functions.scss' as *;
@use './theming.scss' as *;

@use 'sass:math';

apex-client-page-info-form {
  form {
    padding: 1.5rem;
    position: relative;
  }

  .button-row {
    padding: $default-padding-small 0;
  }

  .help-button {
    position: absolute;
    top: 0;
    right: 0;
  }

  mat-form-field {
    cursor: pointer;
  }
}

apex-file-usage,
angular-file-usage {
  * {
    box-sizing: border-box;
  }

  display: block;

  .wrap {
    height: 100%;

    background-color: $AK-50;

    .toolbar {
      padding-left: 16px;
      padding-right: 8px;
      height: 48px;
    }

    &.over {
      background: mat.get-color-from-palette($foreground, darker);

      &.valid {
        background: mat.get-color-from-palette($primary, 50);
      }

      &.invalid {
        background: mat.get-color-from-palette($warn, 100);
      }
    }

    &.grid .content {
      min-height: $default-thumbnail-size + $default-padding;
    }

    &.list .content {
      min-height: math.div($default-thumbnail-size, 6) + 2 * $default-padding;
    }

    &.list {
      .mat-mdc-list-item-content {
        apex-file-preview {
          width: initial;

          display: flex;
          align-items: center;

          .file-preview {
            img,
            .icon,
            .placeholder-image {
              width: 32px;
              height: 32px;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            .file-download {
              position: initial;

              margin-left: $default-padding;

              a {
                display: flex;
              }
            }
          }
        }
      }
    }

    &.single {
      position: relative;
      height: 100%;

      .content {
        height: 100%;
        min-height: 256px;
      }

      .loader {
        position: absolute;
        width: 100%;
        z-index: 1000;
      }

      &.over {
        img {
          opacity: 0.25;
          transition: 0.25s all;
        }
      }
    }

    .actions {
      min-height: 0;
    }
  }

  .overlay-single {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    pointer-events: none;

    .overlay-single-background {
      width: 100%;
      height: 100%;
      opacity: 0.4;
      position: absolute;

      &.over {
        &.valid {
          background: mat.get-color-from-palette($primary, 50);
        }

        &.invalid {
          background: mat.get-color-from-palette($warn, 50);
        }
      }
    }

    .overlay-single-text {
      opacity: 1;
      height: 100%;
    }
  }

  .disabled {
    color: $color-disabled;

    .title {
      padding-left: $default-padding-small;
    }
  }

  .toolbar-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99;
    opacity: 0;
    transition: opacity 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);

    &.toolbar-overlay-show {
      opacity: 1;
    }
  }

  .loader {
    height: 4px;
  }

  .grid {
    .usage {
      margin: 16px;
      position: relative;

      background: #fff;
      border: 1px solid mat.get-color-from-palette($primary, 100);
      border-bottom: none;

      apex-file-preview {
        .file-download {
          a {
            color: $primary-foreground;
          }
        }
      }

      &.selected {
        border-color: $color-selected;
      }

      .file-name {
        background: mat.get-color-from-palette($background, app-bar);

        .mat-mdc-form-field {
          width: 100%;

          .mat-mdc-form-field-wrapper {
            padding: 0;

            input {
              padding: 0 4px;
              width: 100%;

              font-size: mat.font-size($apex-typography, caption);
            }

            .mat-mdc-form-field-infix {
              border: none;
            }

            .mat-mdc-form-field-underline {
              bottom: 0;
            }
          }
        }
      }

      .status {
        position: absolute;
        top: $default-padding-small;
        left: $default-padding-small;
      }
    }
  }

  .list {
    .selection-list {
      padding: 0;
    }

    apex-file-preview {
      min-width: math.div($default-thumbnail-size, 6);
      min-height: math.div($default-thumbnail-size, 6);
    }
  }

  .single {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
  }

  .content {
    position: relative;

    .overlay {
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;

      display: flex;
      align-items: center;
      justify-content: center;

      background: rgba(0, 0, 0, 0.32);
      color: rgba(255, 255, 255, 1);
    }

    .usages.list {
      .selection-list {
        max-height: 270px;
        overflow-y: auto;
      }
    }
  }

  div.empty {
    padding: 1rem;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    div:first-child {
      font-size: mat.font-size($apex-typography, headline-5);
    }
  }
}

apex-addon-on-apartments-2-page {
  .mat-vertical-content-container {
    margin: 0 !important;
  }

  .mat-vertical-stepper-content {
    overflow: visible;
  }

  .cdk-virtual-scroll-content-wrapper {
    width: 100% !important;
  }
}

apex-addon-orders-page {
  @include flex-column();
  overflow: auto;

  @media print {
    overflow: visible;
    > * {
      overflow: visible;
    }
  }

  apex-addon-order-filter {
    .wrapper {
      position: relative;

      .loading {
        position: absolute;
        width: 100%;
        bottom: 0;
        left: 0;
        pointer-events: none;
        z-index: 2;
      }
    }

    .column-filter {
      margin-left: $default-padding-extra-small;
    }

    .checkbox-filter {
      .checkbox-filter-content {
        border-radius: 4px;
      }

      .mdc-checkbox {
        display: none;
      }
    }

    /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
    mat-checkbox {
      mat-icon {
        padding-right: $default-padding-extra-small;
      }
    }
  }

  apex-addon-order-list {
    @include flex-column();
    overflow: hidden;
    min-height: 800px;
    $sortSize: 18px;

    table {
      thead {
        th {
          &.mat-sort-header.mat-sort-header-disabled {
            .mat-sort-header-container {
              border-bottom: none;
            }
          }
        }
      }

      th.mat-mdc-header-cell,
      td.mat-mdc-cell,
      td.mat-mdc-footer-cell {
        padding: 0 $default-padding-extra-small;

        max-width: 300px;
        @extend .truncate;
      }

      td.mat-mdc-cell,
      td.mat-mdc-footer-cell {
        padding-right: $sortSize + $default-padding-extra-small;
        font-size: mat.font-size($apex-typography, caption);
      }

      .mat-column-id,
      .mat-column-dateProcessed,
      .mat-column-createdAt,
      .mat-column-CaseId,
      .mat-column-quantity,
      .mat-column-VAT,
      .mat-column-status,
      .mat-column-hasMarkings {
        text-align: center;

        > div {
          justify-content: center;
        }
      }

      .mat-column-totalPriceIn,
      .mat-column-totalPriceOut,
      .mat-column-totalPriceOutVat,
      .mat-column-totalPriceOutIncVat,
      .mat-column-priceDiff,
      .mat-column-invoiced,
      .invoiced {
        text-align: right;

        > div {
          justify-content: flex-end;
        }
      }

      tbody {
        .mat-mdc-row {
          &.seven-days-or-newer {
            background-color: scale-color($AY-100, $lightness: 70%);
          }

          &.highlight-row {
            background-color: rgba($AT-100, 0.9);
          }

          &:focus-within {
            background-color: rgba($AT-100, 0.9);
          }
        }
      }

      tfoot {
        .mat-mdc-footer-cell {
          border-top: 1px solid black;
          font-weight: bold;
        }
      }
    }
  }
}

apex-file-preview {
  * {
    box-sizing: border-box;
  }

  display: flex;

  position: relative;

  img {
    max-width: 100%;
    max-height: 100%;
  }

  .icon {
    display: flex;
    font-size: mat.font-size($apex-typography, headline-5);
  }

  .file-size {
    background: #707070;
    color: white;

    border-radius: 3px;

    padding: math.div($default-padding-small, 1.4);

    position: absolute;
    top: $default-padding-extra-small;
    right: $default-padding-extra-small;

    font-size: mat.font-size($apex-typography, caption);
  }

  .file-name {
    width: 100%;

    position: absolute;
    bottom: 0;

    @include mat.typography-level($apex-typography, body-1);

    background: white;

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;
  }

  .file-download {
    position: absolute;
    bottom: $default-padding-extra-small;
    left: $default-padding-small;
    cursor: pointer;
    z-index: 1;
  }

  .file-date {
    position: absolute;
    height: 100%;
    width: 100%;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0) 50%, transparent);
    padding-right: $default-padding-extra-small;
    color: $primary-foreground;

    mat-icon {
      $iconSice: mat.font-size($apex-typography, caption);
      font-size: $iconSice;
      width: $iconSice;
      height: $iconSice;
      margin-left: $default-padding-extra-small;
    }
  }
}

apex-addon-projects-page {
  mat-form-field {
    flex: 1;
    padding-right: 16px;
  }
}

apex-addon-project-page {
  apex-page-info {
    .mat-mdc-form-field {
      .mat-mdc-form-field-wrapper {
        .mat-mdc-form-field-underline {
          background-color: white;

          .mat-mdc-form-field-ripple {
            background-color: white;
          }
        }

        .mat-mdc-form-field-label {
          color: white;
        }

        mat-select {
          .mat-mdc-select-arrow-wrapper {
            .mat-mdc-select-arrow {
              color: white;
            }
          }

          .mat-mdc-select-value {
            .mat-mdc-select-placeholder {
              color: white;
            }

            .mat-mdc-select-value-text {
              color: white;
            }
          }
        }
      }
    }
  }
}

apex-addon-project-list {
  .others {
    @include others();
  }
}

apex-signing-list {
  .content {
    .mat-mdc-list-base {
      padding-top: 0;
    }
  }
}

apex-signing {
  &-list {
    .content {
      .emptylist {
        @include flex-column();

        padding: 2rem;

        align-items: center;
        justify-content: center;
      }
    }
  }

  &-form {
    @include flex-column();

    apex-file-usage,
    apex-signers {
      margin: 1rem 0;
    }
  }
}

apex-signers {
  .add {
    T:first-of-type {
      padding-top: 1.25em;
    }
  }

  .new-signers {
    margin: 1rem 0 0 0;
  }

  .add {
    padding: 0 16px;
  }

  .signers {
    .signer-card {
      margin: 1rem;
    }

    .empty {
      align-self: center;
      margin: 1rem auto;
    }

    .signer-info {
      margin: 0 1rem;

      .ellipsis {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      mat-icon {
        padding: $default-padding;
        padding-left: 0;
      }
    }
  }
}

apex-page-info {
  display: block;

  position: sticky;
  top: 0;
  z-index: 999; // One below dialog

  .content {
    height: $header-height;

    h1 {
      margin: 0.5rem;
      margin-left: 0;

      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .mat-mdc-progress-spinner circle,
  .mat-mdc-progress-spinner circle {
    stroke: $AT-900;
  }

  .mat-mdc-progress-bar {
    position: absolute;
    z-index: 5;
  }

  .mat-badge-content {
    background: $AT-900;
    color: $AK-50;
  }
}

apex-page-header {
  background-color: $AT-900;
  height: $header-height;
  display: flex;

  a {
    color: $AK-50;
    padding: $default-padding;
  }
}

apex-client-addon-orders-page {
  .client-addon-tree-container {
    margin: auto;
    max-width: 1010px;
  }

  .mat-expansion-panel-body {
    padding: 0;
  }

  .mat-expansion-panel {
    border-radius: 0;
  }
}

apex-default-text-form {
  > .content {
    padding: 1rem;
  }

  mat-form-field {
    width: 100%;
  }
}

apex-simultaneous-signers {
  table.apex {
    .id {
      flex: 0.5;
    }

    .company {
      flex: 2;
    }

    .user {
      flex: 2;
    }

    .comment {
      flex: 3;
    }

    .action {
      flex: 0.5;
      justify-content: center;
    }
  }
}

apex-http-error {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 700px;

  .error-content {
    .text-content {
      display: flex;
      justify-content: space-between;

      .text {
        flex: 1;
      }
    }
  }
}

apex-date-range-picker-dialog {
  h5 {
    margin: 0;
    padding: 0;
  }

  .mat-mdc-dialog-content {
    min-height: 300px;
    min-width: 300px;
  }

  .custom-date-class {
    background-color: black;
    width: 100px;

    .mat-calendar-body-cell-content {
      background-color: black;
    }
  }

  .example-custom-date-class {
    background: orange;
    border-radius: 100%;
  }

  .inside-range {
    background-color: map-get($primary, 500);
    color: $AK-50;
  }

  .date-start {
    background-color: map-get($primary, 500);
    border-radius: 100% 0 0 100%;
  }

  .date-end {
    background-color: map-get($primary, 500);
    border-radius: 0 100% 100% 0;
  }

  .date-start-end {
    background: map-get($primary, 500);
    border-radius: 100%;
  }
}

apex-addon-card {
  display: flex;

  width: 230px;
  @include phone() {
    width: 125px;
  }

  header {
    height: 230px;
    @include phone() {
      height: 125px;
    }
  }

  .mat-badge-content {
    z-index: 1;
  }

  .standard {
    color: #767676;
  }

  .selectedIcon {
    color: $color-selected !important;
    position: absolute;
    top: $default-padding-small;
    left: $default-padding-small;
  }

  .selected {
    border: 1px solid $color-selected;
  }

  .card-more-button {
    position: absolute;
    right: 0;
    top: 0;
  }

  .mat-caption {
    color: #999;
  }

  .image-icons {
    position: absolute;
    top: $default-padding-small;
    left: $default-padding-small;
    display: flex;
    align-items: center;
  }
}

apex-client-addon-apartment-info {
  .selected {
    border: 1px solid $color-selected;
  }

  .image {
    height: 400px;
    width: 400px;
    position: relative;

    @include phone() {
      height: 300px;
      width: 300px;
    }

    .thumbnail {
      all: unset;
    }

    mat-icon {
      position: absolute;

      right: $default-padding-small;
      bottom: $default-padding-small;

      color: $primary-foreground;
      filter: drop-shadow(0px 0px 1px $AK-700);
    }
  }

  .standard {
    color: #767676;
  }

  .mat-h3 {
    white-space: break-spaces;
  }

  .mat-mdc-list-base .mat-mdc-list-item {
    color: $apex-main;
  }
}

$client-addon-apartment-card-desktop-size: 200px;
$client-addon-apartment-card-tablet-size: calc(25vw - (#{$default-padding-small} * 2.5));
$client-addon-apartment-card-phone-size: calc(50vw - (#{$default-padding-small} * 3));

apex-client-addon-apartment-card,
portal-addon-order-card {
  @include mat.elevation(2);

  .addon-card-wrapper {
    height: 100%;
    position: relative;

    .filusage-wrapper,
    .fileusages {
      height: $client-addon-apartment-card-desktop-size;

      @include tablet() {
        height: $client-addon-apartment-card-tablet-size;
      }

      @include phone() {
        height: $client-addon-apartment-card-phone-size;
      }
    }

    apex-file-usage-preview {
      position: relative;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      z-index: 1;

      will-change: opacity;
      transition: opacity 0.25s cubic-bezier(0.25, 0.8, 0.25, 1);

      opacity: 0;
      box-shadow: inset 0 0 0 2px $color-selected;
    }

    &.selected {
      &:after {
        opacity: 1;
      }
    }

    .info {
      z-index: 2;
    }

    .thumbnail {
      border: unset;
      border-radius: unset;
      padding: 0;
    }
  }

  .selectedIcon {
    position: absolute;
    top: $default-padding-small;
    left: $default-padding-small;

    will-change: opacity;
    transition: opacity 0.6s cubic-bezier(0.25, 0.8, 0.25, 1);
    color: $color-selected;
  }

  .time-left-warning {
    position: absolute;
    top: $default-padding-small;
    right: $default-padding-small;

    color: $AY-300;

    z-index: 10;
  }

  .invalid-marking {
    color: mat.get-color-from-palette($warn);
  }
}

apex-autocomplete {
  .mat-mdc-form-field {
    width: 100%;
  }

  /*TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version.*/
  mat-progress-bar {
    position: absolute;
    bottom: -4px;
  }
}

apex-confirm-dialog {
  mat-dialog-content {
    button {
      margin-left: $default-padding-small;
      margin-top: $default-padding-small;
    }

    &.mat-mdc-dialog-content {
      overflow: hidden;
    }
  }

  mat-dialog-actions {
    justify-content: flex-end;
  }
}

.mat-mdc-dialog-container {
  apex-file-usage-dialog {
    .mat-mdc-dialog-content {
      padding: 0;

      .wrap {
        height: 100%;
      }
    }
  }
}

apex-file-usage-preview {
  .fileusages {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
  }

  .fileusage {
    position: absolute;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);

    &.selected {
      border: 1px solid grey;
    }

    display: flex;
    flex-direction: column;

    apex-file-preview {
      width: 100%;
      height: 100%;
      pointer-events: none;
    }

    .mat-badge-content.mat-badge-content.mat-badge-content.mat-badge-content {
      top: $default-padding-small;
      right: $default-padding-small;
    }

    &.image-text-background {
      &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        z-index: 1;

        background: linear-gradient(to top, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0) 60%, transparent);
      }
    }
  }

  .image-text {
    color: $primary-foreground;
    text-shadow: 0 0 4px $AK-900;
  }

  .navigators {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: flex;
    align-items: center;
    pointer-events: none;

    button {
      &.left {
        margin-right: auto;

        &:hover:before {
          opacity: 1;
        }
      }

      &.right {
        margin-left: auto;

        &:hover:before {
          opacity: 1;
        }
      }

      &.left,
      &.right {
        position: relative;

        &:before {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;

          z-index: -1;

          background: rgba(0, 0, 0, 0.3);
          @include mat.elevation(2);

          will-change: opacity;

          transition: opacity 0.25s cubic-bezier(0.25, 0.8, 0.25, 1);
          opacity: 0;
          border-radius: inherit;
        }
      }

      color: $primary-foreground;

      pointer-events: all;
      z-index: 10;

      &:not([disabled]) {
        mat-icon {
          text-shadow: 0 0 2px $AK-900;
        }
      }
    }
  }

  .placeholder-image {
    width: 100%;
    height: 100%;
  }
}

apex-file-usage-viewer {
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 1600px;
  height: 100%;
  max-height: 100vh;
  min-width: 100%;
  max-width: 100%;

  .preview-list {
    position: relative;

    button.mat-mdc-button {
      position: absolute;
      top: -($default-padding * 3);
      margin: auto;
    }

    apex-file-usage-preview {
      height: 128px;

      &.hidden {
        height: 0;
      }
    }
  }

  .invalid {
    color: mat.get-color-from-palette($warn) !important;
  }

  .valid {
    color: mat.get-color-from-palette($primary) !important;
  }

  .fileusage-info {
    margin: 0 0 $default-padding 0;
  }
}

apex-file-usage-viewer-dialog {
  display: flex;
  flex-direction: column;
  height: 100%;

  apex-file-usage-viewer {
    .title.title.title {
      display: none;
    }

    .fileusage-info {
      display: none;
    }

    height: unset;
  }

  .mat-mdc-dialog-content.mat-mdc-dialog-content {
    padding: 0;
    min-height: 65vh;
  }
}

apex-loading {
  .overlay {
    position: fixed;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 999999;
    pointer-events: none;
  }

  .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

apex-textarea {
  .mat-mdc-form-field {
    width: 100%;
  }

  textarea {
    min-height: 20px;
  }
}

apex-addon-apartment-form {
  .mat-mdc-form-field-infix {
    width: initial;
  }

  .form-column {
    padding-left: 4px;
    padding-right: 4px;
  }

  .number-column {
    text-align: right;
  }

  .header {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.54);
  }

  mat-icon {
    min-width: 24px;
    min-height: 24px;
  }
}

apex-addon-apartment-form-mobile {
  .form-column {
    padding-left: 4px;
    padding-right: 4px;
  }

  mat-form-field {
    width: 100%;
    text-align: right;
  }

  .expansion-content {
    background-color: $AK-100;
    padding: 16px;
  }
}

apex-addon-apartment-header {
  .form-column {
    padding-left: 4px;
    padding-right: 4px;
  }

  mat-expansion-panel-header {
    mat-icon {
      padding: $default-padding-small;
    }
  }

  mat-form-field {
    width: 100%;
    text-align: right;
  }
}

apex-addon-apartment-list {
  display: flex;
  flex: 1;

  main {
    flex: 1;
    min-width: $desktop-breakpoint-min;

    .loading {
      pointer-events: none;
    }

    .overlay {
      position: absolute;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      z-index: 10;
    }

    .list-header {
      position: sticky;
      top: $header-height;
      background-color: white;
      z-index: 1;
      padding-bottom: $default-padding-small;
      padding-top: $default-padding-small;
    }

    .addon-apartment-form-container {
      background-color: $AK-50;
      position: relative;
    }
  }
}

apex-addon-apartment-row {
  .form-column {
    padding-left: 4px;
    padding-right: 4px;
  }

  mat-expansion-panel-header {
    mat-icon {
      padding: $default-padding-small;
    }
  }

  mat-form-field {
    width: 100%;
    text-align: right;
  }

  .warning {
    color: $apex-error;
    font-weight: 600;
  }
}

apex-addon-card-empty {
  box-sizing: border-box;
  width: 230px;
  min-height: 300px;

  @include phone() {
    min-height: 250px;
    width: 125px;
  }

  .level3 {
    @include mat.typography-level($apex-typography, headline-5);
  }

  background-color: rgba($color: #000000, $alpha: 0);
  border-style: dashed;
  border-color: rgba($color: #000000, $alpha: 0.2);
  border-width: 5px;
  transition: background-color 0.2s;

  main {
    color: rgba($color: #000000, $alpha: 0.3);
    transition: color 0.5s;

    .mat-icon {
      transform: scale(2);
    }

    @include phone() {
      .mat-icon {
        transform: scale(1);
      }
    }
  }

  &:hover {
    background-color: rgba($color: #000000, $alpha: 0.1);

    main {
      color: rgba($color: white, $alpha: 0.8);
    }
  }
}

apex-addon-category-tree {
  li,
  ul {
    margin-top: 0;
    margin-bottom: 0;
    list-style-type: none;
  }

  .parent {
    padding: 0;
  }

  .level0 {
    padding: 0;
  }
}

apex-addon-category-tree-node {
  img {
    margin-bottom: 0;
  }

  .mat-mdc-card-image {
    height: 256px;
    object-fit: contain;
    object-position: center;
  }
}

apex-addon-category-tree-project-node {
  apex-file-usage {
    .wrap.single {
      .content {
        @include phone() {
          min-height: 128px;
        }
      }
    }
  }

  main {
    margin: auto;
  }
}

apex-addon-category-project-table {
  .addon-lines-header {
    th {
      flex: 1;
    }
  }

  apex-addon-category-project-table-body {
    overflow: hidden;
  }

  .footer {
    button {
      flex: unset;
    }
  }
}

apex-addon-filter {
  apex-autocomplete {
    width: 100%;
  }
}

apex-addon-on-apartments-1-page {
  .table-container {
    height: 600px;
    overflow: auto;
  }

  apex-addon-category-project-table {
    table.apex {
      thead {
        tr {
          div:first-child {
            display: none;
          }
        }
      }

      tbody {
        section.table-row > div:first-child {
          display: none;
        }
      }
    }
  }
}

.addon-project-body-wrapper {
  background: $background-white;
  overflow: hidden;
}

.table-row {
  border-bottom: 1px solid $border-color;

  &:last-child {
    border-bottom: unset;
  }

  .addon-lines {
    border-left: 1px solid $border-color;

    .addon-line {
      cursor: pointer;
      border-bottom: 1px solid $border-color;

      &:last-child {
        border-bottom: unset;
      }
    }
  }

  .drag-handle {
    button:not([disabled='true']) {
      cursor: move;
    }
  }
}

.drag-item {
  background: white;
}

.drag-handle {
  width: 40px;
  height: 40px;
}

.cdk-drag-preview {
  box-sizing: border-box;
  box-shadow:
    0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.addon-project-body-wrapper.cdk-drop-list-dragging .drag-item:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

apex-client-addon-alert-dialog {
  .mat-h3 {
    margin: 1rem 0;
  }
}

apex-client-addon-cart-product-card {
  margin-bottom: 1rem;

  .mat-mdc-form-field {
    width: 100%;
  }

  main {
    section {
      &:nth-child(2) {
        margin-bottom: 0;
        margin-top: 0;
      }
    }
  }

  .image-container {
    flex: 1 1 40%;
    max-width: 40%;
  }

  footer {
    dd {
      text-align: right;
    }
  }

  dl {
    dt,
    dd {
      display: inline-block;
    }

    dt {
      width: 40%;
    }

    dd {
      width: 60%;
    }
  }

  apex-file-usage-preview .file-usage-preview-container img {
    object-fit: unset;
  }

  @media screen and (max-width: 599px) {
    .df.main-section {
      flex-direction: column;
    }

    aside {
      &.image-container {
        flex: 1;
        max-width: 100%;
      }
    }
  }
}

apex-client-addon-cart-product-list {
  .product-list-header {
    padding: 0 24px 0 24px;
  }

  h5 {
    padding-right: $default-padding-small;
  }

  h6 {
    padding-right: $default-padding-small;
  }

  .mat-divider {
    position: static !important;
  }
}

apex-client-addon-apartment-tree-node {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    &.level0 {
      @include mat.typography-level($apex-typography, headline-2);
    }

    &.level1 {
      @include mat.typography-level($apex-typography, headline-3);
    }

    &.level2 {
      @include mat.typography-level($apex-typography, headline-4);
    }

    &.level3 {
      @include mat.typography-level($apex-typography, headline-5);
    }

    @include phone() {
      &.level0 {
        @include mat.typography-level($apex-typography, headline-2);
      }

      &.level1 {
        @include mat.typography-level($apex-typography, headline-5);
      }

      &.level2 {
        @include mat.typography-level($apex-typography, headline-6);
      }

      &.level3 {
        @include mat.typography-level($apex-typography, subtitle-1);
      }
    }
  }

  .category-wrapper {
    &.space {
      margin-bottom: $default-padding-small * 12;
    }
  }

  .image-name-wrapper {
    position: relative;

    .fileusage {
      position: relative;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        z-index: 1;

        background: linear-gradient(to top, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0) 50%, transparent);
      }
    }

    .category-title {
      color: $apex-main;

      &-move {
        color: $primary-foreground;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;

        margin-bottom: $default-padding;

        text-shadow: 0 0 4px $AK-900;
      }

      .parent-name {
        position: relative;
        top: 6px;
        left: 3px;
        color: $primary-foreground;
      }
    }
  }

  .header {
    position: relative;
    min-height: 256px;
    height: 256px;
    width: 100%;

    @include phone() {
      min-height: 128px;
      height: 128px;
    }

    &.level1 {
      apex-file-usage-preview {
        .fileusages {
          position: absolute;
          width: 100vw;
          right: 0;
          left: 50%;
          transform: translate(-50%, 0);
        }
      }
    }

    apex-file-usage-preview {
      .thumbnail {
        all: unset;
      }
    }
  }

  .header-text {
    text-align: center;
    position: absolute;
    min-width: 196px;
    bottom: -$default-padding-small;
    left: 5%;
    margin-block-start: 0;
    margin-block-end: 0;
    color: white;
    padding: $default-padding-small;
  }

  .mat-mdc-card-image {
    max-height: 256px;
    object-fit: contain;
    object-position: center;
  }

  .addon-cards-container {
    apex-client-addon-apartment-card {
      overflow: hidden;
    }
  }
}

.addon-cards-container,
.other-addons-container {
  @extend .display-grid-cards;
  @extend .card-width-200;

  margin-bottom: $default-padding * 2;
}

apex-client-addon-order-list {
  header {
    background-color: #fff;
    opacity: 0.95;

    &.sticky {
      position: sticky;
      top: 0;
      z-index: 2;
    }
  }

  .header-wrapper,
  .footer-wrapper {
    margin-right: 40px;
  }

  .addon-order-row {
    border-bottom: 1px solid $border-color;

    .totals {
      T {
        display: none;
      }
    }
  }

  .filters {
    .checkbox-filter {
      .checkbox-filter-content {
        border-radius: 4px;
      }
    }

    .mat-mdc-checkbox-inner-container {
      display: none;
    }

    // override bootstrap stuff
    label {
      margin-bottom: 0;
      font-weight: unset;
    }
  }

  footer {
    .footer-values {
      .footer-label {
        display: none;
      }

      .footer-value {
        margin: unset;
        @extend .highlight;

        &:first-of-type {
          @extend .amr;
        }

        &:last-of-type {
          @extend .aml;
        }
      }
    }
  }

  @mixin tabletPhoneMix {
    header {
      .header-wrapper {
        margin: unset;
        padding-right: $default-padding;

        h2 {
          width: 100%;
        }

        .price-headers {
          display: none;
        }
      }
    }

    footer {
      .footer-wrapper {
        margin: unset;
        padding-right: $default-padding;

        .w-60 {
          display: none;
        }

        .footer-values {
          margin: unset;
          justify-content: space-between;

          .footer-value:first-of-type {
            justify-content: flex-start;
          }

          .footer-label {
            display: inline-block;
          }
        }
      }
    }

    .addon-order-row {
      header {
        .header-content {
          flex-direction: column;
          align-items: flex-start;
          margin-right: $default-padding-small;

          section:nth-child(1) {
            width: 100%;
            margin: unset;
          }

          .totals {
            width: 100%;
            margin: unset;
            margin-top: $default-padding-small;

            T {
              display: block;
            }
          }
        }
      }
    }
  }

  @include phone() {
    .filters {
      mat-checkbox {
        width: 50%;

        &:nth-child(even) {
          display: flex;
          justify-content: flex-end;
        }
      }
    }
    @include tabletPhoneMix();
  }

  @include tablet {
    @include tabletPhoneMix();
  }
}

apex-client-addon-order-card {
  .image-container {
    flex: 1 1 40%;
    max-width: 40%;
  }

  footer {
    dd {
      text-align: right;
    }
  }

  dl {
    dt,
    dd {
      display: inline-block;
    }

    dt {
      width: 40%;
    }

    dd {
      width: 60%;
    }
  }

  apex-file-usage-preview .file-usage-preview-container img {
    object-fit: unset;
  }

  @media screen and (max-width: 599px) {
    .df.main-section {
      flex-direction: column;
    }

    aside {
      &.image-container {
        flex: 1;
        max-width: 100%;
      }
    }
  }
}

apex-client-apartment-card {
  mat-card {
    height: 120px;
  }
}

apex-client-addon-apartment-list {
  apex-client-addon-apartment-card {
    width: 100%;
  }
}

apex-client-addon-breadcrumb {
  .apex-client-addon-breadcrumb {
    padding: $default-padding-small 0 $default-padding-small 0;

    a {
      @include mat.typography-level($apex-typography, subtitle-2);
      color: $link-color;
    }
  }

  &.addons-breadcrumb {
    .apex-client-addon-breadcrumb {
      .breadcrumb-wrapper {
        z-index: 11;
        @include phone() {
          z-index: 9;
        }
      }

      .action-buttons {
        width: 100%;
        padding-top: $default-padding-small;
        padding-bottom: $default-padding-small;
        padding-right: $default-padding;
        z-index: 10;

        @include phone() {
          padding: 0;
        }
      }
    }
  }
}

.toc-wrapper {
  max-height: calc(100vh - 230px);

  a.in-view {
    text-shadow: 0 0 0 $link-color;
  }

  a:hover {
    color: $AT-900;
    text-decoration: underline;
  }

  a.toc-strong {
    font-weight: 600;
  }
}

apex-client-addon-addons-page {
  .client-addon-tree-container {
    margin: auto;
    max-width: 1010px;
  }

  .banner-images {
    position: relative;
    height: 256px;

    apex-file-usage-preview {
      .thumbnail {
        all: unset;
      }

      .fileusages {
        position: absolute;
        width: 100vw;
        right: 0;
        left: 50%;
        transform: translate(-50%, 0);

        height: 256px;
      }
    }
  }
}

apex-client-addon-overview-page {
  apex-client-addon-apartment-card {
    .addon-name {
      margin-bottom: $default-padding-small;
    }

    .mat-caption.mat-h3 {
      display: none;
    }

    .expires {
      display: none;
    }

    .addon-text-container {
      display: none;
    }
  }

  apex-client-addon-apartment-info {
    & > div {
      display: flex;
      flex-direction: row-reverse;

      .image {
        height: 300px;
        width: 300px;
        @include phone() {
          height: 200px;
          width: 200px;
        }
      }

      .thumbnail {
        all: unset;
      }

      .prices,
      .expires {
        display: none;
      }
    }
  }
}

apex-addon-overview-page,
apex-client-addon-overview-page {
  .selectedIcon {
    display: none;
  }

  .selected {
    &:after {
      all: unset;
    }
  }

  button.mat-mdc-button {
    display: none;
  }
}

apex-client-addon-cart-page {
  .client-addon-tree-container {
    margin: auto;
    max-width: 1010px;
  }

  .info-box {
    margin: 0.5rem 0;
    padding: 1rem;
    border: 1px solid #ccc;
    background: #eee;

    p {
      margin: 0;
      padding: 0;
    }
  }
}

apex-client-addon-info-page {
  .client-addon-tree-container {
    margin: auto;
    max-width: 1010px;
  }

  .info-other-addons {
    margin-top: 64px;
    margin-bottom: 64px;
  }
}

apex-testing-file-usage {
  :host > div > * {
    margin: 2rem;
  }
}

apex-sales-screen {
  table {
    &.mat-table {
      border-spacing: 0;
    }

    tr.mat-header-row {
      height: 56px;
    }

    th.mat-header-cell,
    td.mat-cell,
    td.mat-footer-cell {
      padding: 0;
      border-bottom-width: 1px;
      border-bottom-style: solid;
      border-bottom-color: $AK-200;
    }

    th.mat-header-cell:last-of-type,
    td.mat-cell:last-of-type,
    td.mat-footer-cell:last-of-type {
      padding-right: 24px;
    }

    tr.mat-row,
    tr.mat-footer-row {
      height: 48px;
    }

    th.mat-header-cell:first-of-type,
    td.mat-cell:first-of-type,
    td.mat-footer-cell:first-of-type {
      padding-left: 24px;
    }

    tr:hover {
      background-color: #f0f0f0;
    }
  }

  div.content {
    padding: 1rem;

    .logo {
      height: 64px;

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }

    table {
      th {
        text-align: center;
        white-space: break-spaces;

        padding: 0 0.25rem;
      }

      td {
        text-align: center;
      }

      th:first-child,
      td:first-child {
        text-align: left;
        padding-left: $default-padding-small;
      }

      th:last-child,
      td:last-child {
        padding-right: $default-padding-small;
      }

      tr + tr:last-of-type {
        background: #eeffee;

        white-space: pre;

        td {
          font-weight: bold;
        }

        td:nth-child(9),
        td:nth-child(10) {
          font-size: 0;
        }
      }
    }

    footer {
      margin: 1rem 0 1rem 0;

      div {
        padding: 0.5rem;
        text-align: center;

        h2 {
          font-weight: bold;
          margin: 0;
          padding: 0;
        }
      }
    }
  }

  p {
    margin: 0;
    padding: 0;
  }
}

body.sales-screen,
body.reporting {
  * {
    @media only screen and (min-width: 1201px) {
      font-size: 14px;

      .mat-headline-5 {
        font-size: 22px;
      }
    }
    @media only screen and (min-width: 1491px) {
      font-size: 18px;

      .mat-headline-5 {
        font-size: 24px;
      }
    }
  }
}

apex-takeover-recipients-form {
  form {
    padding: 1rem;
  }

  mat-form-field {
    padding: $default-padding-small;
  }
}

apex-takeover-recipients-list {
  .mat-mdc-card {
    margin: $default-padding-small;
  }

  .mat-mdc-card .mat-icon {
    color: #767676;
  }
}

apex-fields-form {
  form {
    padding: 1rem;
  }

  mat-form-field {
    padding: $default-padding-small;
  }
}

apex-fields-list {
  .mat-mdc-card {
    margin: $default-padding-small;
  }

  .mat-mdc-card .mat-icon {
    color: #767676;
  }

  .deactivated {
    background-color: #dcdcdc;
  }
}

apex-case-addon-order,
angular-case-addon-order {
  .status-icon {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      background-color: $primary-foreground;
      border-radius: 50%;
      z-index: -1;
    }
  }

  main {
    .addon-info {
      aside {
        $image-size: 150px;
        width: $image-size;
        height: $image-size;
      }
    }
  }
}

apex-object-project-view {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;

  .view-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: auto;
  }

  nav.mat-mdc-tab-nav-bar {
    background-color: $AK-100;

    .mat-mdc-tab-header-pagination {
      background-color: map-get($primary, 500);
      box-shadow: none;

      &.mat-mdc-tab-header-pagination-disabled {
        .mat-mdc-tab-header-pagination-chevron {
          opacity: 0.38;
        }
      }

      .mat-mdc-tab-header-pagination-chevron {
        border-color: $AK-50;
      }
    }

    .mat-mdc-tab-links {
      .mat-mdc-tab-link {
        &.mat-mdc-tab-label-active {
          opacity: 1;
        }
      }

      .mat-ink-bar.mat-ink-bar.mat-ink-bar.mat-ink-bar {
        background-color: map-get($primary, 900);
      }
    }
  }

  .sticky {
    &-top {
      position: sticky;
      top: 0;
      z-index: 2;
    }
  }

  .info-card-container {
    &.df {
      @media screen and (max-width: 600px) {
        flex-direction: column;
      }
    }
  }

  .info-card {
    width: calc(50% - #{$default-padding * 2});

    @include phone() {
      width: 100%;
    }
  }

  form {
    mat-divider[aria-orientation='horizontal'] {
      @extend .amb;
    }
  }

  apex-cases {
    .case-list-wrap {
      > div {
        margin: $default-padding-small $default-padding;
      }
    }
  }
}

apex-object-view {
  .info {
    margin-right: 1rem;

    mat-divider {
      margin-top: 0.5rem;
    }
  }
}

apex-object-project-form,
apex-object-project-tenancy {
  @mixin media {
    flex-direction: column;
    margin: 0;

    .internal {
      padding: 0;
    }
  }

  form {
    .container {
      @include tablet() {
        @include media();
      }

      @include phone() {
        @include media();
      }
    }
  }
}

apex-case-object-info,
angular-case-object-info {
  .info-card {
    border: 1px solid $border-color;
  }

  .mat-divider {
    @extend .amh;
  }

  .open {
    background-color: map-get($primary, 700);
    color: $primary-foreground;
  }

  apex-map > div {
    min-height: 128px;
  }
}

apex-apartment-sale-log {
  .mat-expansion-panel-body {
    overflow-x: auto;
  }
}

apex-object-selector,
angular-object-selector {
  display: flex;
  flex: 1;

  .mat-mdc-standard-chip .mat-mdc-chip-action-label,
  .mat-mdc-standard-chip .mdc-evolution-chip__action--primary {
    overflow: hidden;
  }

  &:not(.multiple) {
    mat-chip-list {
      mat-chip.mat-chip.mat-chip.mat-chip.mat-chip.mat-chip {
        padding-left: 4px;

        .mat-mdc-chip-ripple {
          display: none;
        }

        &.mat-mdc-standard-chip {
          &:hover:after {
            all: unset;
          }

          &:focus:after {
            all: unset;
          }
        }

        mat-icon {
          display: none;
        }
      }
    }
  }
}

apex-object-selector-dialog {
  @include mat.elevation(2);

  display: block;
  width: 480px;
  max-width: 100%;
  max-height: 100%;
  background: $background-white;

  h1.mat-body-1 {
    margin: 0;
  }

  header {
    &.toolbar {
      padding: 8px 0;
    }
  }

  footer {
    padding: $default-padding-small;
  }

  div.container {
    height: 100%;

    main {
      overflow: auto;
    }
  }

  main {
    height: 320px;
  }

  .viewport {
    width: 480px;
    height: 320px;

    max-height: 100%;
    max-width: 100%;

    overflow-x: hidden;

    .cdk-virtual-scroll-content-wrapper {
      max-width: 100%;
    }

    .item {
      padding: $default-padding-small;

      &.selected {
        background: $AT-100;
      }

      &.steps-1 {
        padding-left: 0.5rem;
      }

      &.steps-2 {
        padding-left: 1rem;
      }

      &.steps-3 {
        padding-left: 1.5rem;
      }

      &.steps-4 {
        padding-left: 2rem;
      }

      &.steps-5 {
        padding-left: 2.5rem;
      }
    }
  }
}

apex-portal {
  * {
    box-sizing: border-box;
  }

  .header-divider {
    @extend .amh;
  }
}

.cdk-overlay-container {
  .tenancy {
    .mat-mdc-dialog-container {
      .mat-mdc-dialog-content {
        min-height: auto;

        apex-case-form {
          form {
            .common {
              apex-autocomplete:not(.object-field) {
                display: none;
              }
            }

            .mat-accordion {
              .mat-expansion-panel:not(.property):not(.marking) {
                display: none;
              }

              .property {
                apex-autocomplete[type='agreement'] {
                  display: none;
                }

                apex-autocomplete[type='tenancy'] {
                  pointer-events: none;

                  input {
                    color: rgba(0, 0, 0, 0.38);
                    cursor: default;
                  }

                  .mat-mdc-form-field-underline {
                    background-image: linear-gradient(
                      to right,
                      rgba(0, 0, 0, 0.42) 0%,
                      rgba(0, 0, 0, 0.42) 33%,
                      transparent 0%
                    );
                    background-size: 4px 100%;
                    background-repeat: repeat-x;
                    background-position: 0;
                    background-color: transparent;
                  }
                }
              }
            }
          }
        }
      }

      .mat-mdc-dialog-actions {
        .mat-mdc-slide-toggle {
          display: none;
        }
      }
    }
  }

  .mat-mdc-dialog-content {
    &.tenancy {
      .case {
        .mat-mdc-tab-labels {
          > div:nth-child(2) {
            display: none;
          }
        }

        .mat-mdc-tab-body-wrapper {
          > .mat-mdc-tab-body:first-of-type {
            .actions:first-of-type {
              display: none;
            }

            .new-contractor {
              display: none;
            }
          }
        }
      }
    }
  }

  .overlay-apex-user-card {
    background-color: $AK-50;

    filter: drop-shadow(0px 3px 1px rgb(0 0 0 / 20%)) drop-shadow(-1px 2px 2px rgb(0 0 0 / 14%))
      drop-shadow(0px 1px 5px rgb(0 0 0 / 12%));

    &.is-over:after,
    &.is-under:before {
      content: '';
      width: 0;
      height: 0;

      position: absolute;

      left: $default-padding - 1;

      @include phone() {
        content: none;
      }
    }

    &.is-over {
      &:after {
        bottom: -$default-padding-small;

        border-right: $default-padding-small solid transparent;
        border-left: $default-padding-small + 2 solid transparent;
        border-top: $default-padding-small solid $AK-50;
      }
    }

    &.is-under {
      &:before {
        top: -$default-padding-small;

        border-right: $default-padding-small solid transparent;
        border-left: $default-padding-small + 2 solid transparent;
        border-bottom: $default-padding-small solid $AK-50;
      }
    }
  }

  .tenancy-fields-dialog {
    min-width: 400px;
  }
}

apex-portal-safety-view {
  .info-card {
    width: calc(33.33% - #{$default-padding * 2});

    @include phone() {
      width: 100%;
    }

    @include tablet() {
      width: calc(50% - #{$default-padding * 2});
    }
  }
}

apex-portal-safety-view {
  .fileUsages {
    @include phone() {
      flex-direction: column;
    }

    @include tablet() {
      flex-direction: column;
    }
  }
}

apex-portal-tenancy-view {
  .info-card {
    width: calc(50% - #{$default-padding * 2});

    @include phone() {
      width: 100%;
    }
  }
}

apex-handwritten-signing {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;

  canvas {
    width: 100%;
    box-sizing: border-box;
    border: solid 1px black;
  }

  mat-dialog-content {
    display: flex !important;
    flex: 1;
  }
}

apex-embedded-markings-viewer {
  .wrap {
    width: 100%;
    height: 150px;
    position: relative;
    overflow: hidden;
  }

  mat-progress-bar.mat-progress-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .image-wrap {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;

    img {
      width: 100%;
      object-fit: contain;
    }

    .marking {
      position: absolute;
      z-index: 1;
      background-color: mat.get-color-from-palette($primary);
      color: white;
      border-radius: 100%;
      display: flex;
      font-size: 10px;
      justify-content: center;
      align-items: center;
      width: 20px;
      height: 20px;

      border: 2px solid $AK-900;

      &.case {
        &.completed {
          background-color: $AG-300;
        }

        &.accepted {
          background-color: $contractor-color;
        }

        &.declined {
          background-color: mat.get-color-from-palette($warn);
        }

        &.has-color {
          background: radial-gradient(
            closest-side,
            var(--field-color) 0%,
            var(--field-color) 60%,
            $AT-700 60%,
            $AT-700 100%
          );

          &.completed {
            background: radial-gradient(
              closest-side,
              var(--field-color) 0%,
              var(--field-color) 60%,
              $AG-300 60%,
              $AG-300 100%
            );
          }

          &.accepted {
            background: radial-gradient(
              closest-side,
              var(--field-color) 0%,
              var(--field-color) 60%,
              $contractor-color 60%,
              $contractor-color 100%
            );
          }

          &.declined {
            background: radial-gradient(
              closest-side,
              var(--field-color) 0%,
              var(--field-color) 60%,
              mat.get-color-from-palette($warn) 60%,
              mat.get-color-from-palette($warn) 100%
            );
          }
        }
      }
    }
  }
}

apex-filter-chips {
  display: flex;
  flex: 1;

  mat-chip-grid {
    .mat-mdc-standard-chip .mdc-evolution-chip__action--primary {
      overflow: hidden;
    }

    .mat-mdc-standard-chip .mat-mdc-chip-action-label {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .mat-mdc-form-field {
    width: 100%;
    display: flex;

    .mat-mdc-form-field-wrapper {
      display: flex;
      flex: 1;

      .mat-mdc-form-field-flex {
        display: flex;
        flex: 1;
        align-items: stretch;

        .mat-mdc-form-field-infix {
          flex: 1;
          display: flex;
          flex-direction: column;

          mat-chip-list {
            flex: 1;
            display: flex;
            flex-direction: column;

            .mat-mdc-chip-list-wrapper {
              align-items: flex-start;
              flex: 1;

              input {
                justify-self: flex-end;
                align-self: flex-end;
              }
            }
          }
        }
      }
    }

    .mat-form-field-wrapper:hover {
      cursor: text;
    }
  }
}

@mixin template-form-common() {
  $minHeight: 150px;

  apex-file-usage {
    .wrap {
      @extend .w-100;
      min-width: $minHeight;
    }
  }
}

apex-checklist-template-list,
apex-checklist-list,
apex-customer-list,
apex-field-checklist-project-list {
  .list-container {
    mat-nav-list {
      &.mat-mdc-list-base {
        @extend .rpt;
      }

      mat-icon.complete-icon {
        $icon-size: 45px;
        font-size: 46px;
        line-height: $icon-size;
        height: $icon-size;
        width: 43px;
      }

      .mat-mdc-list-item:last-of-type {
        .mat-divider {
          display: none;
        }
      }
    }
  }
}

apex-checklist-template-form,
apex-checklist-form {
  padding-bottom: $header-height;

  .mat-mdc-tab-group {
    .mat-mdc-tab-body-wrapper {
      flex: 1;

      .mat-mdc-tab-body-content {
        scroll-behavior: smooth;

        @media (prefers-reduced-motion) {
          scroll-behavior: unset;
        }
      }
    }
  }

  apex-checklist-template-item-form,
  apex-checklist-item {
    + .mat-divider:last-of-type {
      display: none;
    }
  }

  .meta-container {
    mat-checkbox {
      .mat.mdc-checkbox-layout {
        white-space: normal;
      }
    }
  }

  .checklist-template-item {
    apex-autocomplete-viewer {
      div {
        @extend .truncate;
      }
    }

    .file-on-case {
      color: $apex-gray;
    }
  }

  .meta-container,
  .checklist-items-container {
    @include template-form-common();
  }
}

.checklist-item {
  touch-action: pan-y !important;
}

.checklist-template-items-drop-list {
  background: $background-white;
  overflow: hidden;
}

/* #region drag and drop for checklist-template item */

.checklist-template-item {
  @include template-form-common();
  background: $background-white;
  overflow: hidden;
}

.checklist-template-item.cdk-drag-preview {
  box-sizing: border-box;
  box-shadow:
    0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.checklist-template-item.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.checklist-template-items-drop-list.cdk-drop-list-dragging .checklist-template-item:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

/* #endregion drag and drop end */

apex-checklist-item {
  @include print() {
    display: block;
    @include mat.elevation(2);
    break-inside: avoid;
    margin: 1px 0;
    position: relative;
  }

  .checklist-item-wrapper {
    position: relative;
    overflow: hidden;

    .checklist-item {
      position: relative;
      z-index: 1;
      background-color: $background-white;
      will-change: transform;

      &.transition {
        transition: all 0.6s cubic-bezier(0.25, 0.8, 0.25, 1);
      }

      apex-case-new-message {
        form {
          > div {
            &:nth-child(2) {
              display: none;
            }
          }
        }
      }
    }

    .delete-item {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      background-color: $AK-100;

      transition: all 0.6s cubic-bezier(0.25, 0.8, 0.25, 1);

      &.delete {
        background-color: $apex-error;

        mat-icon {
          color: $AK-50;
        }
      }

      .mat-icon {
        padding-left: 20px;
      }
    }
  }

  .checklist-item {
    .note {
      .mat-mdc-form-field-wrapper {
        margin-bottom: -1.25em;
      }

      .mat-mdc-form-field-outline-gap {
        transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
        width: 37px !important;
      }

      .showSaved {
        .mat-mdc-form-field-outline-gap {
          width: 50px !important;
        }
      }
    }

    .file-on-case {
      color: $apex-gray;
    }

    a {
      width: fit-content;
    }

    .complete-item-button {
      $button-size: 48px;
      height: $button-size;
      width: $button-size;

      padding: 4px;

      mat-icon {
        $icon-size: 40px;
        font-size: $icon-size;
        line-height: $icon-size;
        height: $icon-size;
        width: $icon-size;
      }
    }

    mat-icon {
      &.case-manager {
        color: $casemanager-color;
      }

      &.contractor {
        color: $contractor-color;
      }
    }
  }

  &.not-applicable {
    opacity: 0.5;
    text-decoration: line-through;
  }
}

apex-checklist-item-list {
  display: flex;
  flex-direction: column;
  position: relative;

  @include print() {
    display: block;
  }

  & > div.mat-elevation-z2 {
    @include print() {
      box-shadow: none;
      background-color: unset;
      position: relative;

      & > .mat-divider {
        display: none;
      }
    }
  }

  & > .mat-divider {
    @extend .amv;
  }

  .note {
    > div:last-child {
      display: none;
    }
  }

  .apex-size-above-break {
    .note-line {
      display: none;
    }
  }

  .apex-size-below-break {
    .note-line {
      display: block;
    }

    .note {
      > div:first-child {
        display: none;

        &.show-note-field {
          display: block;
        }
      }

      > div:last-child {
        display: block;
      }
    }
  }
}

apex-new-checklist-dialog {
  div.mat-mdc-dialog-content.mat-mdc-dialog-content {
    margin: 0;
    padding: $default-padding;

    apex-page-info {
      display: none;
    }

    .filter-container {
      margin-left: 0;
      margin-right: 0;
    }

    .list-container {
      margin: 0;
      padding: 0;

      min-width: 50vw;
      max-width: calc(80vw - (#{$default-padding} * 2));
    }
  }
}

apex-cases-page {
  height: 100%;
  display: flex;
  flex-direction: column;
}

apex-case-print-page {
  @media print {
    apex-page-info {
      display: none;
    }
  }

  .case {
    .case-card {
      .actions,
      .new-message,
      .new-contractor,
      .mat-mdc-tab-header,
      .project-shortcut,
      .apartment-shortcut,
      .remove-contractor,
      .files-are-visible-reminder {
        display: none;
      }

      apex-embedded-markings-viewer {
        pointer-events: none;

        .marking {
          -webkit-print-color-adjust: exact;
          print-color-adjust: exact;
        }
      }

      .mat-mdc-card-avatar {
        -webkit-print-color-adjust: exact;
        print-color-adjust: exact;
      }

      @media print {
        .mark-as-unread {
          display: none;
        }
      }
    }

    apex-translator {
      mat-icon {
        display: none;
      }
    }

    apex-file-usage {
      .file-download,
      mat-checkbox {
        display: none;
      }
    }
  }
}

apex-cases {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;

  main {
    position: relative;
    display: flex;
    flex: 1;
    max-height: 100%;

    .mat-mdc-tab-group {
      flex: 1;
      max-width: 100%;
      max-height: 100%;
      width: 100%;

      .mat-mdc-tab-body-wrapper {
        flex: 1;
      }

      .mat-mdc-tab-body-content {
        display: flex;
        flex: 1;
      }

      .mat-mdc-tab-labels {
        .mat-mdc-tab-label:last-child {
          flex: 1;
          opacity: 1;
          justify-content: flex-end;
        }
      }
    }
  }

  .case-marking-wrap {
    width: 100%;

    apex-case-marking {
      height: 100%;
    }
  }

  .case-and-case-list {
    flex: 1;
    max-width: 100%;

    .case-list-wrap {
      max-height: 100%;
      flex: 6;
      box-sizing: border-box;
      overflow: auto;

      @include tablet {
        flex: 100%;
      }

      @include phone {
        flex: 100%;
      }
    }

    .mat-mdc-tab-group {
      .mat-mdc-tab-body-content {
        display: block;
      }
    }
  }

  footer {
    clip-path: footerpolygon(50, 36);
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 1;
  }
}

apex-case-view-dialog {
  div.mat-mdc-dialog-content.df.oh.rp {
    display: flex;
    padding: 0;
    overflow: hidden;

    apex-case-view {
      .case {
        .save-btn {
          display: none;
        }
      }
    }
  }
}

apex-case-view {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  flex: 1;

  .mat-mdc-tab-labels {
    .mat-mdc-tab-label {
      flex: 1;
    }

    .mat-mdc-tab-label:last-child {
      flex: none;
      min-width: 0;
      padding: 0;

      mat-icon {
        color: black;
      }
    }
  }

  .other {
    mat-tab-header {
      @include others();
    }
  }

  .pending,
  .declined,
  .completed {
    mat-tab-header {
      .mat-mdc-tab-label,
      .mat-mdc-tab-labels .mat-mdc-tab-label:last-child mat-icon {
        color: rgba(0, 0, 0, 0.87);
      }
    }

    // mat-tab-header label when active want black font color
    .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
      color: rgba(0, 0, 0, 0.87);
    }

    .mat-mdc-tab-group .mat-ink-bar {
      background-color: $AT-700;
    }
  }

  .pending {
    mat-tab-header {
      background-color: scale-color($AY-200, $lightness: 70%);
    }
  }

  .declined {
    mat-tab-header {
      background-color: scale-color($AR-200, $lightness: 70%);
    }
  }

  .completed {
    mat-tab-header {
      background-color: scale-color($AG-200, $lightness: 70%);
    }
  }

  .case {
    height: 100%;
    width: 100%;
    display: flex;

    .loading {
      position: absolute;
      width: 100%;
      height: 100%;
      pointer-events: none;
      z-index: 2;
    }

    .case-card {
      flex: 1;
      background-color: $AK-50;
    }
  }

  .icons {
    mat-icon:hover {
      cursor: pointer;
    }
  }

  .mat-mdc-card-avatar {
    color: $AK-50;
    width: 24px;
    height: 24px;
    padding: $default-padding-small;
    border-radius: 50%;
  }

  .apex-file-usage {
    .wrap {
      padding-bottom: 4px;
    }
  }

  .mat-mdc-tab-body-content {
    overflow: hidden;
  }

  .times {
    position: absolute;
    top: 0.25rem;
    right: 0.25rem;
  }

  mat-icon {
    &.contractor {
      color: $contractor-color;
    }

    &.client {
      color: $client-color;
    }

    &.casemanager {
      color: $casemanager-color;
    }

    &.system {
      color: $apex-gray;
    }
  }

  apex-color-picker,
  apex-color-picker .color-box {
    $size: 16px;

    width: $size;
    height: $size;

    mat-icon {
      width: inherit;
      height: inherit;
      font-size: inherit;
      font-weight: bold;
      line-height: inherit;
    }
  }
}

apex-case-form,
apex-contractor-dialog {
  .from-wrapper {
    position: relative;

    .from-before-to-error {
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
}

apex-case-form {
  .checklist-template {
    .items-wrapper {
      @include desktop() {
        max-height: 270px;
        overflow: auto;
      }
    }

    .complete-item-button {
      $button-size: 48px;
      height: $button-size;
      width: $button-size;

      padding: 6px;

      mat-icon {
        $icon-size: 40px;
        font-size: $icon-size;
        line-height: $icon-size;
        height: $icon-size;
        width: $icon-size;
      }
    }
  }
}

apex-case-contractors {
  .contractors {
    .contractor {
      .contractor-status-icon {
        mat-icon {
          color: mat.get-color-from-palette($primary);
        }
      }

      &.new {
        &:before {
          all: unset;
        }

        .contractor-status-icon {
          mat-icon {
            color: mat.get-color-from-palette($primary);
          }
        }
      }

      &.accepted {
        .contractor-status-icon {
          mat-icon {
            color: $contractor-color;
          }
        }
      }

      &.declined {
        .contractor-status-icon {
          mat-icon {
            color: mat.get-color-from-palette($warn);
          }
        }
      }

      &.completed {
        .contractor-status-icon {
          mat-icon {
            color: $AG-300;
          }
        }
      }
    }
  }
}

apex-case-form-dialog {
  .mat-mdc-dialog-content.mat-mdc-dialog-content {
    padding: $default-padding-small;
    min-height: 65vh;
  }

  .yellow-info-card {
    background-color: $AY-100;
    border: 1px solid $AY-300;
  }
}

apex-case-list {
  .filters {
    background-color: $AK-50;
  }

  .case-list-header {
    position: sticky;
    top: 0px;
    z-index: 999;
    background-color: $AK-100;
  }

  .case {
    background-color: $AK-50;

    .other {
      @include others();
    }

    &.list-item {
      position: relative;
      margin-bottom: 1px;
      display: flex;
    }

    &.pending {
      .left {
        background-color: scale-color($AY-200, $lightness: 70%);
      }

      .right {
        background-color: scale-color($AY-200, $lightness: 95%);
      }
    }

    &.declined {
      .left {
        background-color: scale-color($AR-200, $lightness: 70%);
      }

      .right {
        background-color: scale-color($AR-200, $lightness: 95%);
      }
    }

    &.complete {
      .left {
        background-color: scale-color($AG-200, $lightness: 70%);
      }

      .right {
        background-color: scale-color($AG-200, $lightness: 95%);
      }
    }

    &.others {
      .left {
        background-color: scale-color($AB-200, $lightness: 70%);
      }

      .right {
        background-color: scale-color($AB-200, $lightness: 95%);
      }
    }

    &:hover {
      .right {
        background-color: $hover-color;
      }
    }

    &.selected {
      box-shadow: 0 0 0 2px $apex-main;
      margin-bottom: 2px;
    }

    &.archived {
      .left {
        background-color: scale-color($color-disabled, $lightness: 70%);
      }

      .right {
        background-color: scale-color($color-disabled, $lightness: 90%);
      }
    }
  }
}

.contractor mat-icon {
  color: $contractor-color;
}

.client mat-icon {
  color: $client-color;
}

apex-case-new-message {
  mat-checkbox {
    padding-bottom: $default-padding;
  }
}

apex-case-new-message-dialog {
  .mat-mdc-dialog-content {
    width: 400px;
    overflow-x: hidden;
  }
}

apex-case-mass-edit {
  position: relative;

  apex-autocomplete {
    flex: 1;
  }

  .autocomplete-with-remove {
    padding-right: $default-padding;
  }

  .mat-mdc-dialog-content {
    overflow-x: hidden;
  }

  mat-progress-bar {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
  }
}

apex-case-marking {
  width: 100%;
  display: flex;
  flex: 1;
  box-sizing: border-box;
  @extend .ap;

  apex-file-usage-viewer {
    width: 100%;

    .marking-total {
      display: none;
    }
  }

  main {
    display: flex;
    flex-direction: column;

    @include phone() {
      min-height: 400px;
    }

    @include tablet() {
      min-height: 400px;
    }
  }
}

apex-case-calendar {
  @include flex-column();

  .navigation-bar {
    position: relative;
    box-sizing: border-box;
    min-height: 56px;

    .loading {
      position: absolute;
      left: 0;
      bottom: -1px;
      width: 100%;
    }

    button {
      span.mat-mdc-button-wrapper {
        display: flex;
        align-items: center;
      }
    }
  }

  .loading-screen {
    opacity: 0.5;
    pointer-events: none;
  }

  .calendar {
    max-height: calc(100vh - (56px * 3));
    overflow: auto;

    * {
      box-sizing: border-box;
    }

    .row {
      position: relative;
    }

    $sideBar: 40px;

    .time-container {
      position: relative;
      background-color: $AK-700;
      color: $primary-foreground;
      padding: $default-padding-extra-small;
      min-width: $sideBar;
      max-width: $sideBar;
      min-height: 56px;

      cursor: pointer;

      .time {
        position: relative;
        top: -(mat.font-size($apex-typography, caption)) * mat.line-height($apex-typography, caption);
      }
    }

    apex-calendar-day-view,
    apex-calendar-week-view {
      display: flex;
      flex: 1;
      position: relative;
      z-index: 1;

      .range-cases-wrapper {
        z-index: 2;
        border-top: 1px solid $border-color;

        .left {
          min-width: $sideBar;
          max-width: $sideBar;
        }

        .range-cases-container {
          position: relative;
          z-index: 1;

          box-shadow:
            0.5px 0 $border-color,
            -0.5px 0 $border-color;

          .case-wrapper {
            position: relative;
            filter: drop-shadow(0px 3px 1px rgb(0 0 0 / 20%)) drop-shadow(-1px 2px 2px rgb(0 0 0 / 14%))
              drop-shadow(0px 1px 5px rgb(0 0 0 / 12%));

            $arrow-size: 10px;
            $space-right: $default-padding-extra-small;
            padding-right: $space-right;

            &.from-before {
              padding-left: $arrow-size;

              &:before {
                content: '';
                position: absolute;
                width: 0;
                height: 0;
                border-top: $arrow-size solid transparent;
                border-bottom: $arrow-size solid transparent;
                border-right: $arrow-size solid $apex-main;
                top: 0;
                left: 0;
              }

              &.rc {
                &:before {
                  border-right-color: $apex-gray;
                }
              }
            }

            &.to-after {
              padding-right: $arrow-size + $space-right;

              &:after {
                content: '';
                position: absolute;
                width: 0;
                height: 0;
                border-top: $arrow-size solid transparent;
                border-bottom: $arrow-size solid transparent;
                border-left: $arrow-size solid $apex-main;
                top: 0;
                right: $space-right;
              }

              &.rc {
                &:after {
                  border-left-color: $apex-gray;
                }
              }
            }

            .case {
              margin: 4px 0;
              border: unset;

              &:hover {
                box-shadow: unset;
              }
            }
          }
        }

        .case {
          padding: $default-padding-extra-small;
          margin: $default-padding-extra-small;

          line-height: mat.font-size($apex-typography, caption);
        }

        .more {
          padding: $default-padding-extra-small;
          margin: $default-padding-extra-small;

          color: $apex-main;
        }
      }

      .case {
        background-color: $apex-main;
        color: $primary-foreground;

        min-height: mat.font-size($apex-typography, caption) * mat.line-height($apex-typography, caption);
        line-height: math.div(mat.font-size($apex-typography, caption), 2);

        border: 1px solid $AT-500;

        &.rc {
          background-color: $apex-gray;
        }

        &:hover {
          @include mat.elevation(6);
          border-color: white;
        }
      }
    }

    apex-calendar-week-view {
      .range-cases-wrapper {
        position: relative;

        .right {
          position: relative;
        }

        .date-name-wrapper {
          position: relative;
          z-index: 10;
        }

        .dates-wrapper {
          position: absolute;
          top: 0;
          height: 100%;
          width: 100%;

          .date {
            box-shadow:
              0.5px 0 $border-color,
              -0.5px 0 $border-color;
          }
        }
      }
    }

    apex-calendar-day-view {
      .day-cases-container {
        position: relative;
      }

      .timeslot {
        min-height: 56px;

        .slot-wrapper {
          box-shadow: 0 0 0 0.5px $border-color;
          position: relative;

          .slot {
            position: absolute;
            width: 100%;
            height: 100%;

            z-index: 1;
          }
        }
      }

      .case {
        background-color: $apex-main;
        color: $primary-foreground;

        min-height: mat.font-size($apex-typography, caption) * mat.line-height($apex-typography, caption);
        line-height: math.div(mat.font-size($apex-typography, caption), 2);

        border: 1px solid $AT-500;

        &.rc {
          background-color: $apex-gray;
        }

        &:hover {
          @include mat.elevation(6);
          border-color: white;
        }
      }

      .range-cases-wrapper {
        z-index: 2;
        border-top: 1px solid $border-color;

        .case {
          padding: $default-padding-extra-small;
          margin: $default-padding-extra-small;

          line-height: mat.font-size($apex-typography, caption);
        }

        .more {
          padding: $default-padding-extra-small;
          margin: $default-padding-extra-small;

          color: $apex-main;
        }
      }

      .cases-wrapper {
        position: absolute;
        left: $sideBar;
        width: calc(100% - #{$sideBar} - 4px);

        &.full-width {
          left: 0;
          width: calc(100% - 4px);
        }

        .case {
          position: absolute;
          z-index: 2;

          &.rc {
            background-color: $apex-gray;
          }

          > div {
            padding: $default-padding-extra-small;
          }
        }
      }
    }

    .weekslot {
      border-top: 1px solid $border-color;
      background-color: $AK-700;
      color: $light-foreground;
      width: $default-padding * 2;

      padding-top: $default-padding-extra-small;
    }

    .dateslot {
      border-top: 1px solid $border-color;
      border-right: 1px solid $border-color;

      h3 {
        padding: $default-padding-extra-small;
      }
    }

    .today {
      background-color: $AK-700;
      color: $light-foreground;
      font-weight: 700;

      .other-month {
        color: inherit;
      }
    }

    .other-month {
      color: $color-disabled;
    }

    .date-wrapper {
      @include phone() {
        .date {
          padding: 0;
        }

        .month-name {
          display: none;
        }
      }
    }

    .mat-caption {
      color: $light-foreground;
    }

    .row-case-wrapper {
      $left: $default-padding * 2;
      $top: 32px;

      position: absolute;
      left: $left;
      top: $top;

      width: calc(100% - #{$left});
      height: calc(100% - #{$top});

      overflow: auto;

      .case-wrapper {
        position: absolute;
      }

      .case {
        box-sizing: border-box;
        padding: $default-padding-extra-small;

        .dot {
          border-radius: 50%;
          background-color: $apex-main-variant;
          min-height: $default-padding;
          min-width: $default-padding;
        }

        &.mat-elevation-z2 {
          background-color: $apex-main-variant;
          color: $primary-foreground;

          &.rc {
            background-color: $apex-gray;
            color: $others-foreground;
          }
        }

        &.rc {
          .dot {
            background-color: $apex-gray;
          }
        }

        .case-data {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}

apex-case-filter {
  .mat-mdc-form-field-infix {
    width: auto;
  }
}

apex-case-filter-shortcuts {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @include phone() {
    display: inline-block;
  }

  button.mat-mdc-button {
    &.active {
      color: $apex-main;
    }
  }
}

.cases-filter-shortcuts {
  .mat-mdc-button:not(.mat-primary) {
    color: rgba($AK-900, 0.87);

    &:hover {
      color: rgba($AK-900, 0.87);
    }

    &.mat-primary {
      color: $light-foreground;
    }
  }
}

body {
  &.offline {
    apex-deviation-dialog {
      .new-message {
        display: none;
      }
    }
  }
}

body inspection-root {
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  background-color: $AK-100;

  table.apex {
    tr {
      background-color: $AK-50;
    }
  }

  button.mat-mdc-fab {
    margin: 0;
    padding: 0;
    bottom: 30px;
  }

  apex-page-info {
    mat-spinner {
      margin: auto;

      circle {
        stroke: $AK-50 !important;
      }
    }

    mat-slide-toggle {
      .mat-mdc-slide-toggle-bar {
        background-color: rgba($AT-900, 0.54) !important;
      }

      &:not(.mat-mdc-checked) {
        .mat-mdc-slide-toggle-bar {
          background-color: $AK-200 !important;
        }

        .mat-mdc-slide-toggle-thumb {
          background-color: $AK-50 !important;
        }
      }

      .mat-mdc-slide-toggle-thumb {
        background-color: $AT-900 !important;
      }
    }
  }

  mat-card {
    background-color: $AK-50;
  }

  router-outlet + * {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  main {
    flex: 1;
    overflow: auto;
    display: flex;
    flex-direction: column;
  }

  footer {
    height: 56px;
    background-color: $AK-700;
    opacity: 0.95;
    color: $primary-foreground;
  }

  apex-file-usage-viewer {
    .marking.highlighted {
      &.offline {
        .marking-text {
          display: none;
        }
      }

      .marking-mark {
        &:before {
          content: 'fiber_new';
          font-size: 18px;
          font-family: 'Material Icons';
        }
      }
    }
  }

  apex-case-list {
    .highlighted {
      &.offline {
        .left {
          .case-id {
            p {
              display: none;
            }
          }
        }
      }

      .left {
        &.other {
          background-color: initial;
        }

        .case-id {
          &:after {
            content: 'fiber_new';
            font-size: 24px;
            margin: $default-padding-small;
            font-family: 'Material Icons';
          }
        }
      }
    }
  }

  apex-inspection-info-page {
    main {
      padding-bottom: $header-height;

      section {
        form {
          background-color: $background-white;
          margin: $default-padding;
          box-sizing: border-box;
        }

        &.saved-inspections {
          mat-divider {
            margin: $default-padding-small 0;
          }
        }
      }

      footer {
        position: absolute;
        bottom: 0;
        width: 100%;
        z-index: 1;
      }
    }
  }

  apex-inspection-checklist-page {
    main {
      padding-bottom: $header-height;
    }

    footer {
      position: absolute;
      bottom: 0;
      width: 100%;
      z-index: 1;
    }
  }

  apex-inspection-case-page {
    apex-page-info {
      mat-checkbox {
        .mat-mdc-checkbox-frame {
          border-color: $AK-50;
        }

        &.mat-mdc-checkbox-checked.mat-mdc-accent {
          .mat-mdc-checkbox-background {
            background-color: rgba(0, 0, 0, 0);
          }
        }
      }
    }

    main {
      apex-cases {
        .mat-mdc-fab {
          right: calc(50% - 28px) !important;
        }

        .case-and-case-list {
          apex-case-view {
            .oa {
              padding-bottom: $header-height;
            }

            .more {
              display: none;
            }

            .highlighted {
              .contractor {
                .icon {
                  margin-right: 0;
                }
              }

              .offline {
                .mat-mdc-tab-label:first-child {
                  .mat-mdc-tab-label-content {
                    display: none;
                  }
                }
              }

              .new-message {
                display: none;
              }

              .mat-mdc-tab-label:first-child {
                &:before {
                  content: 'fiber_new';
                  font-size: 24px;
                  margin: $default-padding-small;
                  font-family: 'Material Icons';
                }
              }
            }
          }

          .case-list-wrap {
            padding-bottom: $header-height + $default-padding;

            apex-case-filter {
              display: none;
            }
          }
        }

        .case-marking-wrap {
          padding-bottom: $header-height + $default-padding;
        }

        .case-marking-wrap {
          padding-bottom: $header-height + $default-padding;
        }
      }

      footer {
        clip-path: footerpolygon(50, 36);
        position: absolute;
        bottom: 0;
        width: 100%;
        z-index: 1;
      }
    }
  }

  apex-inspection-misc-info-page {
    main {
      flex: 1;
      padding-bottom: $header-height;

      section {
        width: 600px;
        max-width: 100%;
        min-width: 300px;
        margin: auto;

        > div {
          margin: $default-padding;
          background-color: $background-white;
        }
      }

      footer {
        position: absolute;
        bottom: 0;
        width: 100%;
        z-index: 1;
      }
    }
  }

  @mixin involved-table-phone {
    mat-slide-toggle {
      margin-top: $default-padding;
    }
    tbody {
      tr {
        flex-direction: row !important;
        flex-wrap: wrap;

        td:nth-child(1) {
          max-width: 24px;
          flex: 0;
          padding: 0;
        }

        td:nth-child(2) {
          margin-top: $default-padding;
        }

        td:nth-child(3) {
          flex: 100% !important;
        }

        td:nth-child(5) {
          align-items: center;
        }

        td:nth-child(6) {
          flex: none !important;
          align-items: center;
        }

        td:nth-child(7) {
          flex: 100% !important;
          align-items: flex-end;
          padding-right: $default-padding;
        }
      }
    }
    .others-seperator {
      display: none;
    }
  }

  @mixin involved-table {
    @include phone() {
      @include involved-table-phone();
    }
    @include tablet() {
      @include involved-table-phone();
    }
  }

  apex-inspection-involved-page {
    table {
      margin-bottom: 96px !important;

      .casemanager {
        mat-icon {
          color: $casemanager-color;
        }
      }

      .contractor {
        mat-icon {
          color: $contractor-color;
        }
      }

      .client {
        mat-icon {
          color: $client-color;
        }
      }

      .current-user {
        mat-icon {
          color: $apex-gray;
        }
      }

      @include involved-table();
    }

    .mat-mdc-raised-button {
      mat-icon {
        padding-right: $default-padding-small;
      }
    }

    .mat-mdc-fab {
      right: calc(50% - 28px) !important;
    }

    footer {
      clip-path: footerpolygon(50, 36);
      position: absolute;
      bottom: 0;
      width: 100%;
      z-index: 1;
    }
  }

  apex-inspection-view-page {
    table {
      .casemanager {
        mat-icon {
          color: $casemanager-color;
        }
      }

      .contractor {
        mat-icon {
          color: $contractor-color;
        }
      }

      .client {
        mat-icon {
          color: $client-color;
        }
      }

      .current-user {
        mat-icon {
          color: $apex-gray;
        }
      }

      @include involved-table();
    }

    .mat-mdc-fab {
      right: calc(50% - 28px) !important;
    }

    apex-case-list {
      pointer-events: none;

      .filters {
        display: none;
      }

      .case-list-header {
        display: none;
      }

      .case {
        mat-checkbox {
          visibility: hidden;
        }
      }

      .case-list-botttom {
        display: none !important;
      }

      .title-icons {
        display: none;
      }
    }

    apex-checklist-group-checklist {
      pointer-events: none;

      textarea.mat-mdc-input-element {
        resize: none;
      }
    }

    apex-file-usage-viewer {
      main {
        height: 500px;
      }
    }

    .footer-padding {
      padding-bottom: $default-padding * 5;
    }

    footer {
      position: absolute;
      bottom: 0;
      width: 100%;
      z-index: 1;
    }
  }
}

apex-inspection-add-other-user-dialog {
  mat-form-field {
    width: 100%;
  }

  @mixin apex-inspection-add-other-user-dialog-table-small {
    mat-slide-toggle {
      margin-top: $default-padding;
    }
    tbody {
      tr {
        flex-direction: row !important;
        flex-wrap: wrap;
        max-width: initial !important;
        min-width: initial !important;
        width: initial;
        margin: 2px;

        td {
          margin: $default-padding;
        }

        td:nth-child(1) {
          flex: 100% !important;

          mat-form-field {
            width: 100%;
          }
        }

        td:nth-child(2) {
          flex: 100% !important;

          mat-form-field {
            width: 100%;
          }
        }

        td:nth-child(3) {
          flex: none;
          align-items: center !important;
        }

        td:nth-child(4) {
          flex: 1;
          align-items: center !important;
        }

        td:nth-child(5) {
          flex: none;
          align-items: center !important;
          padding-right: 0 !important;
        }
      }
    }
  }
  @include phone() {
    @include apex-inspection-add-other-user-dialog-table-small();
  }
  @include tablet() {
    @include apex-inspection-add-other-user-dialog-table-small();
  }
}

.cdk-overlay-container {
  .highlighted {
    div:first-child {
      p {
        margin: 0;
      }
    }

    &.offline {
      .case-id {
        p {
          display: none;
        }
      }
    }

    .case-id {
      &:before {
        content: 'fiber_new';
        font-size: 24px;
        margin: $default-padding-small;
        font-family: 'Material Icons';
      }
    }
  }

  .mat-mdc-dialog-content {
    &.inspection {
      .highlighted {
        .contractor {
          .icon {
            margin-right: 0;
          }
        }

        .new-message {
          display: none;
        }

        .mat-mdc-tab-label:first-child {
          .mat-mdc-tab-label-content {
            display: none;
          }

          &:before {
            content: 'fiber_new';
            font-size: 24px;
            margin: $default-padding-small;
            font-family: 'Material Icons';
          }
        }
      }
    }
  }
}

apex-supplier-data {
  > div {
    > form:hover,
    > form:focus-within {
      @include mat.elevation(6);
    }
  }
}

apex-date-time {
  .date-time-wrapper {
    flex: inherit;
    flex-direction: inherit;

    .mat-mdc-form-field {
      flex: inherit;
    }

    .mat-mdc-form-field-infix {
      @include phone() {
        width: initial;
      }
    }

    mat-form-field:first-of-type {
      @extend .amr;
    }

    &.reverse-view-order {
      mat-form-field:first-of-type {
        order: 1;
        @extend .aml;
        margin-right: 0;
      }

      .mat-mdc-form-field:last-of-type {
        order: 0;
      }
    }
  }
}

apex-project-fields {
  * {
    box-sizing: border-box;
  }
}

apex-reporting-cases {
  > div {
    background: $AK-100;
  }

  table {
    tr {
      td,
      th {
        &:nth-child(3),
        &:nth-child(4) {
          &,
          & .mat-sort-header-container {
            text-align: center;
            justify-content: center;
          }
        }

        &:nth-child(5) {
          &,
          & .mat-sort-header-container {
            text-align: right;
            justify-content: flex-end;
          }
        }
      }
    }
  }
}

apex-reporting-new-multi-residential {
  .apex-table {
    .mat-mdc-form-field-infix {
      width: unset;
    }
  }
}

apex-root {
  height: calc(100% - 56px);

  background-color: $AK-100;
}

apex-takeover-page {
  display: flex;
  flex-direction: column;

  section {
    & > header {
      position: sticky;
      top: $header-height;
      z-index: 2;
      height: $header-height;
      @include mat.typography-level($apex-typography, 'headline-6');
      margin: 0;
      background: $apex-main;
      color: $primary-foreground;

      &.invalid {
        background: $apex-error;
      }

      mat-checkbox.mat-checkbox {
        .mat-mdc-checkbox-inner-container {
          .mat-mdc-checkbox-frame {
            border-color: white;
          }

          .mat-mdc-checkbox-background {
            background-color: transparent;
          }
        }
      }
    }

    &.case {
      height: 80vh;

      button.mat-mdc-fab {
        display: none;
      }
    }

    &.attachment {
      @include tablet() {
        min-width: 350px;
      }

      .wrap {
        height: 100%;
        display: flex;
        flex-direction: column;

        .content {
          flex: 1;
        }
      }
    }

    .notes {
      @include tablet() {
        min-width: 350px;
      }
    }
  }

  apex-file-usage {
    .toolbar {
      position: sticky;
      top: 0;
    }
  }

  & > main {
    padding-bottom: calc(#{$header-height} + 16px);
  }
}

apex-object-takeover-meter,
apex-project-takeover-meter {
  header {
    background: $apex-main-variant;
    color: $primary-foreground;

    &.invalid {
      background: $apex-error;
    }

    .mat-mdc-progress-spinner circle,
    .mat-mdc-progress-spinner circle {
      stroke: $AK-50;
    }
  }
}

apex-takeover-power {
  .supplier {
    flex: 16.6% 0;
    max-width: 16.6%;
    min-height: 200px;
    box-sizing: border-box;

    @include tablet() {
      flex: 33% 0;
      max-width: 33%;
    }

    @include phone() {
      flex: 50% 0;
      max-width: 50%;
    }
  }

  .supplier-item {
    outline: $default-padding-extra-small solid transparent;
    transition: outline-color 500ms ease;

    &.selected {
      outline-color: $color-selected;

      .icon {
        color: $color-selected !important;
        position: absolute;
        top: $default-padding-small;
        left: $default-padding-small;
      }
    }
  }

  .logo {
    height: 64px;
    box-sizing: border-box;

    img {
      object-fit: contain;
      object-position: $default-padding-small;
      box-sizing: border-box;
      width: 100%;
      height: 100%;
    }
  }
}

apex-takeover-consent {
  header {
    height: $header-height;
    background: $apex-main;
    color: $primary-foreground;

    &.invalid {
      background: $apex-error;
    }
  }
}

apex-object-list {
  :focus {
    outline: none;
  }

  .highlighted {
    background-color: $AT-50;
  }

  .border-bottom,
  .bb {
    border-bottom: 1px solid $border-color;
  }

  .border-left,
  .bl {
    border-left: 1px solid $border-color;
  }

  .mat-mdc-list-base .mat-mdc-list-item .mat-mdc-list-text {
    .mat-h2 {
      font-size: mat.font-size($apex-typography, headline-6);
    }
  }

  .wrapit.apex-drag-over {
    .new {
      background-color: $AT-100;
    }
  }
}

apex-involved-view {
  display: flex;
}

apex-involved-form {
  display: flex;
  flex-direction: column;
}

apex-involved-form-dialog apex-involved-form,
apex-project-takeover-involved-dialog form {
  width: 400px;
  max-width: 100%;
}

apex-involved-signatures {
  mat-radio-button {
    img {
      width: 75px;
    }
  }

  .signer {
    width: 33%;
    @include tablet() {
      width: 50%;
    }
    @include phone() {
      width: 100%;
    }
  }
}

apex-involved-signature {
  .bankid,
  .bankid-mobile {
    .mat-mdc-button-disabled {
      img {
        filter: contrast(0.1);
      }
    }

    img {
      width: 60px;
    }
  }

  .bankid-mobile {
    button {
      display: flex;

      .mat-mdc-button-wrapper {
        display: flex;
        flex: 1;
      }
    }
  }
}

apex-fab-speed-dialog {
  .fab-container {
    .mat-mdc-fab {
      position: static;

      &.middle {
        right: calc(50% - 28px) !important;
      }
    }

    position: fixed;
    bottom: 1rem;
    right: 1rem;

    @include phone {
      bottom: 8px;
      right: 8px;
    }
    z-index: 100;
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-end;

    > div {
      display: flex;
      flex-direction: column-reverse;
      align-items: flex-end;
      margin-bottom: 5px;

      .button-container {
        margin-bottom: 17px;
      }
    }
  }

  .fab-toggler {
    float: right;
    z-index: 100;
  }
}

apex-entry-cards,
apex-entry-groups {
  * {
    box-sizing: border-box;
  }

  .header {
    position: relative;

    [class*='-search'] {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;

      background-color: $background-white;
      color: $dark-foreground;

      will-change: opacity;
      transition: opacity 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
      z-index: 2;

      &.no-touch {
        pointer-events: none;
      }
    }

    .loading {
      position: absolute;
      left: 0;
      bottom: -1px;
      width: 100%;
    }
  }
}

apex-entry-cards {
  .entry-card-wrapper {
    flex: 0 0 24%;
    flex-wrap: inherit;

    @include tablet() {
      flex: 0 0 40%;
    }

    &:last-of-type {
      padding-right: $default-padding;
    }

    .entry-card {
      border-radius: 8px;
    }
  }
}

apex-entry-groups {
  mat-nav-list.mat-mdc-list-base {
    padding-top: 0;
  }

  .group-card {
    flex: 0 0 15%;

    @include tablet() {
      flex: 0 0 33%;
    }
  }
}

apex-bookmark-button,
angular-bookmark-button {
  display: flex;
  align-items: center;
  overflow: hidden;
}

apex-bookmark-manage {
  .invisible {
    display: none;
  }

  ul,
  li {
    margin-top: 0;
    margin-bottom: 0;
    list-style-type: none;
  }

  .selected {
    color: $AT-700;
  }
}

apex-inspection-list {
  &.no-padding {
    .mat-expansion-panel {
      .mat-expansion-panel-content {
        .mat-expansion-panel-body {
          padding: 0;
        }
      }
    }
  }
}

apex-map > div {
  min-height: 256px;
  position: relative;
  overflow: hidden;
}

apex-decline-dialog {
  .mat-mdc-dialog-title.bg-color-warn {
    background-color: $AR-500;
  }

  form div.mat-mdc-dialog-content {
    padding: 0 $default-padding;
  }
}

apex-text-preview {
  flex: 1;
  display: flex;
  flex-direction: column;

  .grid-view {
    border: 1px solid #beeae9;
    width: $default-thumbnail-size;

    .thumbnail {
      height: $default-thumbnail-size;
      overflow: hidden;
    }
  }

  .selected {
    .grid-view {
      border-color: #00919d;
    }
  }
}

apex-text-view {
  flex: 1;
  display: flex;
  flex-direction: column;
}

apex-text-edit {
  flex: 1;
  display: flex;
  flex-direction: column;
}

apex-text-list {
  flex: 1;
  display: flex;
  flex-direction: column;

  .mat-mdc-list-text {
    height: 100%;
  }

  .mat-drawer {
    touch-action: pan-y;

    .mat-drawer-inner-container {
      touch-action: pan-y;
    }
  }

  .mat-drawer-content {
    touch-action: pan-y;
    display: flex;
  }
}

apex-text-page {
  flex: 1;
  display: flex;
  flex-direction: column;

  .mat-drawer-inner-container {
    touch-action: pan-y;
  }
}

apex-text-usage {
  display: flex;
  flex-direction: column;
  @include mat.elevation(2);
  background-color: $background-white;

  header {
    height: $header-height;
    padding-left: $default-padding;
    padding-right: $default-padding-small;
  }

  main {
    height: $default-thumbnail-size;
  }
}

apex-usage-explorer {
  display: flex;
  flex-direction: column;
  width: 1280px;
  height: 600px;
  max-width: 100%;
  max-height: 100%;

  .mat-mdc-dialog-content.mat-mdc-dialog-content {
    padding: 0;
    display: flex;

    .mat-mdc-tab-body-wrapper {
      flex: 1;

      .mat-mdc-tab-body-content {
        display: flex;
        flex-direction: column;
      }
    }
  }
}

apex-folder-page {
  display: flex;
  flex-direction: column;
  flex: 1;
  max-height: 100%;
}

apex-folder-dialog {
  .mat-mdc-dialog-content.mat-mdc-dialog-content {
    min-height: 65vh;
    padding: 0;

    .mat-mdc-tab-body-wrapper {
      flex: 1;

      .mat-mdc-tab-body-content {
        display: flex;
        flex-direction: column;
        overflow: hidden;
      }
    }

    .upload {
      &.dragover {
        background: mat.get-color-from-palette($primary, 50);
      }

      mat-icon {
        width: 140px;
        height: 140px;
        font-size: 140px;
      }
    }

    .list {
      .selected {
        background-color: $AT-50;
      }

      .file-preview {
        height: 24px;
        width: 24px;

        apex-file-preview {
          height: 24px;
          width: 24px;
        }
      }

      table {
        width: 100%;
        table-layout: fixed;

        th,
        td {
          overflow: hidden;
          width: auto;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .mat-column-selected {
          width: 52px;
        }

        .mat-column-updatedAt {
          width: 120px;
        }

        .mat-column-size {
          width: 100px;
        }

        .mat-column-more {
          width: 52px;
        }
      }
    }
  }
}

apex-folder {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;

  .apex-drag-over {
    background: mat.get-color-from-palette($primary, 50);
  }

  apex-folder-tree {
    .apex-drag-over {
      box-shadow: 0 0 0 2px $apex-main;
    }
  }

  .apex-drag-placeholder {
    opacity: 0.5;
  }

  .header {
    height: $header-height;

    @include phone() {
      height: $header-height * 2;
    }

    .breadcrumb {
      .apex-drag-over {
        box-shadow: 0 0 0 2px $apex-main;
      }

      .mat-mdc-button {
        text-transform: none !important; /*For Lower case use lowercase*/
      }
    }
  }

  .upload {
    &.dragover {
      background: mat.get-color-from-palette($primary, 50);
    }

    mat-icon {
      width: 140px;
      height: 140px;
      font-size: 140px;
    }
  }

  .grid {
    .apex-drag-over {
      box-shadow: 0 0 0 2px $apex-main;
    }

    .items {
      display: grid;
      min-height: 0;
      min-width: 0;
      @include desktop() {
        grid-template-columns: repeat(6, 1fr);
      }
      @include desktop-large() {
        grid-template-columns: repeat(auto-fill, 192px);
      }
      @include tablet() {
        grid-template-columns: repeat(2, 1fr);
      }
      @include tablet-landscape() {
        grid-template-columns: repeat(4, 1fr);
      }
      @include phone() {
        grid-template-columns: repeat(2, 1fr);
      }

      .item {
        overflow: hidden;
        min-width: 0;
      }
    }

    .files {
      grid-auto-rows: 1fr;

      .file {
        flex: 1;
        position: relative;
        touch-action: pan-y !important;

        & > div:first-child {
          position: absolute;
          left: 0;
          top: 0;
        }

        border: solid 1px transparent;

        &.selected {
          border: solid 1px $apex-main;
        }
      }
    }

    .files::before {
      content: '';
      width: 0;
      padding-bottom: 100%;
      grid-row: 1 / 1;
      grid-column: 1 / 1;
    }

    .files > *:first-child {
      grid-row: 1 / 1;
      grid-column: 1 / 1;
    }

    .folders {
      .folder {
        border: solid 1px $border-color;
        background-color: $AK-50;
        touch-action: pan-y !important;

        &.selected {
          border: solid 1px $apex-main;
        }
      }
    }
  }

  .list {
    .apex-drag-over {
      border-collapse: collapse;

      td {
        border-top: solid 2px $apex-main;
        border-bottom: solid 2px $apex-main;

        &:first-child {
          border-left: solid 2px $apex-main;
        }

        &:last-child {
          border-right: solid 2px $apex-main;
        }
      }
    }

    .selected {
      background-color: $AT-50;
    }

    .folder {
      flex: 1;
    }

    .files {
      thead {
        display: none;
      }

      .file-preview {
        height: 24px;
        width: 24px;

        apex-file-preview {
          height: 24px;
          width: 24px;
        }
      }
    }

    table {
      width: 100%;
      table-layout: fixed;

      th,
      td {
        overflow: hidden;
        width: auto;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .mat-column-selected {
        width: 52px;
      }

      .mat-column-updatedAt {
        width: 120px;
      }

      .mat-column-size {
        width: 100px;
      }

      .mat-column-more {
        width: 52px;
      }
    }
  }
}

apex-folder-tree-dialog {
  .mat-mdc-dialog-content.mat-mdc-dialog-content {
    min-height: 65vh;
    padding: 0;
  }
}

apex-folder-tree {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
  max-height: 100%;

  .new.steps {
    height: 44px;
    max-height: 44px;
    min-height: 44px;
    background-image: none;
  }

  .selected {
    .content {
      background-color: $AT-50;
    }
  }
}

apex-upload {
  z-index: 9999;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: fixed;
  left: $default-padding;
  bottom: $default-padding;
  width: 400px;
  max-width: 70vw;
  max-height: 200px;
  overflow: hidden;

  header {
    min-height: $header-height;
    padding-left: $default-padding;
    padding-right: $default-padding-small;

    background: $apex-main;
    color: $primary-foreground;

    &.ongoing {
      background: $apex-gray;
    }
  }

  main {
    overflow: auto;
    background: $AK-50;

    .row {
      padding-left: $default-padding;
      padding-right: $default-padding-small;

      .action {
        display: none;
      }
    }

    .row:hover {
      .status {
        display: none;
      }

      .action {
        display: block;
      }
    }
  }
}

apex-drag {
  .item {
    position: fixed;
    overflow: visible;
    z-index: 9999;
    pointer-events: none;
    transition:
      width 250ms ease,
      height 250ms ease;

    &.drop {
      transition:
        left 250ms ease,
        top 250ms ease,
        width 250ms ease,
        height 250ms ease;
    }

    &.drag-item {
      z-index: 99999;
    }
  }

  .drag-selector {
    pointer-events: none;
    position: absolute;
    border: solid 1px $apex-main;
    background-color: rgba($AT-50, 0.2);
    z-index: 9999;
  }
}

apex-pdf-viewer {
  height: 100%;
  width: 100%;
}

apex-file-viewer-dialog {
  mat-dialog-content.mat-dialog-content.mat-dialog-content {
    min-height: 65vh;
    height: 65vh;
    padding: 0;
  }

  apex-pdf-viewer {
    @include flex-column();

    iframe {
      flex: 1;
    }
  }

  apex-file-viewer-preview {
    > div {
      padding: $default-padding-small;
    }

    .file-wrapper {
      width: $file-viewer-preview-width;
      min-width: $file-viewer-preview-width;

      height: $file-viewer-preview-height;
      min-height: $file-viewer-preview-height;
    }
  }
}

apex-file-viewer {
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;

  .bottom-left {
    position: absolute;
    bottom: $default-padding-small;
    left: $default-padding-small;
  }

  .video {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;

    display: flex;
    flex: 1;

    position: relative;

    video {
      display: flex;
      flex: 1;
      width: 100%;
      height: 100%;

      position: absolute;
      top: 0px;
      left: 0px;
    }
  }
}

apex-image-viewer {
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  background-color: $AK-100;

  mat-spinner,
  .image-tools,
  .file-tools {
    position: absolute;
  }

  mat-spinner {
    margin: auto;
  }

  .image-tools {
    top: 0;
    right: 0;

    background-color: rgba($AK-100, 0.7);
  }

  .file-tools {
    bottom: 0;
    right: 0;

    background-color: rgba($AK-100, 0.7);
  }

  .marking {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;

    .marking-mark,
    .marking-text {
      position: absolute;
      box-sizing: border-box;
    }

    .marking-mark {
      width: 100%;
      height: 100%;
      border-radius: 100%;

      background-color: $AT-700;
      border: 2px solid $AK-900;
    }

    .marking-text {
      padding: 1px $default-padding-small 1px $default-padding;

      left: 50%;

      font-size: mat.font-size($apex-typography, caption);
      line-height: 1em;

      background-color: $AT-200;
      color: $dark-foreground;
    }

    &:hover {
      .marking-mark {
        background-color: $AT-500;
        @include mat.elevation(6);
      }
    }

    &.case {
      &.other {
        .marking-text {
          @include others();
        }
      }

      &.completed {
        .marking-mark {
          background-color: $AG-300;
        }
      }

      &.accepted {
        .marking-mark {
          background-color: $contractor-color;
        }
      }

      &.declined {
        .marking-mark {
          background-color: mat.get-color-from-palette($warn);
        }
      }

      &.has-color {
        .marking-mark {
          background: radial-gradient(
            closest-side,
            var(--field-color) 0%,
            var(--field-color) 60%,
            $AT-700 60%,
            $AT-700 100%
          );
        }

        &.completed {
          .marking-mark {
            background: radial-gradient(
              closest-side,
              var(--field-color) 0%,
              var(--field-color) 60%,
              $AG-300 60%,
              $AG-300 100%
            );
          }
        }

        &.accepted {
          .marking-mark {
            background: radial-gradient(
              closest-side,
              var(--field-color) 0%,
              var(--field-color) 60%,
              $contractor-color 60%,
              $contractor-color 100%
            );
          }
        }

        &.declined {
          .marking-mark {
            background: radial-gradient(
              closest-side,
              var(--field-color) 0%,
              var(--field-color) 60%,
              mat.get-color-from-palette($warn) 60%,
              mat.get-color-from-palette($warn) 100%
            );
          }
        }
      }
    }
  }

  .box:hover {
    cursor: pointer;
  }

  .dragging:active {
    cursor: grab;
  }
}

apex-case-checklist-items {
  mat-spinner {
    &.mat-mdc-progress-spinner {
      circle {
        stroke: $primary-foreground;
      }
    }
  }

  mat-icon.mat-icon.complete-icon.mat-primary {
    color: $primary-foreground;
    width: 24px;
    height: 24px;
    font-size: 24px;
    line-height: 24px;
    margin: $default-padding-small;
  }

  .checklist-completed-spinner {
    .mat-mdc-progress-spinner {
      &.spinner-background {
        circle {
          stroke: $AT-900;
        }
      }

      &.spinner-foreground {
        circle {
          stroke: $primary-foreground;
        }
      }
    }
  }
}

apex-object-recipient-dialog {
  .mat-mdc-dialog-content {
    .mat-mdc-form-field-hint-wrapper {
      justify-content: flex-end;

      .mat-form-field-hint-spacer {
        flex: 0;
      }
    }

    .apex-user {
      .mat-mdc-form-field-hint-wrapper {
        mat-hint.mat-mdc-form-field-hint {
          color: $apex-main;
        }
      }
    }
  }
}

apex-object-recipient {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;

  @extend .mat-elevation-z2;

  header {
    padding-left: $default-padding;
    height: $header-height;
    background: $apex-main;
    color: $primary-foreground;
  }
}

apex-fields {
  display: flex;
  flex-direction: column;
  flex: 1;

  .field {
    a {
      color: initial;
    }
  }
}

apex-checklist-group-template {
  display: flex;
  flex-direction: column;
  flex: 1;
}

apex-checklist-group-template-tree {
  display: flex;
  flex-direction: column;
  flex: 1;

  .apex-drag-over {
    border: solid 2px $apex-main;
  }

  header.tree {
    margin: $default-padding-extra-small;
    margin-right: $default-padding-small;
  }

  .mat-expansion-panel {
    .mat-expansion-panel-header {
      padding-left: 12px;
    }

    .mat-expansion-panel-content {
      .mat-expansion-panel-body {
        padding: 0;
      }
    }
  }
}

apex-checklist-group-template-form {
  display: flex;
  flex-direction: column;
  flex: 1;
}

apex-checklist-group-template-checklist {
  display: flex;
  flex-direction: column;
  flex: 1;
}

apex-checklist-group-template-form-page {
  padding-bottom: $header-height * 2;
}

apex-checklist-group-template-picker-dialog {
  mat-dialog-content.mat-dialog-content.mat-dialog-content {
    padding: 0;
  }
}

apex-checklist-group-template-picker {
  mat-selection-list.mat-selection-list {
    padding: 0;
  }
}

apex-checklist-group-template-tree {
  display: flex;
  flex: 1;
  flex-direction: column;
}

apex-checklist-group-list-page {
  mat-icon.icon {
    display: none;
  }
}

apex-checklist-group-tree {
  display: flex;
  flex: 1;
  flex-direction: column;

  header {
    margin: $default-padding-small;
  }

  .mat-expansion-panel {
    .mat-expansion-panel-header {
      padding-left: $default-padding-small;
    }

    .mat-expansion-panel-content {
      .mat-expansion-panel-body {
        padding: 0;
      }
    }
  }
}

apex-checklist-group-checklist {
  display: flex;
  flex: 1;
  flex-direction: column;
}

apex-checklist-groups,
angular-checklist-groups {
  .mat-expansion-panel {
    .mat-expansion-panel-content {
      .mat-expansion-panel-header {
        padding-left: 0;
      }

      .mat-expansion-panel-body {
        padding: 0;

        main {
          a {
            mat-icon,
            .mat-h3,
            .mat-body-2,
            .mat-caption {
              color: $AK-700;
            }
          }

          .mat-nav-list {
            overflow: unset;
          }

          apex-checklist-group-tree {
            .mat-expansion-panel-header {
              padding-left: $default-padding-small;
            }
          }

          .mat-accordion {
            .mat-expansion-panel:last-child {
              .mat-expansion-panel-body {
                padding: 0;
              }
            }
          }

          .mat-expansion-panel-body {
            padding-left: 0;
            padding-right: 0;
            padding-top: 0;
          }

          apex-checklist-item-list {
            apex-checklist-item {
              form {
                padding-right: $default-padding-small;
              }
            }
          }
        }
      }
    }
  }
}

apex-progress {
  mat-icon.mat-icon.mat-primary.complete-icon.complete-icon {
    color: $apex-main;
  }

  .checklist-completed-spinner {
    position: relative;
    $size: var(--size, 40px);

    .percent {
      width: $size;
      height: $size;
      text-align: center;
      line-height: $size;

      position: absolute;
      top: calc(50% - (#{$size} / 2));
      left: calc(50% - (#{$size} / 2));
    }

    .mat-mdc-progress-spinner {
      &.spinner-background {
        position: absolute;
        top: 0;
        left: 0;

        circle {
          stroke: $AK-200;
        }
      }
    }
  }

  &.bg-color-main {
    mat-icon.mat-icon.mat-primary.complete-icon.complete-icon {
      color: $primary-foreground;
    }

    .checklist-completed-spinner {
      .mat-mdc-progress-spinner {
        circle {
          stroke: $primary-foreground;
        }

        &.spinner-background {
          position: absolute;
          top: 0;
          left: 0;

          circle {
            stroke: $AK-900;
            opacity: 0.5;
          }
        }
      }
    }
  }

  mat-icon.complete-icon {
    $icon-size: 45px;
    font-size: 46px;
    line-height: $icon-size;
    height: $icon-size;
    width: 43px;
  }

  .small {
    mat-icon.complete-icon {
      $icon-size: 24px;
      font-size: 24px;
      line-height: $icon-size;
      height: $icon-size;
      width: 24px;
    }
  }
}

apex-checklist-template-section-view {
  display: flex;

  .checklist-template-section {
    background-color: $apex-main;
    color: $primary-foreground;
  }
}

apex-checklist-section {
  @include print() {
    display: block;
  }

  .df.c {
    @include print() {
      display: block;
    }
  }

  & > div.mat-elevation-z2 {
    & > .mat-divider {
      @include print() {
        display: none;
      }
    }
  }

  .checklist-section {
    background-color: $apex-main;
    color: $primary-foreground;
  }
}

apex-checklist-section-view,
apex-checklist-section-preview {
  .item {
    .complete-item-button {
      $button-size: 48px;
      height: $button-size;
      width: $button-size;

      // @todo Herre må fikses.
      // Rett og slett lag en komponent for den type knapp i steden.
      // OG ALL ANDRE PLASSA DER DET TRIKSET HER E GJORT
      padding: 4px;

      mat-icon {
        $icon-size: 40px; // material icon button size
        font-size: $icon-size;
        line-height: $icon-size;
        height: $icon-size;
        width: $icon-size;
      }
    }
  }
}

apex-object-project-start {
  display: flex;
  flex-direction: column;
  flex: 1;
  @extend .list-container;

  button.mat-mdc-fab {
    display: none;
  }

  apex-fab-menu {
    button.mat-mdc-fab {
      display: inline-block;
    }
  }

  apex-cases {
    min-height: 400px;

    @include desktop() {
      min-height: 800px;
    }

    .mat-mdc-tab-body.mat-mdc-tab-body-active {
      @include desktop() {
        position: absolute;
      }
    }
  }

  .meters,
  .agreements {
    .search-container {
      display: none;
    }

    .content,
    mat-nav-list {
      margin: 0;
      box-shadow: unset;
    }
  }

  .grid-container {
    $box-max-height: 256px;

    @include desktop() {
      & > * {
        mat-nav-list.mat-nav-list {
          padding-top: 0;
        }

        .mat-expansion-panel-body {
          height: $box-max-height;
          max-height: $box-max-height;
          overflow-y: auto;
        }
      }
    }

    .inspections {
      mat-nav-list {
        @include phone() {
          max-height: unset;
        }

        @include tablet() {
          max-height: unset;
        }
      }
    }
  }

  .mat-expansion-panel {
    .mat-expansion-panel-content {
      .mat-expansion-panel-body {
        padding: 0;
      }
    }
  }

  .meters {
    .warnings {
      mat-icon {
        color: $AY-300;
      }
    }

    .errors {
      mat-icon {
        color: $AR-300;
      }
    }
  }
}

apex-object-project-info {
  @include flex-column();
}

apex-portal-agreement,
apex-portal-product {
  @include flex-column();
}

.apex-case-dialog-object-select-step {
  @for $i from 1 through 12 {
    &.select-steps-#{$i + 1} {
      padding-left: ($default-padding * $i);
    }
  }
}

apex-crm-user-list-page {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;

  mat-paginator {
    padding-right: $header-height;
  }
}

apex-crm-user-list {
  display: flex;
  flex-direction: column;
  flex: 1;

  // Todo check if this is used, otherwise not needed anymore
  //.mat-column-invite {
  //  max-width: 40px;
  //  text-align: center;
  //  justify-content: center;
  //}
}

apex-crm-user-view-page {
  display: flex;
  flex-direction: column;
  flex: 1;

  & > main {
    display: grid;
    grid-gap: $default-padding;
    grid-template-columns: 1fr;

    button.mat-mdc-button {
      .mat-mdc-button-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .action {
        display: none;
      }
    }

    button.mat-mdc-button:hover {
      .status {
        display: none;
      }

      .action {
        display: flex;
      }
    }

    .mat-expansion-panel .mat-expansion-panel-content .mat-expansion-panel-body {
      padding: 0;
    }
  }
}

apex-crm-user-form {
  display: flex;
  flex-direction: column;
  flex: 1;
}

apex-user-form {
  display: flex;
  flex-direction: column;
  flex: 1;
}

apex-user-invite-list {
  .mat-column-delete,
  .mat-column-status {
    max-width: 50px;
    display: flex;
    justify-content: center;
  }
}

apex-language-picker {
  flex: 1;
  display: flex;
  flex-direction: column;
}

apex-country-picker {
  flex: 1;
  display: flex;
  flex-direction: column;
}

apex-color-picker {
  display: inline-block;
  width: 24px;
  height: 24px;

  position: relative;

  .vh {
    position: absolute;
    top: 0;
    left: 0;
  }
}

apex-case-tenancy-info {
  .case-tenancy.mat-expansion-panel {
    .mat-expansion-panel-body {
      padding: 0;
    }
  }
}

apex-it-subscription {
  .relative {
    position: relative;
  }

  .loading {
    bottom: 0;
    left: 0;
    position: absolute;
    width: 100%;
  }

  @include flex-column();
  overflow: auto;
}

apex-avatar,
angular-avatar {
  $image-size: --size;
  border-radius: 50%;

  .avatar {
    box-sizing: border-box;

    width: var($image-size);
    height: var($image-size);
    border-radius: 50%;

    position: relative;

    &:after {
      content: '';
      position: absolute;
      z-index: 10;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      border-radius: 50%;
      box-shadow:
        0 0 0 1px var(--border-space-color),
        0 0 0 3px var(--border-fill-color);
    }

    img,
    .initials {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      border-radius: 50%;

      will-change: opacity;
    }

    img {
      transition: opacity 0.25s cubic-bezier(0.25, 0.8, 0.25, 1);
    }

    .initials {
      width: inherit;
      height: inherit;
      border-radius: inherit;

      background-color: var(--background-color, $apex-main);

      transition: opacity 0.125s cubic-bezier(0.25, 0.8, 0.25, 1);

      span {
        color: var(--color, $primary-foreground);
      }
    }
  }
}

apex-user-card-overlay {
  flex: 1;
  width: 304px;
  max-width: 100%;
}

apex-user-card {
  // need to set display flex for override display-grid-cards
  display: flex;
  flex: 1;

  .mat-mdc-card-header-text {
    overflow: hidden;
  }

  // override mat-card
  .user-card {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  // override mat-card-actions
  .user-card-button {
    display: flex;
    justify-content: space-between;
  }
}

apex-map-points {
  display: flex;
}

apex-it-projects,
apex-it-customers {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;

  apex-map-points {
    height: 50vh;
  }
}

apex-image-crop {
  display: flex;
  flex-direction: column;
  flex: 1;

  &.crop-circle {
    .cropper-crop-box,
    .cropper-view-box {
      border-radius: 50%;
    }
  }

  .cropper-view-box {
    outline: 1px solid $apex-main;
  }

  .cropper-line,
  .cropper-point {
    background-color: $apex-main;
  }

  .image-container {
    max-height: 100%;
    max-width: 100%;

    > img {
      max-height: 100%;
      max-width: 100%;
    }
  }
}

apex-image-crop-dialog {
  display: flex;
  flex-direction: column;
  flex: 1;

  width: 100%;
  height: 100%;
}

.crop {
  display: flex;
  flex-direction: column;
  flex: 1;

  width: 80vw;
  height: 65vh;

  .mat-mdc-dialog-container {
    display: flex;
    flex-direction: column;
    flex: 1;

    max-width: 100%;
    max-height: 100%;
  }
}

apex-contractor-addon-list-page {
  display: flex;
  flex-direction: column;

  .addon-list {
    .addon {
      width: 230px;
      height: $header-height + $default-padding + 230px;

      apex-file-usage-preview {
        height: 230px;
      }

      &.empty {
        box-sizing: border-box;
        width: 230px;
        height: $header-height + $default-padding + 230px;
        background-color: rgba($color: #000000, $alpha: 0);
        border-style: dashed;
        border-color: rgba($color: #000000, $alpha: 0.2);
        border-width: 5px;
        transition:
          background-color,
          color 0.4s;

        color: rgba($color: #000000, $alpha: 0.3);

        .mat-icon {
          transform: scale(2);
        }

        @include phone() {
          .mat-icon {
            transform: scale(1);
          }
        }

        &:hover {
          background-color: rgba($color: #000000, $alpha: 0.1);
          color: rgba($color: white, $alpha: 0.8);
        }
      }
    }
  }

  mat-expansion-panel.mat-expansion-panel {
    mat-expansion-panel-header.mat-expansion-panel-header {
      padding: 0;
      height: unset;

      &.mat-expanded {
        padding: 0;
        height: unset;
      }
    }
  }
}

apex-contractor-addon-category {
  .addon {
    position: relative;

    .status {
      position: absolute;
      left: $default-padding-small;
      top: $default-padding-small;
    }
  }
}

apex-contractor-addon-form-page {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;

  .addon-list {
    .addon {
      width: 230px;
      height: $header-height + $default-padding + 230px;

      apex-file-usage-preview {
        height: 230px;
      }
    }
  }

  apex-file-usage {
    .wrap {
      min-width: 256px;
    }
  }

  apex-addon-apartment-list {
    main > div:first-child {
      button.mat-mdc-button {
        display: none;
      }
    }
  }
}

apex-case-meter-info {
  .mat-accordion .mat-expansion-panel .mat-expansion-panel-content .mat-expansion-panel-body,
  .mat-expansion-panel .mat-expansion-panel-content .mat-expansion-panel-body {
    padding: 0;
  }
}

apex-addon-category-project-bulk-edit {
  .mat-vertical-stepper-content:has(apex-bulk-edit-addon-select) {
    overflow-x: auto;
  }

  .mat-tree {
    background: inherit;
  }

  .apartments-wrapper,
  .addons-wrapper {
    display: grid;
    gap: $default-padding;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));

    .apartment,
    .addon {
      @include mat.elevation-transition();
      will-change: box-shadow;

      &.selected {
        @include mat.elevation(8, $apex-main, 0.2);
      }
    }
  }
}

apex-nobb-list {
  .mat-mdc-table .mat-column-actions {
    text-align: right;
  }

  .mat-expansion-panel .mat-expansion-panel-content .mat-expansion-panel-body,
  .mat-expansion-panel .mat-expansion-panel-content .mat-expansion-panel-body {
    padding: 0;
  }
}

apex-nobb-dialog {
  .selected {
    border: 1px solid $color-selected;
  }

  .image-container {
    width: 192px;
  }
}

apex-company-list {
  .primary {
    color: #90c08c;
  }
}

apex-tenancies {
  display: flex;
  flex-direction: column;

  mat-paginator.mat-paginator {
    background: transparent;
  }

  .mat-expansion-panel {
    .mat-expansion-panel-content {
      .mat-expansion-panel-body {
        padding: 0;
      }
    }
  }

  .mat-nav-list {
    padding: 0;
  }

  .cards {
    display: grid;
    gap: $default-padding;

    @include phone() {
      grid-template-columns: 1fr;
    }

    @include tablet() {
      grid-template-columns: 1fr 1fr;
    }

    @include desktop() {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }
}

apex-profile-form {
  .checkbox-wrapper {
    .mat-caption {
      margin-left: 44px;
    }
  }

  .settings {
    .grid {
      display: grid;
      column-gap: $default-padding;
      grid-template-columns: 1fr;

      @include phone() {
        grid-template-columns: 1fr;
      }

      @include tablet() {
        grid-template-columns: 1fr 1fr;
      }

      @include desktop() {
        grid-template-columns: 1fr 1fr 1fr;
      }
    }
  }
}

apex-autocomplete-picker {
  .mat-mdc-chip-list-wrapper {
    flex-direction: column;
    align-items: flex-start;
  }
}

apex-table-of-contents {
  > div.fixed {
    position: fixed;
    top: $default-padding;
    right: $default-padding;
    z-index: 1000; // To be safe.
  }
}

apex-case-list-item {
  .case {
    background-color: $AK-50;

    .other {
      @include others();
    }

    &.list-item {
      position: relative;
      margin-bottom: 1px;
      display: flex;
    }

    &.selected {
      box-shadow: inset 0 0 0 2px $apex-main;

      &.completed {
        box-shadow: inset 0 0 0 2px $apex-green;
      }

      &.others {
        box-shadow: inset 0 0 0 2px $others-background;
      }
    }

    &.archived {
      background-color: $color-disabled;
    }
  }

  .client mat-icon {
    color: $client-color;
  }
}

apex-project-yearly {
  display: flex;
  flex-direction: column;
  flex: 1;

  mat-list {
    .bg-color-main.mat-mdc-subheader {
      color: $primary-foreground;

      .mat-caption {
        color: $AT-100;
      }
    }

    .bg-color-primary-300.mat-mdc-subheader {
      color: $dark-foreground;
    }
  }

  &.loading {
    position: relative;
    opacity: 0.5;
    pointer-events: none;
  }
}

apex-request-list-table {
  .mat-mdc-row:hover {
    background-color: #f0f0f0;
  }
}

apex-field-checklist-list {
  .checklist-card {
    width: 250px;
    height: 250px;
  }
}

apex-project-checklist-templates {
  .mat-nav-list {
    padding: 0;
  }
}

apex-project-checklist-group-templates {
  .mat-nav-list {
    padding: 0;
  }
}

apex-folder-share {
  .dates {
    position: relative;

    a:hover {
      cursor: pointer;
    }

    .mat-date-range-input {
      position: absolute;
      visibility: hidden;
    }
  }
}

apex-file-page {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

apex-topbar {
  background-color: $AT-900;
  color: $light-foreground;
  height: 56px;

  z-index: 999;

  display: flex;
  align-items: center;

  a:hover {
    color: $light-foreground;
  }
}

apex-sidebar {
  .amta {
    margin-top: auto;
  }

  overflow: auto;

  background: $AK-900;

  width: 300px;

  display: flex;
  flex-direction: column;

  header {
    color: $primary-foreground;
    height: 56px;

    display: flex;
    align-items: center;
  }

  section {
    display: flex;
    flex-direction: column;

    a {
      display: flex;
      align-items: center;

      height: 56px;

      padding: 0 $default-padding;

      color: $AK-50;

      &:hover {
        background-color: $AK-800;
        color: $AT-50;
      }

      &.active {
        background-color: $AT-800;
        color: $light-foreground;

        mat-icon {
          color: $light-foreground;
        }
      }

      mat-icon {
        margin-right: $default-padding-small;
        color: $AK-300;
      }
    }

    .mat-caption {
      color: $AK-300;
    }
  }
}

apex-sign-in,
apex-sign-out,
apex-reset-password,
apex-set-password,
apex-client-invite-form {
  display: flex;
  flex: 1;
  flex-direction: row;

  align-items: center;
  justify-content: center;

  background: linear-gradient(to bottom, $AT-900 0%, $AT-900 60%, $AK-900 60%, $AK-900 100%);

  form {
    width: 384px;
  }
}

apex-sign-in,
apex-client-invite-form {
  .mat-caption {
    a {
      color: $primary-foreground;

      &:hover {
        color: $AT-500;
      }
    }
  }
}

apex-sign-in {
  display: flex;
  flex: 1;
  flex-direction: row;

  align-items: center;
  justify-content: center;

  a.tal {
    text-align: left;
  }
}

apex-reset-password {
  a:not(.mat-mdc-unelevated-button),
  a:not(.mat-flat-button):hover {
    color: $light-foreground;
  }
}

apex-deviation-dialog {
  .messages {
    .message {
      margin: 1rem 0;

      .icon {
        .md-avatar-icon {
          background-color: rgba(0, 0, 0, 0.25);
          box-sizing: initial;
          color: #fff;
          padding: 8px;
          border-radius: 50%;
        }
      }

      $border-color: #5d5f64;

      .text {
        position: relative;

        .meta,
        .markdown,
        translator {
          padding: 0.25rem 0.75rem;
        }

        .markdown {
          padding: 0.75rem;
        }

        .meta {
          background: #f9f8f7;
          border-bottom: 1px solid #eee;

          .user {
            margin-right: 0.5rem;
          }
        }

        .role {
          padding: 0.33rem 0.5rem;
          border: 1px solid #ddd;
          border-radius: 3px;
        }

        &::before,
        &::after {
          position: absolute;
          top: 10px;
          right: 100%;
          left: -16px;
          display: block;
          width: 0;
          height: 0;
          pointer-events: none;
          content: '\A';
          border-color: transparent;
          border-style: solid solid outset;
        }

        &::before {
          border-width: 8px;
          border-right-color: $border-color;
        }

        &::after {
          margin-top: 1px;
          margin-left: 2px;
          border-width: 7px;
          border-right-color: #f6f8fa;
        }
      }

      &.casemanager {
        .icon {
          .md-avatar-icon {
            background-color: $casemanager-color;
          }
        }
      }

      &.client {
        .icon {
          .md-avatar-icon {
            background-color: $client-color;
          }
        }
      }

      &.contractor {
        .icon {
          .md-avatar-icon {
            background-color: $contractor-color;
          }
        }
      }

      &.contractor {
        .icon {
          margin: 0;
        }
      }

      .system {
        md-icon {
          color: #666;
          margin-right: 1rem;
        }
      }

      &.error {
        md-icon {
          color: $apex-error;
        }
      }
    }
  }
}

apex-bulk-copy-repeatable-case {
  display: flex;
  flex-direction: column;
  flex: 1;

  apex-object-selector {
    flex: initial;
  }

  .card {
    &.saved {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}

apex-box {
  display: block;

  .mat-expansion-panel {
    .mat-expansion-panel-header {
      will-change: opacity, background-color;
      transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);

      opacity: 1;
    }

    .mat-expansion-panel-content {
      .mat-expansion-panel-body {
        will-change: opacity, background-color;
        transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);

        opacity: 1;
      }
    }
  }

  &.no-padding {
    .mat-expansion-panel {
      .mat-expansion-panel-content {
        .mat-expansion-panel-body {
          padding: 0;
        }
      }
    }
  }

  &.disabled {
    .mat-expansion-panel {
      .mat-expansion-panel-header {
        opacity: 0.8;
      }

      .mat-expansion-panel-content {
        .mat-expansion-panel-body {
          pointer-events: none;

          opacity: 0.7;
        }
      }
    }
  }

  /**
   * Adjusts for it's own header
   */
  &.content-height-adjust-for-top-60 {
    div.mat-expansion-panel-body {
      height: calc(60vh - (3 * 48px));
    }
  }

  &[class*='scroll-box']:not(&.scroll-box-x) {
    .mat-expansion-panel {
      .mat-expansion-panel-content {
        .mat-expansion-panel-body {
          max-height: $apex-box-scroll-height;

          @include phone() {
            max-height: unset;
          }
        }
      }
    }
  }

  &.scroll-box {
    .mat-expansion-panel {
      .mat-expansion-panel-content {
        .mat-expansion-panel-body {
          overflow: auto;
        }
      }
    }

    &-y {
      .mat-expansion-panel {
        .mat-expansion-panel-content {
          .mat-expansion-panel-body {
            overflow-y: auto;
          }
        }
      }
    }

    &-x {
      .mat-expansion-panel {
        .mat-expansion-panel-content {
          .mat-expansion-panel-body {
            overflow-x: auto;
          }
        }
      }
    }
  }
}

apex-reporting-project-cases-by-day {
  table {
    tr.project-detail-row {
      height: 0;
      overflow: hidden;
    }

    .project-detail {
      overflow: hidden;
    }

    .project-detail-td {
      border-bottom-width: 0;
    }

    td.mat-mdc-cell.mat-column-expandedDetail {
      padding: 0;
    }
  }

  .w-96 {
    width: 96px;
  }
}

apex-title-item,
apex-subtitle-item {
  display: block;
}

apex-case-logs {
  display: flex;
  flex-direction: column;
}

apex-case-log-system-message {
  display: flex;
  flex-direction: row;

  margin-bottom: $default-padding;

  .left {
    width: 48px;
  }
}

apex-case-log-user-message {
  display: flex;
  flex-direction: row;

  margin-bottom: $default-padding;

  .right {
    position: relative;

    .meta {
      background: scale-color($AK-100, $lightness: 33%);
      box-shadow: 0 1px 1px $AK-200;
    }

    &::before,
    &::after {
      position: absolute;
      top: 10px;
      right: 100%;
      left: -16px;
      display: block;
      width: 0;
      height: 0;
      pointer-events: none;
      content: '\A';
      border-color: transparent;
      border-style: solid solid outset;
    }

    &::before {
      border-width: 8px;
      border-right-color: $border-color;
    }

    &::after {
      margin-top: 1px;
      margin-left: 2px;
      border-width: 7px;
      border-right-color: scale-color($AK-100, $lightness: 33%);
    }
  }
}

apex-file-preview-loader {
  display: flex;
}

apex-template-picker {
  .dialog {
    width: 400px;
  }
}

apex-mom-builder,
apex-folder-viewer {
  .folder-wrapper {
    height: $default-padding * 16;

    outline: 1px solid $AT-200;
    position: relative;

    .folder-content {
      .mat-icon {
        animation-name: scaleItDown;
        animation-duration: 0.5s;
        animation-fill-mode: forwards;

        font-size: $default-padding * 5;
      }
    }

    &:before {
      content: '';
      opacity: 0;

      position: absolute;
      inset: 0;

      will-change: opacity;
      transition: opacity 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);

      @include mat.elevation(2);
    }

    &:hover {
      .folder-content {
        .mat-icon {
          animation-name: scaleItUp;
          animation-duration: 0.5s;
          animation-fill-mode: forwards;
        }
      }

      &:before {
        opacity: 1;
      }
    }
  }

  apex-file-preview {
    height: 100%;
  }
}

apex-mom-builder {
  @include flex-column();

  .mom-builder-wrapper {
    position: relative;
  }

  .back-button {
    z-index: 2;
  }

  .upload-wrapper {
    position: fixed;

    top: 0;
    left: 0;
    right: 0;
    height: 100%;

    z-index: -1;
    opacity: 0;

    will-change: all;
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);

    .mat-icon.upload-icon {
      font-size: $drag-drop-icon-size;
    }

    &.empty-state {
      z-index: 1;
      opacity: 1;
    }
  }

  .apex-drag-over {
    .upload-wrapper {
      z-index: 10;
      opacity: 1;

      color: $primary-foreground;
      background-color: rgba($AK-900, 0.5);
    }
  }

  .folder-wrapper {
    position: relative;

    .menu-button {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}

apex-mass-mailing-form {
  .recipients {
    h3 {
      mat-icon {
        transform: translateY(3px);
      }
    }

    ul {
      list-style: none;
      padding: 0 0 0 ($default-padding * 2);

      ul {
        padding: 0;
      }

      mat-icon {
        font-size: 20px;
        margin-left: $default-padding-small;
      }
    }

    .mat-mdc-card-subtitle {
      padding-left: $default-padding * 2;
    }

    .remove-button {
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}

@keyframes scaleItUp {
  0% {
    transform: scale(1);
  }
  70% {
    transform: scale(1.12);
  }
  100% {
    transform: scale(1.1);
  }
}

@keyframes scaleItDown {
  0% {
    transform: scale(1.1);
  }
  70% {
    transform: scale(0.98);
  }
  100% {
    transform: scale(1);
  }
}

apex-construction-site-form,
apex-construction-site-view {
  @include flex-column();

  apex-map-drawing {
    @include flex-column();
  }
}

apex-autocomplete-selector {
  display: block;

  .mat-mdc-card-title {
    @extend h2;

    span {
      font-weight: 400;
      font-size: 16px;
    }
  }

  .mat-mdc-card-subtitle {
    margin: 0;
    font-size: 12px;
  }

  .mat-mdc-card {
    margin: 0;
    transition: background-color ease 0.2s;

    &.selected {
      background-color: $AT-100;
    }

    &:hover {
      background-color: $AT-50;
    }
  }

  .filters {
    @include phone() {
      .end-filters.f1 {
        flex: auto;
      }
    }
  }
}

apex-checklist-list,
apex-checklist-group-list-page {
  .bulk-delete-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: $default-padding 0 $default-padding-small;
  }

  .mat-nav-list {
    .mat-headline-6 {
      color: inherit;

      * {
        font-size: inherit;
        color: inherit;
        margin: 0;
        padding: 0;
      }
    }

    .mat-mdc-list-item {
      // Hack to make checkbox click/touch area a little bit bigger,
      // so it's not so easy to miss and then end up going to single view
      mat-checkbox {
        overflow: visible;

        label {
          width: 48px;
          height: 48px;
          transform: translate(-12px, -12px);

          .mat-mdc-checkbox-inner-container {
            transform: translate(12px, 0);
          }
        }
      }
    }
  }
}

apex-checklist-dialog .hint-visible mat-form-field {
  padding-bottom: $default-padding;
}

apex-object-ownership-list {
  mat-nav-list {
    ul {
      list-style: none;
      margin: 0;
      padding: $default-padding-extra-small 0 0;
    }
  }
}

apex-apartment-view {
  margin-bottom: $fab-button-spacer;
}

apex-client-page-documents {
  @include flex-column();
}

apex-project-takeover {
  mat-stepper {
    .mat-step {
      .stepper-send {
        display: none;
      }

      &:first-of-type {
        .stepper-prev {
          display: none;
        }
      }

      &:last-of-type {
        .stepper-next {
          display: none;
        }

        .stepper-send {
          display: inline-block;
        }
      }
    }

    .mat-vertical-stepper-header {
      scroll-padding-top: ($default-padding * 7);
      scroll-margin-top: $default-padding * 3;
    }

    @include phone() {
      .mat-vertical-stepper-header {
        padding: $default-padding $default-padding-small;
      }
      .mat-vertical-content {
        padding: 0 $default-padding $default-padding;
      }
      .mat-vertical-content-container {
        margin-left: ($default-padding + $default-padding-extra-small);
      }

      .mat-stepper-vertical-line::before {
        top: -$default-padding-small;
        bottom: -$default-padding-small;
      }
    }
  }

  apex-cases {
    min-height: 800px;
  }
}

apex-project-takeover-involved {
  mat-icon {
    transform: translateY(-3px);
  }

  ul {
    list-style: none;
    padding: 0 0 0 ($default-padding * 2);
    margin: 0 0 $default-padding $default-padding-small;
    font-style: italic;
  }

  .remove-button {
    position: absolute;
    right: 0;
    top: 0;
  }

  .involved-user {
    position: relative;
  }

  .invalid-button {
    position: absolute;
    top: -$default-padding-small;
    right: -$default-padding-small;
    width: $default-padding * 2;
    height: $default-padding * 2;
    line-height: $default-padding * 2;

    .mat-icon {
      height: $default-padding;
      width: $default-padding;
      font-size: $default-padding;
      line-height: $default-padding;
    }
  }
}

apex-project-takeover {
  .protocol-receiver {
    p {
      padding: 0 0 0 ($default-padding * 2);
      margin: $default-padding-small 0 0 $default-padding-small;
      font-weight: 600;
    }

    ul {
      list-style: none;
      padding: 0 0 0 ($default-padding * 2);
      margin: 0 0 $default-padding $default-padding-small;
      font-style: italic;
    }
  }
}

apex-project-takeover-summary {
  .meter {
    li > span {
      display: inline-block;
      min-width: 120px;
    }
  }

  .bankid-img {
    width: 80px;
  }
}

apex-loader {
  .loader-wrapper {
    visibility: hidden;
    opacity: 0;
    transition:
      opacity 0.5s,
      visibility 0.6s;

    &.fade-in {
      opacity: 1;
      visibility: visible;
      transition:
        opacity 0.5s 0.1s,
        visibility 0s;
    }
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    z-index: 9991;
    display: flex;
    align-items: center;
    justify-content: center;

    .loader-svg {
      width: $default-padding * 12;
      height: $default-padding * 12;
    }
  }

  .loader-svg {
    width: $default-padding * 6;
    max-width: 80vw;
    height: $default-padding * 6;
    max-height: 80vw;
  }

  .icon-wrapper {
    transform-origin: 50% 50%;
    transform: scale(0.8, 0.8);
  }

  .icon-animate {
    transform-origin: 55px 55px;
    transform: scale(1);
  }

  .loader-text {
    font-weight: 300;
  }

  .dot-flashing {
    position: relative;
    width: 4px;
    height: 4px;
    border-radius: 2px;
    background-color: $AK-900;
    color: $AK-900;
    animation: dotFlashing 1s infinite ease alternate;
    animation-delay: 0.5s;
    margin-left: $default-padding-small;
    transform: translateY(1px);

    &::before,
    &::after {
      content: '';
      display: inline-block;
      position: absolute;
      top: 0;
      width: 4px;
      height: 4px;
      border-radius: 2px;
      background-color: $AK-900;
      color: $AK-900;
      animation: dotFlashing 1s infinite ease alternate;
    }

    &::before {
      left: -6px;
      animation-delay: 0s;
    }

    &::after {
      left: 6px;
      animation-delay: 1s;
    }
  }
}

@keyframes dotFlashing {
  0% {
    background-color: $AK-900;
  }
  50%,
  100% {
    background-color: $AK-100;
  }
}

apex-overview-housing {
  .overview-container {
    position: relative;
    background-color: $AK-50;
    width: $overlay-width;
    max-height: calc(#{$overlay-height} - #{$toolbar-height});
    overflow: auto;
  }

  .bg-color-main {
    height: $toolbar-height;
  }
}

apex-file-upload {
  .file-wrapper {
    height: $fileHeight-200;
  }

  .file-upload-container {
    position: relative;
    z-index: 1;

    &.invalid,
    &.drag-over {
      &:before {
        content: '';
        position: absolute;
        inset: 0;
        z-index: -1;
        opacity: 0.5;
      }
    }

    &.invalid.drag-over {
      &:before {
        background-color: $apex-red;
      }
    }

    &.drag-over:not(.invalid) {
      &:before {
        background-color: $apex-green;
      }
    }

    .hover:hover {
      cursor: pointer;
    }

    .no-hover {
      cursor: default;
    }
  }
}

apex-file-usage-carousel {
  .carousel-container {
    background: $AK-50;
  }

  button.arrow {
    border: none;
    background-color: transparent;
  }

  button.arrow {
    position: absolute;
    top: 46%;
    transform: scale(2.5);
    opacity: 25%;
    color: $AT-700;
    z-index: 1;
  }

  &:hover {
    button.arrow {
      opacity: 50%;
    }
  }

  button.arrow:hover {
    cursor: pointer;
    opacity: 75%;
  }

  .arrow-left {
    left: 3%;
  }

  .arrow-right {
    right: 3%;
  }

  div.file-preview.thumbnail {
    @include phone() {
      object-fit: contain;
    }
  }

  .carousel {
    position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden;
  }

  .carousel apex-file-preview {
    @include phone() {
      height: $file-preview-height;
    }
  }

  .carousel {
    opacity: 1;
    transition:
      1000ms ease-in scale,
      1000ms opacity;
  }

  .carousel:hover {
    opacity: 0.9;
  }

  i .load-image-smooth {
    animation-duration: 2s;
  }
}

apex-article-form {
  .textarea-wrapper {
    position: relative;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    outline: none;
  }

  mat-expansion-panel {
    .mdc-notched-outline__leading,
    .mdc-notched-outline__trailing {
      border: none;
    }
  }
}

apex-article-model-viewer {
  .cardContainer {
    gap: 32px 16px;
  }

  .cardBox {
    margin: 0;
    height: 100%;
    padding-top: $default-padding-small;
    padding-bottom: $default-padding-small;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-areas:
      'a b b'
      'a b b';
  }

  .grid-item-left {
    grid-area: a;
    align-self: center;
    width: 100%;
  }

  .grid-item-right {
    grid-area: b;
    align-self: center;
    width: 100%;
  }
}

apex-article-view {
  section img {
    max-width: 100%;
  }

  section video {
    max-width: 100%;
  }
}

apex-file-usage-attachments {
  .cardBox {
    display: grid;
    grid-template-columns: auto 1fr 1fr auto;
    grid-template-areas:
      'left center center right'
      'left center center right'
      'left center center right';
  }

  .grid-item-left {
    width: 100%;
    grid-area: left;
  }

  .grid-item-center {
    width: 100%;
    grid-area: center;
  }

  .grid-item-right {
    width: 100%;
    grid-area: right;
    padding-top: 10%;
  }

  .truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .flex-direction-row {
    flex-direction: row;
  }
}

apex-article-model-dialog {
  mat-form-field-required-marker {
    color: yellow;
  }
}

portal-apartment-page,
portal-cases {
  .portal-banner-image {
    width: 100%;
    aspect-ratio: 4 / 1;
    object-fit: contain;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      inset: 0;
      background: $AK-100 url('https://app.apexapp.io/assets/no-image.png') no-repeat center center;
    }
  }

  apex-link-cards {
    @include filter-drop-shadow-z2();

    a {
      box-shadow: unset;
    }
  }

  .mat-mdc-fab {
    &.newCase {
      display: none;
    }
  }

  .box-list-container {
    $box-max-height: 288px;

    height: calc(#{$box-max-height} - 8px);
    max-height: calc(#{$box-max-height} - 48px);
    overflow-y: auto;
    padding-top: 0;
  }

  .columns-container {
    display: grid;
    align-content: start;
    gap: $default-padding;

    $box-max-height: 288px;

    @include desktop() {
      grid-template-columns: repeat(3, minmax(0, 1fr));
      // max-height: $box-max-height;
    }

    @include tablet() {
      grid-template-columns: 1fr 1fr;
    }

    @include phone() {
      grid-template-columns: 1fr;
    }
  }

  apex-apartment-info {
    apex-logo {
      position: static;
    }
  }

  .router-outlet-content {
    display: flex;
    flex-direction: column;
    margin: auto;
    max-width: 960px;

    > *:not(router-outlet) {
      margin: 0 $default-margin $default-margin;
    }

    @media screen and (max-width: 599px) {
      > *:not(router-outlet) {
        margin: 0 0 0;
      }
    }

    apex-client-page-documents,
    apex-apartment-mom-viewer,
    portal-portal-case-list,
    apex-request-list {
      display: block;
      @include mat.elevation(2);

      margin: 0 $default-margin $default-margin;

      background-color: $AK-50;

      @media screen and (max-width: 599px) {
        margin: 0 0 $default-margin;
      }
    }

    portal-news {
      img {
        max-width: 100%;
      }

      apex-file-usage-attachments {
        > h4 {
          margin-left: 0;
          margin-right: 0;
        }

        > div {
          margin-left: 0;
          margin-right: 0;
        }
      }
    }

    apex-apartment-mom-viewer {
      table.mat-mdc-table {
        margin: 0;
      }
    }

    apex-client-addon-addons-page {
      .fileusages {
        @include mat.elevation(2);
      }

      apex-client-addon-apartment-card {
        .fileusages {
          box-shadow: unset;
        }
      }

      .image-name-wrapper.no-image {
        .category-title {
          .parent-name {
            color: $AK-700;
          }
        }
      }

      .category-title {
        .parent-name {
          top: $default-padding-extra-small;
          left: 0;
        }
      }
    }

    apex-client-addon-apartment-info {
      display: block;
      background-color: $AK-50;
      margin: $default-margin 0;
      @include mat.elevation(2);
    }
  }
}

portal-portal-case-list {
  apex-title-item {
    overflow: hidden;

    .text-wrapper {
      overflow: hidden;
    }
  }
}

portal-portal-case-list-item {
  apex-file-viewer-preview {
    .file-wrapper {
      width: $file-viewer-preview-width-small;
      min-width: $file-viewer-preview-width-small;

      height: $file-viewer-preview-height-small;
      min-height: $file-viewer-preview-height-small;
    }
  }

  apex-case-list-item-wrapper {
    border-bottom: 1px solid lightgrey;
  }

  .complete {
    .left {
      background-color: scale-color($AG-200, $lightness: 70%);
    }

    .right {
      background-color: scale-color($AG-200, $lightness: 95%);
    }
  }

  .disabled {
    .right {
      background-color: scale-color($color-disabled, $lightness: 90%);
    }
  }
}

apex-case-list-item-wrapper {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: minmax(100px, auto) 0 1fr;

  .right {
    display: grid;
    grid-template-areas:
      'top-left    top-right'
      'main        main'
      'bottom-left bottom-right';
    grid-template-rows: auto 1fr auto;
    grid-template-columns: 1fr 1fr;
    gap: $default-padding-small;

    .top {
      grid-area: top-left / top-left / top-right / top-right;
    }

    .top-left {
      grid-area: top-left;
    }

    .top-right {
      grid-area: top-right;
    }

    .main {
      grid-area: main;
    }

    .bottom {
      grid-area: bottom-left / bottom-left / bottom-right / bottom-right;
    }

    .bottom-left {
      grid-area: bottom-left;
    }

    .bottom-right {
      grid-area: bottom-right;
    }
  }
}

portal-portal-case-view {
  apex-case-log-user-message {
    .left {
      display: none;
    }
  }
}

apex-link-cards {
  .active {
    background-color: $AT-700;
    color: $primary-foreground;

    &:hover {
      background-color: $AK-50;
      color: $AT-900;
    }
  }
}

portal-portal-addon-order-list {
  .header-content {
    .w-60 {
      width: auto;
      flex: 1;
    }
  }
}

apex-logo {
  display: block;
  aspect-ratio: 1;
  width: $logo-container-width;
  height: $logo-container-height;
  max-width: 128px;
  max-height: 128px;
  position: absolute;
  left: 32px;
  bottom: -60px;

  @include phone() {
    width: 42px;
    height: 42px;
    bottom: -19px;
  }
}

portal-start {
  apex-logo {
    height: $default-margin * 3;
    width: $default-margin * 3;
    position: relative;
    left: 0;
    bottom: 0;
  }
}

apex-banner {
  .banner-container {
    position: relative;
  }

  .banner-container-small {
    margin-bottom: 30px;
    position: relative;
  }

  .avatar-container {
    position: absolute;
    left: 32px;
    bottom: -66px;
  }

  .avatar-small {
    position: absolute;
    left: 32px;
    bottom: -20px;
  }
}

apex-company-card {
  // need to include this to push down mat-card content
  .card-content {
    display: flex;
    flex-direction: column;
  }

  // need to include this to style mat-card-actions
  .card-buttons {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
}

apex-signing {
  .complete {
    background-color: scale-color($AG-200, $lightness: 70%);
  }
}

apex-article-edit {
  display: flex;
  flex: 1;
  flex-direction: column;

  .mat-tab-body-wrapper {
    display: flex;
    flex: 1;
  }

  .mat-tab-body-content {
    display: flex;
    flex: 1;
  }

  form {
    max-width: 720px;
    width: 100%;

    margin: 0 auto;
  }
}

apex-article-edit-content {
  editor {
    div.tox.tox-tinymce {
      min-height: 512px;
    }
  }
}

apex-construction-site-list {
  a {
    color: $AK-900;

    &:hover {
      color: inherit;
    }
  }
}

apex-inspection-case-page {
  apex-cases {
    div.mdc-tab:nth-child(3) {
      opacity: 1;
      padding-right: 0px;

      .mdc-tab__content {
        flex: 1;
        justify-content: end;
      }
    }
  }
}

apex-object-takeover,
apex-project-apartment-takeover-page {
  apex-cases {
    div.mat-mdc-tab:nth-child(2) {
      color: rgba(0, 0, 0, 0.6);
      opacity: 1;
    }

    div.mdc-tab:nth-child(3) {
      opacity: 1;
      flex-grow: 0 !important;
    }
  }
}

apex-cases {
  div.mdc-tab:nth-child(4) {
    opacity: 1;
    padding-right: 0px;

    .mdc-tab__content {
      flex: 1;
      justify-content: end;
    }
  }

  mat-tab-header {
    background-color: $AK-100;
  }

  div.mat-mdc-tab.mdc-tab {
    height: 56px;
  }
}

apex-file-usage {
  .file-preview-icon-bottom {
    bottom: $default-padding * 3;
  }
}

apex-button {
  // adjusting case-view-button
  button {
    height: auto !important;
  }
}

apex-case-filter-dialog {
  .mat-mdc-dialog-content {
    height: 60vw;
    max-height: 60vh;
    width: 80vw;
    max-width: 80vw;

    @include phone {
      min-height: 100vh;
      max-height: 100vh;
      min-width: 100vw;
      max-width: 100vw;
    }
  }
}

apex-article-model-dialog {
  // open dialog - mat-dialog-actions to be at the bottom
  display: flex;
  height: 100%;
  flex-direction: column;
}

apex-client-addon-addons-page {
  apex-client-addon-breadcrumb {
    .apex-client-addon-breadcrumb {
      position: sticky;
      top: 0;
      z-index: 100;
    }
  }
}

portal-apartment-page {
  apex-client-addon-addons-page {
    apex-client-addon-breadcrumb {
      .apex-client-addon-breadcrumb {
        position: sticky;
        top: $header-height;
        z-index: 100;
      }
    }
  }
}

apex-grid-gallery {
  apex-file-preview {
    min-height: 100px;
    min-width: 100px;
  }
}

apex-unwanted-incident-view {
  apex-image-viewer {
    min-height: 400px;
  }
}
