@use '@angular/material' as mat;
@use './theming.scss' as *;

@use 'sass:math';

/* Colors */

$primary: map-get($apex-theme, primary);
$accent: map-get($apex-theme, accent);
$warn: map-get($apex-theme, warn);
$background: map-get($apex-theme, background);
$foreground: map-get($apex-theme, foreground);
$others-foreground: rgba(0, 0, 0, 0.87);
$others-background: scale-color($AB-100, $lightness: 55%);

$background-white: $AK-50;

$primary-foreground: $AK-50;
$light-foreground: $AK-50;
$dark-foreground: $AK-900;

$apex-main: map-get($primary, 700);
$apex-main-variant: map-get($primary, 900);
$apex-error: map-get($warn, 500);
$apex-gray: $AK-400;
$apex-red: $AR-300;
$apex-green: $AG-300;
$apex-yellow: $AY-300;
$apex-blue: $AB-300;

$bg-color-disabled: rgba(50, 50, 55, 0.12);
$fg-color-disabled: rgba(50, 50, 55, 0.26);

$color-disabled: mat.get-theme-color($apex-theme, background, disabled-button);
$color-disabled-light: mat.get-theme-color($apex-theme, foreground, disabled-button);
$button-disabled: mat.get-color-from-palette($foreground, disabled-button);
$color-gray: $apex-gray;
$color-google-gray: #f2f1f0;
$color-info: map-get($primary, 500);
$color-new: map-get($primary, 500);
$color-selected: map-get($primary, 700);
$color-warning: $AY-300;
$color-danger: $AR-300;

$fab-color: map-get($primary, 700);
$button-color: map-get($primary, 700);
$link-color: map-get($primary, 900);
$checkbox-color: map-get($primary, 700);

$contractor-color: $apex-yellow;
$apartment-color: $apex-blue;
$project-color: $apex-blue;
$casemanager-color: $apex-red;
$client-color: map-get($primary, 500);

$border-color: rgba(0, 0, 0, 0.12);

$hover-color: rgba(black, 0.04);

/* Constants */

$default-thumbnail-size: 192px;

$default-padding: 16px;
$default-padding-small: math.div($default-padding, 2);
$default-padding-extra-small: math.div($default-padding-small, 2);

$default-margin: 16px;
$default-margin-small: math.div($default-margin, 2);
$default-margin-extra-small: math.div($default-margin-small, 2);

$header-height: 56px;

$phone-breakpoint-max: 599px;
$phone-landscape-breakpoint-max: 959px;

$tablet-breakpoint-min: 600px;
$tablet-breakpoint-max: 1199px;
$tablet-landscape-breakpoint-min: 960px;
$tablet-landscape-breakpoint-max: $tablet-breakpoint-max;

$desktop-breakpoint-min: 1200px;
$desktop-large-breakpoint-min: 1920px;

$toolbar-height: 48px;

$default-button-height: 40px;
$fab-button-spacer: 56px + $default-padding;

$drag-drop-icon-size: $default-padding * 12;
$drag-drop-icon-size-small: math.div($drag-drop-icon-size, 2);

$default-text-editor-height: 500px;
$default-text-editor-min-height: 300px;

$fileHeight-256: 256px;
$fileHeight-200: 200px;

$file-viewer-preview-height: 150px;
$file-viewer-preview-width: 200px;

$file-viewer-preview-height-small: 32px;
$file-viewer-preview-width-small: 32px;

$overlay-width: 400px;
$overlay-height: 320px;

$apex-box-scroll-height: calc(288px - #{$toolbar-height});

$file-preview-height: 280px;

$logo-container-width: 128px;
$logo-container-height: 128px;
