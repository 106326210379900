// functions and mixins

@use '@angular/material' as mat;
@use 'sass:math';

@use './variables' as *;
@use './theming' as theming;

/* Mixins */

@mixin flex-column {
  display: flex;
  flex: 1;
  flex-direction: column;
}

@mixin others {
  color: $others-foreground;
  background-color: $others-background;
}

@mixin phone {
  @media (max-width: $phone-breakpoint-max) {
    @content;
  }
}

@mixin phone-landscape {
  @media (max-width: $phone-landscape-breakpoint-max) and (orientation: landscape) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $tablet-breakpoint-min) and (max-width: $tablet-breakpoint-max) {
    @content;
  }
}

@mixin tablet-landscape {
  @media (min-width: $tablet-landscape-breakpoint-min) and (max-width: $tablet-landscape-breakpoint-max) and (orientation: landscape) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: $desktop-breakpoint-min) {
    @content;
  }
}

@mixin desktop-large {
  @media (min-width: $desktop-large-breakpoint-min) {
    @content;
  }
}

@mixin print {
  @media print {
    @content;
  }
}

@mixin filter-drop-shadow-z2 {
  filter: drop-shadow(0px 3px 1px rgb(50 50 55 / 20%)) drop-shadow(-1px 2px 2px rgb(50 50 55 / 14%))
    drop-shadow(0px 1px 5px rgb(50 50 55 / 12%));
}

/* Functions */

/* This is the code needed to make the footer for the fab with the cutout */
@function fabcut($position, $radius) {
  $result: null;
  $iterations: 180;
  $midCut: math.div($iterations, 2);
  $value: null;

  @for $i from 1 to $iterations {
    @if $i == 1 {
      $value: 'calc(' + ($position * 1%) + ' - ' + ($radius * 1px) + ') ' + 0px;
    } @else if $i < $midCut {
      $value: 'calc(' +
        ($position * 1%) +
        ' - ' +
        (abs($radius * cos($i * 1deg)) * 1px) +
        ') ' +
        (abs($radius * sin($i * 1deg)) * 1px);
    } @else if $i == $midCut {
      $value: ($position * 1%) + ' ' + ($radius * 1px);
    } @else if $i > $midCut {
      $value: 'calc(' +
        ($position * 1%) +
        ' + ' +
        (abs($radius * cos($i * 1deg)) * 1px) +
        ') ' +
        (abs($radius * sin($i * 1deg)) * 1px);
    } @else if $i == 90 {
      $value: 'calc(' + ($position * 1%) + ' + ' + ($radius * 1px) + ') ' + 0px;
    }

    $result: append($result, unquote($value), comma);
  }

  @return $result;
}

@function footerpolygon($position, $diameter) {
  $polygon: polygon(0 0, fabcut($position, $diameter), 100% 0, 100% 100%, 0 100%);
  @return $polygon;
}

@function pow($number, $exp) {
  $value: 1;
  @if $exp > 0 {
    @for $i from 1 through $exp {
      $value: $value * $number;
    }
  } @else if $exp < 0 {
    @for $i from 1 through -$exp {
      $value: math.div($value, $number);
    }
  }
  @return $value;
}

@function fact($number) {
  $value: 1;
  @if $number > 0 {
    @for $i from 1 through $number {
      $value: $value * $i;
    }
  }
  @return $value;
}

@function pi() {
  @return 3.14159265359;
}

@function rad($angle) {
  $unit: unit($angle);
  $unitless: math.div($angle, ($angle * 0 + 1));
  // If the angle has 'deg' as unit, convert to radians.
  @if $unit == deg {
    $unitless: math.div($unitless, 180) * pi();
  }
  @return $unitless;
}

@function sin($angle) {
  $sin: 0;
  $angle: rad($angle);
  // Iterate a bunch of times.
  @for $i from 0 through 10 {
    $sin: $sin + pow(-1, $i) * math.div(pow($angle, (2 * $i + 1)), fact(2 * $i + 1));
  }
  @return $sin;
}

@function cos($angle) {
  $cos: 0;
  $angle: rad($angle);
  // Iterate a bunch of times.
  @for $i from 0 through 10 {
    $cos: $cos + pow(-1, $i) * math.div(pow($angle, 2 * $i), fact(2 * $i));
  }
  @return $cos;
}

@function tan($angle) {
  @return sin($angle) / cos($angle);
}

// Noter her
$one-item-height: (
  0: 56px,
  -1: 52px,
  -2: 48px,
  -3: 44px,
  -4: 40px,
  -5: 40px,
);

$two-item-height: (
  0: 72px,
  -1: 68px,
  -2: 64px,
  -3: 60px,
  -4: 56px,
  -5: 56px,
);

$three-item-height: (
  0: 88px,
  -1: 84px,
  -2: 80px,
  -3: 76px,
  -4: 72px,
  -5: 72px,
);

@function get-one-item-height() {
  $density-scale: mat.get-density-config(theming.$apex-theme);

  @return map-get($one-item-height, $density-scale);
}

@function get-two-item-height() {
  $density-scale: mat.get-density-config(theming.$apex-theme);

  @return map-get($two-item-height, $density-scale);
}

@function get-three-item-height() {
  $density-scale: mat.get-density-config(theming.$apex-theme);

  @return map-get($three-item-height, $density-scale);
}
