@use '@angular/material' as mat;
@use 'sass:map';

// APEX - Teal
$AT-50: #def9f6;
$AT-100: #beeae9;
$AT-200: #9fdbdd;
$AT-300: #7fccd0;
$AT-400: #5fbec3;
$AT-500: #3fafb6;
$AT-600: #20a0aa;
$AT-700: #00919d;
$AT-800: #007a86;
$AT-900: #00636f;

// APEX - Gray
$AK-50: #f9f9f9;
$AK-100: #ededed;
$AK-200: #d6d6d7;
$AK-300: #adadaf;
$AK-400: #98989b;
$AK-500: #7a7a7d;
$AK-600: #656569;
$AK-700: #515155;
$AK-800: #46464b;
$AK-900: #323237;

// APEX - Peach
$AP-50: #faefed;
$AP-100: #f7deda;
$AP-200: #f7c9c1;
$AP-300: #f5b7ab;
$AP-400: #f0a597;
$AP-500: #eb8f7f;
$AP-600: #e67e6c;
$AP-700: #e56a54;
$AP-800: #c25a47;
$AP-900: #a14a3b;

// APEX - Red
$AR-100: #d5888c;
$AR-200: #c15b62;
$AR-300: #c53440;
$AR-500: #c61226;

// APEX - Blue
$AB-100: #abb3db;
$AB-200: #8b95cc;
$AB-300: #727cc0;

// APEX - Yellow
$AY-100: #ebdf9f;
$AY-200: #ddca5f;
$AY-300: #cfb706;

// APEX - Green
$AG-100: #90c08c;
$AG-200: #7bb375;
$AG-300: #46733f;

$apex-primary-palette: (
  50: $AT-50,
  100: $AT-100,
  200: $AT-200,
  300: $AT-300,
  400: $AT-400,
  500: $AT-500,
  600: $AT-600,
  700: $AT-700,
  800: $AT-800,
  900: $AT-900,
  contrast: (
    50: $AK-900,
    100: $AK-900,
    200: $AK-900,
    300: $AK-900,
    400: $AK-50,
    500: $AK-50,
    600: $AK-50,
    700: $AK-50,
    800: $AK-50,
    900: $AK-50,
  ),
);

$apex-secondary-palette: (
  50: $AK-50,
  100: $AK-100,
  200: $AK-200,
  300: $AK-300,
  400: $AK-400,
  500: $AK-500,
  600: $AK-600,
  700: $AK-700,
  800: $AK-800,
  900: $AK-900,
  contrast: (
    50: $AK-900,
    100: $AK-900,
    200: $AK-900,
    300: $AK-900,
    400: $AK-50,
    500: $AK-50,
    600: $AK-50,
    700: $AK-50,
    800: $AK-50,
    900: $AK-50,
  ),
);

$apex-accent-palette: (
  50: $AP-50,
  100: $AP-100,
  200: $AP-200,
  300: $AP-300,
  400: $AP-400,
  500: $AP-500,
  600: $AP-600,
  700: $AP-700,
  800: $AP-800,
  900: $AP-900,
  contrast: (
    50: $AK-900,
    100: $AK-900,
    200: $AK-900,
    300: $AK-900,
    400: $AK-50,
    500: $AK-50,
    600: $AK-50,
    700: $AK-50,
    800: $AK-50,
    900: $AK-50,
  ),
);

$apex-warn-palette: (
  100: $AR-100,
  200: $AR-200,
  300: $AR-300,
  500: $AR-500,
  contrast: (
    100: $AK-900,
    200: $AK-900,
    300: $AK-50,
    500: $AK-50,
  ),
);

$apex-blue-palette: (
  100: $AB-100,
  200: $AB-200,
  300: $AB-300,
  contrast: (
    100: $AK-900,
    200: $AK-900,
    300: $AK-50,
  ),
);

$apex-yellow-palette: (
  100: $AY-100,
  200: $AY-200,
  300: $AY-300,
  contrast: (
    100: $AK-900,
    200: $AK-900,
    300: $AK-50,
  ),
);

$apex-green-palette: (
  100: $AG-100,
  200: $AG-200,
  300: $AG-300,
  contrast: (
    100: $AK-900,
    200: $AK-900,
    300: $AK-50,
  ),
);

$apex-mat-light-theme-foreground: (
  base: $AK-900,
  divider: rgba($AK-900, 0.12),
  dividers: rgba($AK-900, 0.12),
  disabled: rgba($AK-900, 0.38),
  disabled-button: rgba($AK-900, 0.26),
  disabled-text: rgba($AK-900, 0.38),
  elevation: $AK-900,
  hint-text: rgba($AK-900, 0.38),
  secondary-text: rgba($AK-900, 0.54),
  icon: rgba($AK-900, 0.54),
  icons: rgba($AK-900, 0.54),
  text: rgba($AK-900, 0.87),
  slider-min: rgba($AK-900, 0.87),
  slider-off: rgba($AK-900, 0.26),
  slider-off-active: rgba($AK-900, 0.38),
);

$apex-mat-light-theme-background: (
  status-bar: $AK-100,
  app-bar: map.get($apex-primary-palette, 900),
  background: $AK-100,
  hover: rgba($AK-900, 0.04),
  card: $AK-50,
  dialog: $AK-50,
  disabled-button: rgba($AK-900, 0.12),
  raised-button: $AK-50,
  focused-button: rgba($AK-900, 0.12),
  selected-button: $AK-100,
  selected-disabled-button: $AK-300,
  disabled-button-toggle: $AK-50,
  unselected-chip: $AK-100,
  disabled-list-option: $AK-50,
  tooltip: $AK-700,
);

$body-font: 'Open Sans, calibri';
$headline-font: 'Titillium Web, Verdana';

$apex-primary: mat.define-palette($apex-primary-palette, 700, 500, 900);
$apex-secondary-palette: mat.define-palette($apex-secondary-palette, 700, 700, 700);
$apex-accent: mat.define-palette($apex-accent-palette, 700, 700, 700);
$apex-warn: mat.define-palette($apex-warn-palette, 300, 300, 300);

$apex-typography: mat.define-typography-config(
  $font-family: $body-font,
  $headline-1: mat.define-typography-level(112px, 1.2, 200, $headline-font, $letter-spacing: -0.05em),
  $headline-2: mat.define-typography-level(56px, 1.2, 200, $headline-font, $letter-spacing: -0.02em),
  $headline-3: mat.define-typography-level(45px, 1.2, 200, $headline-font, $letter-spacing: -0.005em),
  $headline-4: mat.define-typography-level(34px, 1.2, 200, $headline-font),
  $headline-5: mat.define-typography-level(24px, 1.2, 200, $headline-font),
  $headline-6: mat.define-typography-level(20px, 1.2, 300, $headline-font),
  $subtitle-1: mat.define-typography-level(16px, 1.2, 300, $headline-font),
  $subtitle-2: mat.define-typography-level(16px, 1.2, 300, $headline-font),
  $body-1: mat.define-typography-level(14px, 1.2, 400, $body-font),
  $body-2: mat.define-typography-level(14px, 1.2, 400, $body-font),
  $caption: mat.define-typography-level(12px, 1.2, 400, $body-font),
  $button: mat.define-typography-level(14px, 1.2, 400, $body-font),
);

@function define-light-theme($primary, $accent, $warn) {
  $theme: mat.define-light-theme(
    (
      color: (
        primary: $apex-primary,
        accent: $apex-accent,
        warn: $apex-warn,
      ),
      typography: $apex-typography,
      density: 0,
    )
  );
  $color: map.get($theme, color);
  $color: map.merge(
    $color,
    (
      foreground: $apex-mat-light-theme-foreground,
      background: $apex-mat-light-theme-background,
    )
  );
  @return map.merge(
    $theme,
    (
      color: $color,
    )
  );
}

$apex-theme: define-light-theme($apex-primary, $apex-accent, $apex-warn);
