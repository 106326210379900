//media stuff goes here

@use './variables.scss' as *;
@use './functions.scss' as *;
@use './classes.scss' as *;

.phone-hide,
.ph {
  @include phone() {
    display: none;
  }
}

.phone-show,
.ps {
  display: none;

  @include phone() {
    display: block;
  }
}

.tablet-hide,
.th {
  @include tablet() {
    display: none;
  }
}

.tablet-show,
.ts {
  display: none;

  @include tablet() {
    display: block;
  }
}

.desktop-hide,
.dh {
  @include desktop() {
    display: none;
  }
}

.desktop-show,
.ds {
  display: none;

  @include desktop() {
    display: block;
  }
}

@mixin widthMixin($i, $media, $shorthand) {
  .#{$media}-width,
  .#{$shorthand}-width,
  .#{$media}-w,
  .#{$shorthand}-w {
    &-#{$i} {
      width: $i * 1%;
    }
  }

  .#{$media}-max-width,
  .#{$shorthand}-max-width,
  .#{$media}-maxw,
  .#{$shorthand}-maxw {
    &-#{$i} {
      max-width: $i * 1%;
    }
  }

  .#{$media}-min-width,
  .#{$shorthand}-min-width,
  .#{$media}-minw,
  .#{$shorthand}-minw {
    &-#{$i} {
      min-width: $i * 1%;
    }
  }
}

@for $i from 1 through 100 {
  @if (($i % 5 == 0) or ($i == 33) or ($i == 66) or ($i == 99)) {
    @include phone() {
      @include widthMixin($i, 'phone', 'p');
    }

    @include tablet() {
      @include widthMixin($i, 'tablet', 't');
    }

    @include desktop() {
      @include widthMixin($i, 'desktop', 'd');
    }
  }
}

@mixin flexMixin($media, $shorthand) {
  .#{$media}-display-flex,
  .#{$media}-df,
  .#{$shorthand}-display-flex,
  .#{$shorthand}-df {
    display: flex;
  }

  .#{$media}-display-flex-inline,
  .#{$media}-dfi,
  .#{$shorthand}-display-flex-inline,
  .#{$shorthand}-dfi {
    display: inline-flex;
  }

  .#{$media}-column,
  .#{$media}-c,
  .#{$shorthand}-column,
  .#{$shorthand}-c {
    flex-direction: column;
  }

  .#{$media}-row,
  .#{$media}-r,
  .#{$shorthand}-row,
  .#{$shorthand}-r {
    flex-direction: row;
  }

  .#{$media}-wrap,
  .#{$media}-w,
  .#{$shorthand}-wrap,
  .#{$shorthand}-w {
    flex-wrap: wrap;
  }

  .#{$media}-nowrap,
  .#{$media}-nw,
  .#{$shorthand}-nowrap,
  .#{$shorthand}-nw {
    flex-wrap: nowrap;
  }

  $justify: (
    'fs': 'flex-start',
    'fe': 'flex-end',
    'c': 'center',
    'sa': 'space-around',
    'sb': 'space-between',
    'se': 'space-evenly',
  );
  $align: (
    'fs': 'flex-start',
    'fe': 'flex-end',
    'c': 'center',
    'b': 'baseline',
  );

  @each $name, $value in $justify {
    .#{$media}-j#{$name},
    .#{$media}-justify-#{$value},
    .#{$shorthand}-j#{$name},
    .#{$shorthand}-justify-#{$value} {
      justify-content: #{$value};
    }
  }

  @each $name, $value in $align {
    .#{$media}-a#{$name},
    .#{$media}-align-#{$value},
    .#{$shorthand}-a#{$name},
    .#{$shorthand}-align-#{$value} {
      align-items: #{$value};
    }

    .#{$media}-as#{$name},
    .#{$media}-align-self-#{$value},
    .#{$shorthand}-as#{$name},
    .#{$shorthand}-align-self-#{$value} {
      align-self: #{$value};
    }
  }

  .#{$media}-fdot5.#{$media}-fdot5.#{$media}-fdot5,
  .#{$shorthand}-fdot5.#{$shorthand}-fdot5.#{$shorthand}-fdot5 {
    flex: 0.5;
  }

  @for $i from 0 to 10 {
    .#{$media}-f#{$i}.#{$media}-f#{$i}.#{$media}-f#{$i},
    .#{$media}-flex#{$i}.#{$media}-flex#{$i}.#{$media}-flex#{$i},
    .#{$shorthand}-f#{$i}.#{$shorthand}-f#{$i}.#{$shorthand}-f#{$i},
    .#{$shorthand}-flex#{$i}.#{$shorthand}-flex#{$i}.#{$shorthand}-flex#{$i} {
      flex: $i;
    }
  }
}

@include phone() {
  @include flexMixin('phone', 'p');
}

@include tablet() {
  @include flexMixin('tablet', 't');
}

@include desktop() {
  @include flexMixin('desktop', 'd');
}

$utils: (
  '': (
    '': $default-padding,
  ),
  'vertical': (
    'top': $default-padding,
    'bottom': $default-padding,
  ),
  'horizontal': (
    'left': $default-padding,
    'right': $default-padding,
  ),
  'top': (
    'top': $default-padding,
  ),
  'right': (
    'right': $default-padding,
  ),
  'bottom': (
    'bottom': $default-padding,
  ),
  'left': (
    'left': $default-padding,
  ),
);

$actions: (
  'remove': 'r',
  'add': 'a',
);

$props: (
  'padding': 'p',
  'margin': 'm',
);

@mixin utilsMixin($media, $shorthand) {
  @each $action, $aAbbr in $actions {
    @each $prop, $pAbbr in $props {
      @each $direction, $propDirections in $utils {
        $name: #{$action}-#{$prop};
        $abbr: #{$aAbbr}#{$pAbbr};

        @if (#{$direction} != '') {
          $name: #{$name}-#{$direction};
          $abbr: #{$abbr}#{str-slice(#{$direction}, 1, 1)};
        }

        .#{$media}-#{$name},
        .#{$shorthand}-#{$name},
        .#{$media}-#{$abbr},
        .#{$shorthand}-#{$abbr} {
          @each $propDirection, $value in $propDirections {
            $propName: #{$prop};

            @if (#{$action} == 'remove') {
              $value: 0;
            }

            @if (#{$propDirection} != '') {
              $propName: #{$propName}-#{$propDirection};
            }

            #{$propName}: #{$value};
          }
        }

        @if ($action == 'add') {
          .#{$media}-#{$name}-#{small},
          .#{$shorthand}-#{$name}-#{small},
          .#{$media}-#{$abbr}#{s},
          .#{$shorthand}-#{$abbr}#{s} {
            @each $propDirection, $value in $propDirections {
              $value: $default-padding-small;
              $propName: #{$prop};

              @if (#{$propDirection} != '') {
                $propName: #{$propName}-#{$propDirection};
              }

              #{$propName}: #{$value};
            }
          }
        }
      }
    }
  }
}

@include phone() {
  @include utilsMixin('phone', 'p');
}

@include tablet() {
  @include utilsMixin('tablet', 't');
}

@include desktop() {
  @include utilsMixin('desktop', 'd');
}
