@use '@angular/material' as mat;
//helper classes for apex awesomeness

@use './variables.scss' as *;
@use './functions.scss' as *;
@use './theming.scss' as *;

@use 'sass:math';

body,
html {
  @include mat.typography-level($apex-typography, body-1);
  color: rgba($AK-900, 0.87);

  margin: 0;
  padding: 0;

  h1 {
    @include mat.typography-level($apex-typography, headline-5);
  }

  h2 {
    @include mat.typography-level($apex-typography, headline-6);
  }

  h3 {
    @include mat.typography-level($apex-typography, subtitle-1);
  }

  h4,
  h5,
  h6 {
    @include mat.typography-level($apex-typography, subtitle-2);
  }
}

apex-root {
  @include flex-column();
}

a {
  color: $link-color;
  text-decoration: none;

  &:hover {
    color: $link-color;
  }
}

div.column > md-content {
  overflow-y: auto;
}

.success-snackbar {
  background-color: #f3f8f0;
  color: $apex-green;
}

.info-snackbar {
  color: $color-info;
  background-color: #bef;
}

.warning-snackbar {
  color: mat.get-color-from-palette($warn);
  background-color: #fff8e2;
}

.error-snackbar {
  background: #ffbaba;
  color: $apex-error;
}

.accent-color {
  color: mat.get-color-from-palette($accent) !important;
}

.primary-color {
  color: mat.get-color-from-palette($primary) !important;
}

.warn-color {
  color: mat.get-color-from-palette($warn) !important;
}

.client-color {
  color: $client-color;
}

.contractor-color {
  color: $contractor-color;
}

.accent-color-bg {
  background-color: mat.get-color-from-palette($accent) !important;
}

.primary-color-bg {
  background-color: mat.get-color-from-palette($primary) !important;
}

.warn-color-bg {
  background-color: mat.get-color-from-palette($warn) !important;
}

.button-color {
  color: $button-color !important;
}

.color-warning {
  color: $color-warning;
}

.color-danger {
  color: $color-danger;
}

.color-black {
  color: black;
}

.color-white {
  color: white;
}

.text-align-left,
.tal {
  text-align: left;
}

.text-align-right,
.tar {
  text-align: right;
}

.text-align-center,
.tac {
  text-align: center;
}

.db,
.display-block {
  display: block;
}

.dib,
.display-inline-block {
  display: inline-block;
}

.white-space-pre-line {
  white-space: pre-line;
}

.white-space-break-spaces,
.wsbs {
  white-space: break-spaces;
}

.white-space-nowrap,
.wsnw {
  white-space: nowrap;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.hyphens {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}

@for $i from 1 through 5 {
  .line-clamp-#{$i} {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: #{$i};
    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;
  }
}

.oh.oh.oh {
  overflow: hidden;
}

.oa {
  overflow: auto;
}

.bsbb {
  box-sizing: border-box;
}

.success-color {
  color: $apex-green;
}

.warning-color {
  color: mat.get-color-from-palette($warn);

  &-100 {
    color: $AR-100;
  }
}

.error-color {
  color: $apex-error;
}

.disabled-color {
  color: $color-disabled;
}

.gray-color {
  color: $apex-gray;
}

.info-color {
  color: $color-info;
}

.bg-color-white {
  background-color: white;
}

.bg-color-white-important {
  background-color: white !important;
}

.bg-color-main {
  background-color: $apex-main-variant;
  color: $primary-foreground;

  mat-slide-toggle {
    &.mat-mdc-checked {
      .mat-mdc-slide-toggle-bar {
        background-color: rgba($AT-400, 0.54);
      }

      .mat-mdc-slide-toggle-thumb {
        background-color: $AT-400;
      }
    }

    &:not(.mat-mdc-checked) {
      .mat-mdc-slide-toggle-bar {
        background-color: $AK-200;
      }

      .mat-mdc-slide-toggle-thumb {
        background-color: $AK-50;
      }
    }
  }

  mat-checkbox {
    .mat-mdc-checkbox-frame {
      border-color: $primary-foreground;
    }
  }

  a:hover {
    color: $AT-300;
  }
}

.bg-color-warn {
  background-color: mat.get-color-from-palette($warn);
  color: $light-foreground;
}

.bg-color-primary-900 {
  background-color: $AT-900;
  color: $light-foreground;
}

.bg-color-primary-800 {
  background-color: $AT-800;
  color: $light-foreground;
}

.bg-color-primary-700 {
  background-color: $AT-700;
  color: $light-foreground;
}

.bg-color-primary-600 {
  background-color: $AT-600;
  color: $primary-foreground;
}

.bg-color-primary-500 {
  background-color: $AT-500;
  color: $primary-foreground;
}

.bg-color-primary-400 {
  background-color: $AT-400;
  color: $primary-foreground;
}

.bg-color-primary-300 {
  background-color: $AT-300;
}

.bg-color-primary-200 {
  background-color: $AT-200;
}

.bg-color-primary-100 {
  background-color: $AT-100;
}

.bg-color-primary-50 {
  background-color: $AT-50;
}

.bg-color-secondary-900 {
  background-color: $AK-900;
  color: $light-foreground;
}

.bg-color-secondary-800 {
  background-color: $AK-800;
  color: $light-foreground;
}

.bg-color-secondary-700 {
  background-color: $AK-700;
  color: $light-foreground;
}

.bg-color-secondary-600 {
  background-color: $AK-600;
  color: $primary-foreground;
}

.bg-color-secondary-500 {
  background-color: $AK-500;
  color: $primary-foreground;
}

.bg-color-secondary-400 {
  background-color: $AK-400;
  color: $primary-foreground;
}

.bg-color-secondary-300 {
  background-color: $AK-300;
}

.bg-color-secondary-200 {
  background-color: $AK-200;
}

.bg-color-secondary-100 {
  background-color: $AK-100;
}

.bg-color-secondary-50 {
  background-color: $AK-50;
}

.bg-color-empty {
  background-color: $AK-100;
}

.bg-color-disabled {
  &.disabled,
  &:disabled {
    pointer-events: none;
  }

  background-color: $color-disabled;
  color: $color-disabled-light;

  .mat-caption {
    color: $color-disabled-light;
  }

  mat-icon {
    color: initial;
    opacity: 0.5;
  }
}

a {
  &.bg-color-disabled {
    background-color: $bg-color-disabled;
    color: $fg-color-disabled;
  }
}

.disable-element {
  pointer-events: none;

  &.mat-mdc-form-field,
  mat-form-field {
    @extend .mat-form-field-disabled !optional;

    mat-chip-list {
      @extend .mat-chip-list-disabled !optional;

      mat-chip {
        @extend .mat-chip-disabled !optional;
      }
    }

    .mat-mdc-form-field-underline {
      background-position: 0;
      background-color: transparent;
    }

    .mat-mdc-input-element {
      color: rgba(0, 0, 0, 0.38);
    }
  }

  button,
  &.mat-mdc-button-base {
    @extend .mat-mdc-button-disabled !optional;
  }

  mat-checkbox,
  &.mat-mdc-checkbox {
    @extend .mat-checkbox-disabled !optional;
  }
}

.fg-disabled-list-text {
  opacity: 0.5;

  &.mat-mdc-list-item {
    .mat-mdc-list-text {
      opacity: 0.5;
    }
  }
}

.bg-others {
  @include others();
}

.bg-color-transparent {
  background-color: transparent;
}

.emptystate {
  height: calc(100vh - 400px);
}

.emptystate-mypage {
  width: 100%;
  text-align: center;
  padding: 400px 0;
}

.empty-state {
  text-align: center;
}

.highlight {
  background-color: transparent;
  font-weight: bold;
}

@keyframes scale-on-hover {
  from {
    transform: scaleX(0);
  }

  to {
    transform: scaleX(1);
  }
}

.bg {
  &.error {
    background-color: scale-color($apex-error, $lightness: 90%);
  }

  &.warning {
    background-color: scale-color($apex-yellow, $lightness: 90%);
  }

  &.new {
    background-color: scale-color($AK-300, $lightness: 90%);
  }

  &.completed {
    background-color: scale-color($apex-green, $lightness: 90%);
  }

  &-hover:hover {
    background-color: $AK-100;
  }

  &-archived {
    background-color: $color-disabled;
  }
}

.row {
  &-error {
    background-color: scale-color($apex-error, $lightness: 90%);
  }

  &-warning {
    background-color: scale-color($apex-yellow, $lightness: 90%);
  }

  &-success {
    background-color: scale-color($apex-green, $lightness: 90%);
  }

  &-new {
    background-color: scale-color($AK-300, $lightness: 90%);
  }

  &-hover:hover {
    background-color: $AK-50;
  }
}

.canceled {
  &:before,
  &:after {
    background-color: mat.get-color-from-palette($warn);
  }
}

.error {
  &:before,
  &:after {
    background-color: $apex-error;
  }
}

.processing,
.warning {
  &:before,
  &:after {
    background-color: $apex-yellow;
  }
}

.primary {
  &:before,
  &:after {
    background-color: $apex-main;
  }
}

.pending {
  &:before,
  &:after {
    background-color: $AK-300;
  }
}

.new {
  &:before,
  &:after {
    background-color: $AK-300;
  }

  &:before {
    left: 0;
  }

  &:after {
    display: none;
  }
}

@mixin fullscreenDialog {
  &.cdk-overlay-pane.mat-mdc-dialog-panel {
    max-width: 100%;
    min-width: 100%;
    width: 100%;
    max-height: 100%;
    min-height: 100%;
    height: 100%;
    mat-dialog-container {
      > * {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;

        .mat-mdc-dialog-content {
          max-height: none;
          flex: 1;
        }
      }
    }
  }
}

@mixin defaultDialog {
  max-width: initial;
  min-width: initial;
  max-height: initial;
  min-height: initial;
  height: initial;

  .mat-mdc-dialog-content {
    max-height: 65vh;
  }
}

.apex-fullscreen-dialog {
  &.tablet,
  &.phone {
    @include defaultDialog();
  }

  &.tablet {
    @include tablet() {
      @include fullscreenDialog();
    }
  }

  &.phone {
    @include phone() {
      @include fullscreenDialog();
    }

    @include phone-landscape() {
      @include fullscreenDialog();
    }
  }
}

.apex-dialog-80 {
  max-width: 80vw;
  min-width: 80vw;
  width: 80vw;
  max-height: 80vh;
  min-height: 80vh;
  height: 80vh;

  mat-dialog-container.mat-mdc-dialog-container {
    > * {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;

      .mat-mdc-dialog-content {
        max-height: none;
        flex: 1;
      }
    }
  }

  @include phone() {
    @include fullscreenDialog();
  }

  @include phone-landscape() {
    @include fullscreenDialog();
  }
}

button.spacing {
  margin: 6px $default-padding-small;
}

.bg-white {
  background: white;
}

.AK-50-bg {
  background-color: $AK-50;
}

.full-width {
  width: 100%;
}

.placeholder-image {
  width: 96px;
  height: 96px;
  background: url('https://app.apexapp.io/assets/no-image.png') center center no-repeat;
  background-size: contain;
}

img.placeholder-image-on-error {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    inset: 0;
    background: $AK-100 url('https://app.apexapp.io/assets/image-placeholder-1-to-1.svg') no-repeat center center;
    background-size: contain;
    aspect-ratio: 1;
  }

  &-banner {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      inset: 0;
      background: $AK-100 url('https://app.apexapp.io/assets/image-placeholder-4-to-1.svg') no-repeat center center;
      background-size: contain;
      aspect-ratio: 4 / 1;
    }
  }
}

.load-image-smooth {
  opacity: 0;
  animation-name: load-image;
  animation-duration: 250ms;
  animation-fill-mode: forwards;
}

@keyframes load-image {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@for $i from 1 through 100 {
  @if (($i % 5 == 0) or ($i == 33) or ($i == 66) or ($i == 99)) {
    .width,
    .w {
      &-#{$i} {
        width: $i * 1%;
      }
    }

    .max-width,
    .maxw {
      &-#{$i} {
        max-width: $i * 1%;
      }
    }

    .min-width,
    .minw {
      &-#{$i} {
        min-width: $i * 1%;
      }
    }
  }
}

.max-width-180px {
  max-width: 180px;
}

.max-width-320px {
  max-width: 320px;
}

.max-width-640px {
  max-width: 640px;
}

.max-width-fit-content,
.mwfc {
  max-width: fit-content;
}

@for $i from 1 through 100 {
  @if (($i % 5 == 0) or ($i == 33) or ($i == 66) or ($i == 99)) {
    .height,
    .h {
      &-#{$i} {
        height: $i * 1%;
      }
    }

    .max-height,
    .maxh {
      &-#{$i} {
        max-height: $i * 1%;
      }
    }

    .min-height,
    .minh {
      &-#{$i} {
        min-height: $i * 1%;
      }
    }
  }
}

.visibility-hidden,
.vh {
  visibility: hidden;
}

.display-none,
.dn {
  display: none;
}

.display-flex,
.df {
  display: flex;
}

.display-flex-inline,
.dfi {
  display: inline-flex;
}

.column,
.c {
  flex-direction: column;
}

.row,
.r {
  flex-direction: row;
}

.wrap,
.w {
  flex-wrap: wrap;
}

.nowrap,
.nw {
  flex-wrap: nowrap;
}

$justify: (
  'fs': 'flex-start',
  'fe': 'flex-end',
  'c': 'center',
  'sa': 'space-around',
  'sb': 'space-between',
  'se': 'space-evenly',
);
$align: (
  'fs': 'flex-start',
  'fe': 'flex-end',
  'c': 'center',
  'b': 'baseline',
  's': 'stretch',
);

@each $name, $value in $justify {
  .j#{$name},
  .justify-#{$value} {
    justify-content: #{$value};
  }
}

@each $name, $value in $align {
  .a#{$name},
  .align-#{$value} {
    align-items: #{$value};
  }

  .as#{$name},
  .align-self-#{$value} {
    align-self: #{$value};
  }

  .ac#{$name},
  .align-content-#{$value} {
    align-content: #{$value};
  }
}

.fdot5.fdot5.fdot5 {
  flex: 0.5;
}

@for $i from 0 to 15 {
  .f#{$i}.f#{$i}.f#{$i},
  .flex-#{$i}.flex-#{$i}.flex-#{$i},
  .flex#{$i}.flex#{$i}.flex#{$i} {
    flex: $i;
  }
}

.level0 {
  h3 {
    @include mat.typography-level($apex-typography, headline-6);
  }
}

.level1 {
  h3 {
    @include mat.typography-level($apex-typography, subtitle-1);
  }
}

.level2 {
  h3 {
    @include mat.typography-level($apex-typography, subtitle-2);
  }
}

.level3 {
  h3 {
    @include mat.typography-level($apex-typography, body-1);
  }
}

.disable-focus {
  &:focus {
    outline: none;
  }
}

.hide {
  display: none;
}

.show {
  display: block;
}

.hide-overflow,
.ho {
  overflow: hidden;
}

.flip {
  transform: rotateY(180deg);
}

.rotate-45 {
  transform: rotate(45deg);
}

.rotate-180 {
  transform: rotate(180deg);
}

// make sure that you can see all elements even if fab button is present
.list-container {
  padding-bottom: 80px;
}

.steppable {
  .steps {
    background-repeat: no-repeat;
    background-image: linear-gradient($AT-100, $AT-100), linear-gradient($AT-200, $AT-200),
      linear-gradient($AT-300, $AT-300), linear-gradient($AT-400, $AT-400), linear-gradient($AT-500, $AT-500),
      linear-gradient($AT-600, $AT-600), linear-gradient($AT-700, $AT-700), linear-gradient($AT-800, $AT-800),
      linear-gradient($AT-900, $AT-900);
    background-size: 0;
  }

  @for $i from 2 through 20 {
    .steps {
      &-#{$i} {
        padding-left: ($default-padding-small * ($i - 1));
        @if ($i == 2) {
          background-size: 8px 100%;
        } @else if ($i == 3) {
          background-size:
            8px 100%,
            16px 100%;
        } @else if ($i == 4) {
          background-size:
            8px 100%,
            16px 100%,
            24px 100%;
        } @else if ($i == 5) {
          background-size:
            8px 100%,
            16px 100%,
            24px 100%,
            32px 100%;
        } @else if ($i == 6) {
          background-size:
            8px 100%,
            16px 100%,
            24px 100%,
            32px 100%,
            40px 100%;
        } @else if ($i == 7) {
          background-size:
            8px 100%,
            16px 100%,
            24px 100%,
            32px 100%,
            40px 100%,
            48px 100%;
        } @else if ($i == 8) {
          background-size:
            8px 100%,
            16px 100%,
            24px 100%,
            32px 100%,
            40px 100%,
            48px 100%,
            56px 100%;
        } @else if ($i == 9) {
          background-size:
            8px 100%,
            16px 100%,
            24px 100%,
            32px 100%,
            40px 100%,
            48px 100%,
            56px 100%,
            64px 100%;
        } @else if ($i == 10) {
          background-size:
            8px 100%,
            16px 100%,
            24px 100%,
            32px 100%,
            40px 100%,
            48px 100%,
            56px 100%,
            64px 100%,
            72px 100%;
        } @else {
          background-size:
            8px 100%,
            16px 100%,
            24px 100%,
            32px 100%,
            40px 100%,
            48px 100%,
            56px 100%,
            64px 100%,
            ($i - 1) * 8px 100%;
        }
      }
    }
  }
}

/* scrollable */
table.apex-mobile {
  display: table !important;
}

table.apex {
  display: flex;
  width: 100%;

  > *,
  > * > *,
  > * > * > * {
    /* many hungry hippos */
    display: flex;
    flex: 1;
  }

  flex-direction: column;

  color: rgba(0, 0, 0, 0.87);

  $border-bottom-color: rgba(0, 0, 0, 0.12);

  thead,
  tfoot {
    color: rgba(0, 0, 0, 0.54);

    font-size: mat.font-size($apex-typography, caption);
    font-weight: 600;

    border-bottom: 1px solid $border-bottom-color;
  }

  thead {
    background-color: $background-white;

    &.sticky {
      position: sticky;
      top: 0;
      z-index: 1;
    }
  }

  tbody {
    flex-direction: column;

    tr {
      border-bottom: 1px solid $border-bottom-color;

      td {
        > label:first-child {
          display: none;
          @include mat.typography-level($apex-typography, caption);
        }
      }
    }
  }

  tr > th:first-child,
  tr > td:first-child {
    padding-left: 24px;
  }

  tr > th:last-child,
  tr > td:last-child {
    padding-right: 24px;
  }

  tr {
    min-height: 48px;
  }

  td,
  th {
    align-items: center;
  }

  @mixin tableMedia {
    @include mat.elevation(0);
    margin: 0;

    thead {
      display: none;
    }

    tbody {
      flex-direction: row;
      flex-wrap: wrap;

      tr {
        &:hover {
          @include mat.elevation(4);
          @include mat.elevation-transition;
        }

        @include mat.elevation(2);
        margin-bottom: $default-padding;
        flex-direction: column;

        td {
          &:first-child {
            padding-left: unset;
            margin-top: $default-padding;
          }

          > label:first-child {
            display: block;
          }

          margin-bottom: $default-padding;
          margin-left: $default-padding;
          align-items: flex-start;

          display: flex;
          flex-direction: column;
        }
      }
    }
  }

  &.phone {
    @include phone() {
      @include tableMedia();

      tbody {
        tr {
          min-width: 100%;
        }
      }
    }
  }

  &.tablet {
    @include tablet() {
      @include tableMedia();

      tbody {
        justify-content: space-between;

        tr {
          max-width: calc(50% - #{$default-padding-small});
          min-width: calc(50% - #{$default-padding-small});
        }
      }
    }
  }
}

.mat-mdc-menu-panel.apex-max-size-menu {
  max-width: 100%;
}

.pointer {
  cursor: pointer;
}

.required:after {
  content: ' *';
}

.font {
  &-light {
    font-weight: 300;
  }

  &-regular {
    font-weight: 400;
  }

  &-semi-bold {
    font-weight: 600;
  }
}

.strong,
.font-strong {
  font-weight: 600;
}

.apex-table {
  width: 100%;

  &.mat-mdc-table {
    border-spacing: 0;
  }

  tr.mat-mdc-header-row {
    height: 56px;
  }

  th.mat-mdc-header-cell,
  td.mat-mdc-cell,
  td.mat-mdc-footer-cell {
    border-bottom-width: 1px;
    border-bottom-style: solid;
  }

  th.mat-mdc-header-cell:last-of-type,
  td.mat-mdc-cell:last-of-type,
  td.mat-mdc-footer-cell:last-of-type {
    padding-right: 24px;
  }

  tr.mat-mdc-row,
  tr.mat-mdc-footer-row {
    height: 48px;
  }

  th.mat-mdc-header-cell:first-of-type,
  td.mat-mdc-cell:first-of-type,
  td.mat-mdc-footer-cell:first-of-type {
    padding-left: 24px;
  }

  tr:hover {
    background-color: $AK-50;
  }
}

.scrollable-horizontal {
  overflow-x: auto;
}

.scrollable-vertical {
  overflow-y: auto;
}

.scrollable {
  overflow: auto;
}

.set-height {
  height: var(--height);
}

.set-min-height {
  min-height: var(--min-height);
}

.set-max-height {
  max-height: var(--max-height);
}

.set-width {
  width: var(--width);
}

.set-min-width {
  min-width: var(--min-width);
}

.set-max-width {
  max-width: var(--max-width);
}

.remove-list-style,
.rls {
  list-style: none;
}

$utils: (
  '': (
    '': $default-padding,
  ),
  'vertical': (
    'top': $default-padding,
    'bottom': $default-padding,
  ),
  'horizontal': (
    'left': $default-padding,
    'right': $default-padding,
  ),
  'top': (
    'top': $default-padding,
  ),
  'right': (
    'right': $default-padding,
  ),
  'bottom': (
    'bottom': $default-padding,
  ),
  'left': (
    'left': $default-padding,
  ),
);

$actions: (
  'add': 'a',
  'remove': 'r',
);

$props: (
  'padding': 'p',
  'margin': 'm',
);

@each $action, $aAbbr in $actions {
  @each $prop, $pAbbr in $props {
    @each $direction, $propDirections in $utils {
      $name: #{$action}-#{$prop};
      $abbr: #{$aAbbr}#{$pAbbr};

      @if (#{$direction} != '') {
        $name: #{$name}-#{$direction};
        $abbr: #{$abbr}#{str-slice(#{$direction}, 1, 1)};
      }

      .#{$name},
      .#{$abbr} {
        @each $propDirection, $value in $propDirections {
          $propName: #{$prop};

          @if (#{$action} == 'remove') {
            $value: 0;
          }

          @if (#{$propDirection} != '') {
            $propName: #{$propName}-#{$propDirection};
          }

          #{$propName}: #{$value};
        }
      }

      .#{$name}-important,
      .#{$abbr}i {
        @each $propDirection, $value in $propDirections {
          $propName: #{$prop};

          @if (#{$action} == 'remove') {
            $value: 0;
          }

          @if (#{$propDirection} != '') {
            $propName: #{$propName}-#{$propDirection};
          }

          #{$propName}: #{$value} !important;
        }
      }

      @if ($action == 'add') {
        .#{$name}-#{small},
        .#{$abbr}#{s} {
          @each $propDirection, $value in $propDirections {
            $value: $default-padding-small;
            $propName: #{$prop};

            @if (#{$propDirection} != '') {
              $propName: #{$propName}-#{$propDirection};
            }

            #{$propName}: #{$value};
          }
        }

        .#{$name}-#{extra-small},
        .#{$abbr}#{x}#{s} {
          @each $propDirection, $value in $propDirections {
            $value: $default-padding-extra-small;
            $propName: #{$prop};

            @if (#{$propDirection} != '') {
              $propName: #{$propName}-#{$propDirection};
            }

            #{$propName}: #{$value};
          }
        }
      }

      @if ($action == 'add') {
        .#{$name}-#{small}-important,
        .#{$abbr}#{s}i {
          @each $propDirection, $value in $propDirections {
            $value: $default-padding-small;
            $propName: #{$prop};

            @if (#{$propDirection} != '') {
              $propName: #{$propName}-#{$propDirection};
            }

            #{$propName}: #{$value} !important;
          }
        }

        .#{$name}-#{extra-small}-important,
        .#{$abbr}#{x}#{s}i {
          @each $propDirection, $value in $propDirections {
            $value: $default-padding-extra-small;
            $propName: #{$prop};

            @if (#{$propDirection} != '') {
              $propName: #{$propName}-#{$propDirection};
            }

            #{$propName}: #{$value} !important;
          }
        }
      }
    }
  }
}

@for $i from 0 through 10 {
  $value: math.div($i, 10);
  $classValue: 1;

  @if ($i == 0) {
    $classValue: 0;
  } @else if ($i == 10) {
    $classValue: 1;
  } @else {
    $classValue: '0' + $i;
  }

  .opacity,
  .op {
    &-#{$classValue} {
      opacity: $value;
    }
  }
}

.pointer-events-disable,
.ped {
  pointer-events: none;
}

.pointer-events-all,
.pea {
  pointer-events: all;
}

.animate-rotate {
  animation: rotate 2s ease;
  animation-iteration-count: infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

button:disabled {
  .warning-color {
    color: scale-color(mat.get-color-from-palette($warn), $lightness: 33%);
  }
}

.rtl {
  direction: rtl;
}

.ltr {
  direction: ltr;
}

.color-box {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 24px;
  height: 24px;

  box-sizing: border-box;

  border-radius: 50%;

  &.is-weak {
    border: 1px solid $AK-200;
  }

  input {
    visibility: hidden;
  }
}

.color-circles {
  width: 274px;

  box-sizing: border-box;

  .color-circle {
    width: 22px;
    height: 22px;
    margin: 1px;

    box-sizing: border-box;

    border-radius: 50%;

    &.is-weak {
      border: 1px solid $AK-200;
    }
  }
}

.messages {
  .message {
    margin: 1rem 0;

    .icon {
      .md-avatar-icon {
        background-color: rgba(0, 0, 0, 0.25);
        box-sizing: initial;
        color: #fff;
        padding: 8px;
        border-radius: 50%;
      }
    }

    $border-color: #d2d4d8;

    .text {
      position: relative;

      .meta,
      .markdown,
      translator {
        padding: 0.25rem 0.75rem;
      }

      .markdown {
        padding: 0.75rem;
      }

      .meta {
        background: #f9f8f7;
        border-bottom: 1px solid #eee;

        .user {
          margin-right: 0.5rem;
        }
      }

      .role {
        padding: 0.33rem 0.5rem;
        border: 1px solid #ddd;
        border-radius: 3px;
      }

      &::before,
      &::after {
        position: absolute;
        top: 10px;
        right: 100%;
        left: -16px;
        display: block;
        width: 0;
        height: 0;
        pointer-events: none;
        content: '\A';
        border-color: transparent;
        border-style: solid solid outset;
      }

      &::before {
        border-width: 8px;
        border-right-color: $border-color;
      }

      &::after {
        margin-top: 1px;
        margin-left: 2px;
        border-width: 7px;
        border-right-color: #f6f8fa;
      }
    }

    &.casemanager {
      .icon {
        .md-avatar-icon {
          background-color: $casemanager-color;
        }
      }
    }

    &.client {
      .icon {
        .md-avatar-icon {
          background-color: $client-color;
        }
      }
    }

    &.contractor {
      .icon {
        .md-avatar-icon {
          background-color: $contractor-color;
        }
      }
    }

    &.contractor {
      .icon {
        margin: 0;
      }
    }

    .system {
      md-icon {
        color: #666;
        margin-right: 1rem;
      }
    }

    &.error {
      md-icon {
        color: $apex-error;
      }
    }
  }

  .icons {
    md-icon {
      width: 18px;
      height: 18px;

      cursor: pointer;

      outline: none;

      &.grey {
        svg {
          path {
            fill: #bfbfbf;
          }
        }
      }
    }
  }

  .btn-view-email {
    display: none;
  }
}

.maps-label {
  text-shadow: 1px 1px 1px $AK-900;
}

.text-italic {
  font-style: italic;
}

.fg {
  &-warning {
    color: $apex-yellow;
  }

  &-error {
    color: $apex-error;
  }

  &-disabled {
    color: $color-disabled;
  }

  &-gray {
    color: $apex-gray;
  }

  &-primary {
    color: $apex-main;
  }

  &-light-text {
    color: $primary-foreground;
  }
}

.loading-screen {
  opacity: 0.5;
  pointer-events: none;
}

.mat-caption {
  color: $AK-700;

  &.green-background {
    color: $AK-200;
  }
}

.clickable {
  cursor: pointer;

  &.apex-user-card {
    color: $link-color;
  }
}

.transition {
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.sticky {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 1000;

  &.top-0 {
    top: 0;
  }

  &.top-48 {
    top: 48px;
  }

  &.top-56 {
    top: 56px;
  }
}

.display-grid {
  display: grid;
  gap: $default-padding;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));

  &.inline {
    display: inline-grid;
  }

  &.small {
    gap: $default-padding-small;
  }

  &.one-column {
    grid-template-columns: 1fr;
  }

  &.two-column {
    grid-template-columns: 1fr 1fr;
  }

  &.three-column {
    grid-template-columns: 1fr 1fr 1fr;
  }

  &.four-column {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  &.auto-flow-dense {
    grid-auto-flow: dense;
  }

  &.auto-flow-column {
    grid-auto-flow: column;
    grid-template-columns: unset;
  }

  .grid-colum-1 {
    grid-column: 1;
  }

  .grid-colum-2 {
    grid-column: 2;
  }

  .grid-colum-3 {
    grid-column: 3;
  }

  .grid-colum-4 {
    grid-column: 4;
  }

  &.phone-one-column {
    @include phone {
      grid-template-columns: 1fr;
    }
  }
}

.display-grid-cards {
  display: grid;
  gap: $default-padding;

  --auto-value: auto-fill;

  &.dense {
    gap: $default-padding-small;

    .mat-mdc-card {
      padding: $default-padding-small;
      margin: 0;
    }
  }

  &.auto-fit {
    --auto-value: auto-fit;
  }

  &.align-items-start {
    align-items: flex-start;
  }

  &.align-items-center {
    align-items: center;
  }

  &.auto-flow-dense {
    grid-auto-flow: dense;
  }

  &.auto-flow-column {
    grid-auto-flow: column;
    grid-template-columns: unset;
  }

  grid-template-columns: repeat(var(--auto-value, auto-fill), minmax(min(300px, 100%), 1fr));
  x &.align-start {
    align-items: flex-start;
  }

  &.card-width-100 {
    grid-template-columns: repeat(var(--auto-value, auto-fill), minmax(min(100px, 100%), 1fr));
  }

  &.card-width-150 {
    grid-template-columns: repeat(var(--auto-value, auto-fill), minmax(min(150px, 100%), 1fr));
  }

  &.card-width-200 {
    grid-template-columns: repeat(var(--auto-value, auto-fill), minmax(min(200px, 100%), 1fr));
  }

  &.card-width-250 {
    grid-template-columns: repeat(var(--auto-value, auto-fill), minmax(min(250px, 100%), 1fr));
  }

  &.card-width-350 {
    grid-template-columns: repeat(var(--auto-value, auto-fill), minmax(min(350px, 100%), 1fr));
  }

  &.card-width-400 {
    grid-template-columns: repeat(var(--auto-value, auto-fill), minmax(min(400px, 100%), 1fr));
  }

  &.card-width-450 {
    grid-template-columns: repeat(var(--auto-value, auto-fill), minmax(min(450px, 100%), 1fr));
  }

  &.card-width-500 {
    grid-template-columns: repeat(var(--auto-value, auto-fill), minmax(min(500px, 100%), 1fr));
  }

  &.card-width-550 {
    grid-template-columns: repeat(var(--auto-value, auto-fill), minmax(min(550px, 100%), 1fr));
  }

  &.card-width-600 {
    grid-template-columns: repeat(var(--auto-value, auto-fill), minmax(min(600px, 100%), 1fr));
  }

  @include phone() {
    &.phone-card-width-100 {
      grid-template-columns: repeat(auto-fill, minmax(min(100px, 100%), 1fr));
    }
  }
}

.bg-save {
  position: relative;

  &:before {
    content: '';
    opacity: 0;
    transition: all 0.4s ease-in-out;
    background-color: $apex-green;
    position: absolute;
    inset: 0;
  }

  &.saved {
    &:before {
      opacity: 0.1;
    }
  }
}

.selectable {
  @include mat.elevation-transition();
  will-change: box-shadow;

  &.selected {
    @include mat.elevation(3, $apex-main, 0.25);

    background-color: scale-color($apex-main, $lightness: 90%);

    &-error {
      @include mat.elevation(3, $apex-error);
    }
  }

  &.selected-border-inset {
    box-shadow: inset 0 0 0 2px $color-selected;
  }

  &.selected-border {
    box-shadow: 0 0 0 2px $color-selected;
  }
}

.selectable-flat {
  will-change: background-color;

  &.selected {
    background-color: scale-color($apex-main, $lightness: 90%);
  }
}

form.bg-selected-primary-50 {
  background-color: $AT-50;
}

.fsmt,
.font-size-mat-caption {
  font-size: mat.font-size($apex-typography, caption);
}

.fsd3,
.font-size-display-3 {
  font-size: mat.font-size($apex-typography, headline-2);
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.inset {
  &-0 {
    inset: 0;
  }
}

.top {
  &-0 {
    top: 0;
  }

  &-negative-1 {
    top: -1px;
  }
}

.right {
  &-0 {
    right: 0;
  }
}

.bottom {
  &-0 {
    bottom: 0;
  }

  &-negative-1 {
    top: -1px;
  }

  &-default {
    bottom: $default-padding;
  }

  &-small {
    bottom: $default-padding-small;
  }

  &-extra-small {
    bottom: $default-padding-extra-small;
  }
}

.left {
  &-0 {
    left: 0;
  }
}

.drop-area {
  will-change: all;
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);

  &.apex-drag-over {
    color: $primary-foreground;
    background-color: rgba($AK-900, 0.5);
  }
}

.map-tooltip {
  background: rgba(97, 97, 97, 0.9);
  color: $primary-foreground;
  border-radius: 4px;
  margin: 0 14px 14px;
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-line;

  font-size: 10px;
  padding: 6px 8px;

  will-change: opacity;
  transition: opacity 200ms cubic-bezier(0.35, 0, 0.25, 1);
}

.error-border {
  border: 2px solid $apex-error;
}

.error-outline {
  outline: 2px solid $apex-error;
}

.add-margin-auto,
.ama {
  margin: auto;
}

.overscroll-contain {
  overscroll-behavior: contain;
}

.inspection-root {
  // apex-case-filter {
  //   .inspection-disable {
  //     @extend .disable-element;
  //   }
  // }

  @extend .overscroll-contain;
}

.yellow-info-card {
  background-color: $AY-100;
  border: 1px solid $AY-300;
}

.upload-icon.upload-icon.upload-icon {
  font-size: $drag-drop-icon-size;
}

.upload-icon-small.upload-icon-small.upload-icon-small {
  font-size: $drag-drop-icon-size-small;
}

.object-fit {
  &-cover {
    object-fit: cover;
  }

  &-contain {
    object-fit: contain;
  }
}

.mat-mdc-fab.mat-fab-extended {
  width: unset;
  padding: 0 16px;
  border-radius: $default-padding;

  &.left {
    left: 1rem;
    right: unset;
  }

  .mat-mdc-icon {
    margin-right: $default-padding-small;
  }
}

.gap {
  gap: $default-padding;

  &-small {
    gap: $default-padding-small;
  }

  &-extra-small {
    gap: $default-padding-extra-small;
  }
}

.gap-horizontal {
  column-gap: $default-padding;

  &-small {
    column-gap: $default-padding-small;
  }

  &-extra-small {
    column-gap: $default-padding-extra-small;
  }
}

.gap-vertical {
  row-gap: $default-padding;

  &-small {
    row-gap: $default-padding-small;
  }

  &-extra-small {
    row-gap: $default-padding-extra-small;
  }
}

.disable-user-drag {
  user-select: none;
  -webkit-user-drag: none;
}

.disable-user-drag-on-children {
  * {
    user-select: none;
    -webkit-user-drag: none;
  }
}

// cdk goes outside parent DOM
.cdk-drag-preview {
  &.usage {
    background: #fff;

    .mat-mdc-list-item-content {
      display: flex;
      flex-direction: row;
      align-items: center;
      box-sizing: border-box;
      padding: 0 $default-padding;
      flex: 1;

      .mat-mdc-list-text {
        width: 100%;
        padding-left: $default-padding;

        p {
          margin: 0;
        }
      }

      apex-file-preview {
        flex-shrink: 0;
        width: initial;
        height: 40px;
        border-radius: 50%;
        object-fit: cover;

        .file-preview {
          img {
            width: 32px;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        .file-download {
          position: initial;
          margin-left: $default-padding;
        }
      }
    }
  }

  .hide-on-drag {
    display: none;
  }
}

.pintura-editor {
  --color-background: 255, 255, 255;
  --color-foreground: 0, 0, 0;

  --border-radius-round: 0em;
  --border-radius: 0em;

  --color-primary: #00919d;
  --color-primary-dark: #00636f;
  --color-primary-text: #fff;
  --color-secondary: #7a7a7d;
  --color-secondary-dark: #515155;
}

.empty {
  opacity: 0.5;
}

.aspect-ratio-1 {
  aspect-ratio: 1;
}

.aspect-ratio-2-1 {
  aspect-ratio: 2 / 1;
}

.aspect-ratio-3-2 {
  aspect-ratio: 3 / 2;
}

.aspect-ratio-4-1 {
  aspect-ratio: 4 / 1;
}

.grid-auto-rows-1fr {
  grid-auto-rows: 1fr;
}

@keyframes dotFlashing {
  0% {
    background-color: $AK-900;
  }
  50%,
  100% {
    background-color: $AK-100;
  }
}

.dot-flashing {
  position: relative;
  width: 4px;
  height: 4px;
  border-radius: 2px;
  background-color: $AK-900;
  color: $AK-900;
  animation: dotFlashing 1s infinite ease alternate;
  animation-delay: 0.5s;
  margin-left: $default-padding-small;
  transform: translateY(1px);

  &::before,
  &::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    width: 4px;
    height: 4px;
    border-radius: 2px;
    background-color: $AK-900;
    color: $AK-900;
    animation: dotFlashing 1s infinite ease alternate;
  }

  &::before {
    left: -6px;
    animation-delay: 0s;
  }

  &::after {
    left: 6px;
    animation-delay: 1s;
  }
}

.overflow-visible {
  overflow: visible !important;
}

.min-width {
  &-128 {
    min-width: 128px;
  }
}

.min-height {
  &-48 {
    min-height: 48px;
  }
  &-64 {
    min-height: 64px;
  }

  &-80 {
    min-height: 80px;
  }

  &-96 {
    min-height: 96px;
  }

  &-112 {
    min-height: 112px;
  }

  &-128 {
    min-height: 128px;
  }

  &-144 {
    min-height: 144px;
  }

  &-160 {
    min-height: 160px;
  }

  &-250 {
    min-height: 250px;
  }

  &-400 {
    min-height: 400px;
  }

  &-600 {
    min-height: 600px;
  }
}

.height {
  &-225 {
    height: 225px;
  }

  &-300 {
    height: 300px;
  }

  &-456 {
    height: 456px;
  }

  &-500 {
    height: 500px;
  }

  &-692 {
    height: 692px;
  }

  &-928 {
    height: 928px;
  }
}

.phone-min-height-150 {
  @include phone {
    min-height: 150px;
  }
}

.tablet-max-height-150 {
  @include tablet {
    max-height: 150px;
  }
}

.font-size {
  &-34 {
    font-size: 34px;
  }

  &-45 {
    font-size: 45px;
  }

  &-56 {
    font-size: 56px;
  }

  &-112 {
    font-size: 112px;
  }
}

.line-height {
  &-1 {
    line-height: 1;
  }
}

.status {
  &.list-item {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      width: $default-padding-small;
      height: 100%;
      left: 0;
      background-color: #7a7a7d;
    }

    &.normal {
      &:before {
        background-color: inherit;
      }
    }

    &.warning {
      &:before {
        background-color: $AY-200;
      }
    }

    &.error {
      &:before {
        background-color: $AR-200;
      }
    }
  }
}

.remove-padding-important,
.rpi {
  padding: 0px !important;
}

.add-margin-horizontal-auto,
.amha {
  margin-left: auto;
  margin-right: auto;
}

.add-margin-vertical-auto,
.amva {
  margin-top: auto;
  margin-bottom: auto;
}

.toggle-button-green-background,
.tbgb {
  background-color: $AT-700;
  color: $AK-50 !important;
}

.gps-toggle-button-color-black {
  color: $AK-900;
}

.text-decoration-underline,
.tdl {
  text-decoration: underline;
}

.page-break-inside-avoid {
  page-break-inside: avoid;
}

.page-break-before-always {
  page-break-before: always;
}

.page-break-after-always {
  page-break-after: always;
}

.view-width {
  @for $i from 1 through 100 {
    &-#{$i} {
      width: #{$i}vw;
    }
  }
}

.view-height {
  @for $i from 1 through 100 {
    &-#{$i} {
      height: #{$i}vh;
    }
  }
}

.view-height-99-point-9 {
  height: 99.9vh;
}

.text-white {
  color: mat.get-color-from-palette($apex-secondary-palette, 50);
}

.print-margin {
  margin-left: $default-margin-small;
  margin-right: $default-margin-small;
}

.display-grid-snap {
  height: inherit;
  margin: 0;
  display: grid;
  grid-column-gap: $default-margin-small;
  grid-template-columns: repeat(auto-fill, minmax(80%, 1fr));
  grid-auto-columns: minmax(80%, 1fr);
  grid-auto-flow: column;
  overflow-y: hidden;
  overflow-x: auto;
  overscroll-behavior-x: contain;
  scroll-snap-type: x mandatory;

  @media only screen and (min-width: 701px) {
    grid-template-columns: repeat(auto-fill, minmax(40%, 1fr));
    grid-auto-columns: minmax(40%, 1fr);
  }

  @media only screen and (min-width: 1101px) {
    grid-template-columns: repeat(auto-fill, minmax(28%, 1fr));
    grid-auto-columns: minmax(28%, 1fr);
  }

  @media only screen and (min-width: 1401px) {
    grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
    grid-auto-columns: minmax(20%, 1fr);
  }
}

.display-grid-snap > * {
  scroll-snap-align: start;
  scroll-margin-inline-start: $default-margin-small;
}

.touch-action-auto-important {
  touch-action: auto !important;
}

.markdown-content {
  ol,
  ul {
    padding: $default-padding;
  }
}

align-self-center {
  align-self: center;
}

overflow-y-auto {
  overflow-y: auto;
}

.apex-staging {
  border: 3px solid $AP-800;
}

.line-break-anywhere {
  line-break: anywhere;
}

.white-space-collapse-preserve {
  white-space-collapse: preserve;
}

.white-space-collapse-preserve-spaces {
  white-space-collapse: preserve-spaces;
}

.overscroll-contain-none {
  overscroll-behavior: none;
}
