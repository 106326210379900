@use '@angular/material' as mat;
//material overrides

@use './variables.scss' as *;
@use './classes.scss' as *;
@use './theming.scss' as *;
@use './functions.scss' as *;

.no-caps {
  &.mat-mdc-button,
  &.mat-mdc-raised-button {
    text-transform: none !important;
  }
}

.mat-mdc-fab {
  background-color: $AT-700;

  .mat-mdc-icon {
    color: #fff !important;
  }
}

a.mat-mdc-button.mat-primary {
  color: $link-color;
}

.mat-mdc-checkbox-inner-container {
  width: 20px !important;
  height: 20px !important;
}

apex-root,
portal-root {
  // For being spesific
  .mat-mdc-card {
    margin: $default-padding-small;
    border-radius: 0;

    &.df,
    &.display-flex {
      display: flex;
    }

    &.remove-margin {
      margin: 0;
    }

    &[ng-reflect-router-link],
    &[href] {
      cursor: pointer;
    }

    .last-child {
      margin-bottom: 0;
    }

    button:disabled {
      .mat-mdc-icon {
        color: rgba(0, 0, 0, 0.38);
      }
    }
  }
}

.mat-ripple:not(.disabled):hover {
  cursor: pointer;
}

.mat-mdc-tooltip {
  white-space: pre-line;
}

.mat-fab-component-padding {
  padding-bottom: 96px;
}

a.mat-mdc-fab,
button.mat-fab,
.fab-button {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  background-color: $fab-color;
  z-index: 2;

  @include phone() {
    right: 8px;
    bottom: 8px;
    margin: 0;
    padding: 0;
  }
}

a.mat-mdc-fab,
button.mat-mdc-fab,
.fab-button {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  background-color: $fab-color;
  z-index: 2;

  @include phone() {
    right: 8px;
    bottom: 8px;
    margin: 0;
    padding: 0;
  }
}

.toolbar,
.md-subheader {
  padding: $default-padding-small $default-padding;
  background: $apex-main-variant;
  color: $primary-foreground;

  &.md-subheader {
    padding: initial;

    .md-subheader-inner {
      padding-right: 0;
    }
  }

  .actions {
    .mat-mdc-icon-button[disabled][disabled] {
      color: rgba($light-foreground, 0.26);
    }
  }

  editor & {
    background-color: whitesmoke;
  }
}

md-toolbar,
.md-toolbar-tools {
  height: 56px !important;
  min-height: 56px !important;
}

.cdk-overlay-container {
  overflow: hidden;

  .mat-mdc-autocomplete-panel {
    min-width: 250px;
    max-width: 100vw;
  }
}

#toast-container > div {
  opacity: 1;
}

md-icon,
mat-icon {
  svg.fill-current-color {
    path {
      fill: currentColor;
    }
  }
}

mat-icon.mat-icon {
  &[svgIcon='project'] {
    color: $project-color;
  }

  &[svgIcon='apartment'] {
    color: $apartment-color;
  }
}

mat-option.mat-option {
  button {
    mat-icon.mat-icon {
      margin-right: 0;
    }
  }
}

label.mat-mdc-checkbox-layout {
  align-items: center;
  white-space: normal;
}

mat-chip-grid {
  mat-chip.mat-chip.mat-chip {
    border-radius: 0;
    background-color: $AT-50;
    @include mat.elevation(1);
    margin-top: 2px;
    margin-bottom: 4px;
    padding: 4px 4px 4px 8px;
    min-height: unset;
    height: unset;
  }

  mat-icon {
    position: relative;
    top: 1px;
  }
}

.mat-mdc-dialog-content {
  padding: $default-padding !important;
}

mat-dialog-container {
  &.mat-mdc-dialog-container {
    min-width: 75vw;

    .mdc-dialog__surface {
      background-color: $AK-50;
    }

    .mat-mdc-dialog-content {
      &.df {
        display: flex;
      }

      &.no-padding {
        padding: 0;
      }
    }

    .mat-mdc-dialog-content,
    .mat-mdc-dialog-actions {
      padding: $default-padding;
      margin: 0;
    }

    .mat-mdc-dialog-title {
      display: flex;
      align-items: center;

      //apex toolbar height
      height: 56px;
      min-height: 56px;
      margin: 0;

      padding: 0 $default-padding;

      color: $primary-foreground;
      background-color: $apex-main-variant;

      @include mat.typography-level($apex-typography, headline-6);
      line-height: 1;
    }

    .mat-mdc-dialog-actions {
      position: relative;
      min-height: unset;
      border-top: 1px solid $border-color;

      // This was in old files and set both.
      justify-content: space-between;

      mat-progress-bar {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }
  }
}

.mat-mdc-menu-panel {
  border-radius: 0;

  &.apex-remove-padding-menu {
    .mat-mdc-menu-content:not(:empty) {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

.cdk-drag:not(.cdk-drag-disabled) {
  .cdk-drag-handle {
    cursor: pointer;
  }
}

div.panel {
  border-radius: 0;
}

div.mat-mdc-menu-panel {
  border-radius: 0;
}

div.mdc-mat-autocomplete-panel {
  border-radius: 0;
}

div.mat-mdc-select-panel {
  border-radius: 0;
}

mat-datepicker-content.mat-datepicker-content {
  border-radius: 0;
}

.mat-elevation-z1,
.mat-elevation-z2,
.mat-elevation-z3,
.mat-elevation-z4,
.mat-elevation-z5,
.mat-elevation-z6,
.mat-elevation-z7,
.mat-elevation-z8,
.mat-elevation-z9,
.mat-elevation-z10 {
  background-color: $AK-50;
}

html,
body {
  background-color: $AK-100 !important;
}

mat-option.autocomplete-option {
  line-height: unset;
  height: unset;

  padding: $default-padding-small;

  .mat-caption {
    color: $AK-600;
  }
}

apex-case-form {
  .mat-accordion .mat-expansion-panel:first-of-type,
  .mat-accordion .mat-expansion-panel:first-of-type {
    border-radius: unset;
  }
}

.mat-accordion mat-expansion-panel.mat-expansion-panel,
mat-expansion-panel.mat-expansion-panel {
  border-radius: unset;
  background-color: $AK-50;

  &:first-of-type,
  &:last-of-type {
    border-radius: unset;
  }

  &:not(.mat-expanded) .mat-expansion-panel-header {
    &:hover:not([aria-disabled='true']),
    &:focus:not([aria-disabled='true']) {
      &.bg-color-main {
        background-color: $apex-main-variant;
      }

      &.bg-color-warn {
        background-color: mat.get-color-from-palette($warn);
      }

      &.bg-color-primary-400 {
        background-color: $AT-400;
      }

      &.bg-color-empty {
        background-color: $AK-100;
      }

      &.bg-color-disabled {
        background-color: $color-disabled;
      }
    }

    a:hover {
      color: $primary-foreground;
    }
  }

  mat-expansion-panel-header.mat-expansion-panel-header {
    padding: 0 ($default-padding + $default-padding-small) 0 $default-padding;

    &:not(.mat-expanded) {
      border-bottom: 1px solid $border-color;
    }

    &.mat-expanded {
      height: $toolbar-height;
    }

    .mat-expansion-indicator {
      display: flex;
      margin-left: $default-padding-small;
    }

    &.bg-color-main,
    &.bg-color-warn,
    &.bg-color-disabled {
      color: $primary-foreground;

      .mat-expansion-indicator:after,
      .mat-expansion-panel-header-title {
        color: $primary-foreground;
      }

      .mat-caption {
        color: rgba($primary-foreground, 0.9);
      }

      .mdc-icon-button-disabled {
        color: rgba($primary-foreground, 0.26);
      }

      .mdc-icon-button[disabled][disabled] {
        color: rgba($primary-foreground, 0.26);
      }
    }

    &.bg-color-primary-400,
    &.bg-color-empty {
      color: $dark-foreground;

      .mat-expansion-indicator:after,
      .mat-expansion-panel-header-title {
        color: $dark-foreground;
      }

      .mat-caption {
        color: $AK-800;
      }
    }

    &:hover,
    &:focus {
      &.bg-color-main {
        background-color: $apex-main-variant;
      }

      &.bg-color-warn {
        background-color: mat.get-color-from-palette($warn);
      }

      &.bg-color-primary-400 {
        background-color: $AT-400;
      }

      &.bg-color-empty {
        background-color: $AK-100;
      }

      &.bg-color-disabled {
        background-color: $color-disabled;
      }
    }

    &[aria-disabled='true'] {
      padding-right: $default-padding-small;
    }
  }

  .mat-expansion-panel-content {
    .mat-expansion-panel-body {
      position: relative;

      padding: $default-padding-small;
    }

    .mat-action-row {
      padding: $default-padding-small $default-padding-small $default-padding-small $default-padding;
    }
  }

  &.zero-padding {
    .mat-expansion-panel-content {
      .mat-expansion-panel-body {
        padding: 0;
      }
    }
  }
}

body.apex-angular {
  #wrap #content-wrap .container {
    max-width: unset;

    apex-client-addon-breadcrumb {
      .breadcrumb-background {
        margin: 0px -15px;
        padding: $default-padding-small;
      }
    }
  }
}

apex-addon-overlay-list {
  mat-form-field {
    .mdc-notched-outline__leading,
    .mdc-notched-outline__trailing {
      border-radius: 0 !important;
    }
  }
}

body.apex-app {
  margin: 0;
  padding: 0;

  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: column;

  apex-root {
    height: unset;
    overflow: auto;

    @include print() {
      overflow: visible;
    }

    .mat-drawer.mat-drawer-side {
      border-right: none;
    }

    .mat-drawer .mat-drawer-inner-container {
      display: flex;
      flex: 1;
    }
  }
}

.loader-svg-main {
  background: linear-gradient(to bottom, $AT-900 0%, $AT-900 60%, $AK-900 60%, $AK-900 100%);

  opacity: 1;
  transition: all 750ms ease-in;

  position: absolute;
  z-index: 2000000;

  width: 100vw;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;

  .svg-icon {
    width: 256px;

    path {
      fill: $AK-200;
    }
  }
}

.apex-angular {
  .loader-svg-main {
    opacity: 0;
    z-index: -1;
  }
}

md-backdrop.md-sidenav-backdrop {
  z-index: 3000;
}

.md-sidenav-left {
  z-index: 3001;
}

button.mat-mdc- icon-button {
  line-height: 1;
}

.mat-mdc-tab-label,
.mat-mdc-tab-link {
  &.mat-mdc-tab-label-active,
  &:hover {
    color: rgba($AT-900, 0.87);
    opacity: 1;
  }
}

body .mat-badge-content {
  background-color: $link-color;
  color: $light-foreground;
}

table.mat-mdc-table {
  &.space-between-columns {
    [class*='mat-column-']:not(:last-of-type) {
      padding-right: $default-padding;
    }

    &-small {
      [class*='mat-column-']:not(:last-of-type) {
        padding-right: $default-padding-small;
      }
    }
  }
}

#tinymce {
  background-color: $AK-50 !important;
  margin-left: 10px;
  margin-top: 10px;
}

#tinymce p {
  padding-right: 8px;
}

div.tox-editor-header {
  background-color: $AK-50 !important;
}

div.tox-toolbar {
  background-color: $AK-50 !important;
}

div.tox-statusbar {
  background-color: $AK-50 !important;
}

body#tinyBody.mce-content-body {
  font-style: normal;
}

body#tinyBody.mce-content-body p img {
  max-width: 100%;
}

body#tinyBody.mce-content-body h1 {
  font-size: 24px;
}

div.tox.tox-tinymce {
  border: 0;
  border-radius: 0;
}

.mat-tree-node {
  &.hide {
    display: none;
  }
}

apex-case-contractors,
apex-box {
  mat-expansion-panel {
    mat-expansion-panel-header {
      mat-slide-toggle,
      mat-checkbox {
        label {
          color: $AK-50;
        }

        .mdc-checkbox__background {
          border-color: $AK-50 !important;
        }
      }
    }
  }

  // apex-case-contractors - need to be specific to override notify label when bg-white
  .case-toggle-notify-label {
    color: $AK-900 !important;
  }
}

span.mdc-list-item__primary-text {
  overflow: hidden;
}

mat-button-toggle-group {
  .mat-button-toggle-checked {
    background-color: $AT-700 !important;
    color: $AK-50 !important;
  }
}

// @todo Fix this by adding a mat-divider where tabs is used
.mat-mdc-tab-link-container,
.mat-mdc-tab-label-container {
  border-bottom: 1px solid $AK-400;
}

input {
  margin-top: 4px;
}

// hides cart button when viewing he card as a client
apex-client-addon-overview {
  apex-client-addon-apartment-card {
    .info {
      button {
        display: none;
      }
    }
  }
}

print-checklist,
print-checklist-groups {
  display: block;
}

print-checklist-groups {
  print-checklist-groups {
    margin-left: $default-margin;
  }
}

a {
  &.disabled {
    color: #ccc;
    cursor: default;
    pointer-events: none;
  }
}

print-case-list {
  apex-box {
    mat-expansion-panel-header {
      * {
        color: black !important;
      }

      background-color: white !important;
    }
  }
}

apex-cases div.mdc-tab:nth-child(4) {
  flex-grow: 0 !important;
  pointer-events: none;

  span.mdc-tab__content {
    pointer-events: none;
  }

  span.mdc-tab__text-label {
    pointer-events: all;
  }
}

apex-sidebar {
  // added this so sidebar items looks the same
  mat-expansion-panel.mat-expansion-panel {
    background-color: $AK-900;

    .mat-expansion-indicator:after,
    .mat-expansion-panel-header-title {
      color: $primary-foreground;
    }

    .mat-expansion-panel-content {
      .mat-expansion-panel-body {
        padding: 0;
      }
    }
  }
}

// override mat-slide-toggle color when is toggled is active for entire app
mat-slide-toggle.mat-mdc-slide-toggle {
  div.mdc-form-field {
    button.mdc-switch.mdc-switch:enabled {
      .mat-ripple-element {
        background-color: $AT-700;
      }

      // toggle slider color
      .mdc-switch__track.mdc-switch__track::after {
        background-color: $AT-700;
      }
    }

    // toggle slider icon
    .mdc-switch.mdc-switch--selected:enabled .mdc-switch__handle::after {
      background-color: $AT-700;
    }

    // on hover
    button.mdc-switch.mdc-switch--checked.mdc-switch:enabled:hover .mdc-switch__handle::after {
      background-color: $AT-700;
    }
  }
}

body.portal-root {
  margin: 0;
  padding: 0;

  width: 100vw;
  height: 100vh;

  portal-root {
    height: 100%;
    overflow: auto;

    @include print() {
      overflow: visible;
    }

    .mat-drawer-container {
      height: calc(100% - 56px);
    }
  }
}
